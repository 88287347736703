import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Spinner, Row, Input, Card, CardHeader, CardBody, Button, Table, Accordion, AccordionBody, AccordionHeader, AccordionItem, Modal, ModalBody, InputGroup, ModalHeader} from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Autoridad } from './Autoridad';
import { Persona } from './Persona';

export const Audiencias = () =>{
    const edoGlobal = useContext(AppContext);
    let [cargando,setCargando] = useState(false);
    let [usuario, setUsuario] = useState(1);
    let [corporacion, setCorporacion] = useState(1);
    let [fechaIni, setFechaIni] = useState(new Date().yyyymmdd('-'));
    let [fechaFin, setFechaFin] = useState(new Date().yyyymmdd('-'));
    let [audiencias, setAudiencias] = useState([]);
    let [capturando, setCapturando] = useState(false);
    const [open, setOpen] = useState('0');
    let [tipo, setTipo] = useState("");
    let [idAu, setIdAu] = useState(0);
    let [idRpt, setIdRpt] = useState(0);
    let [idDet, setIdDet] = useState(0);
    let [nombreDet, setNombreDet] = useState("");
    let [idJuez, setIdJuez] = useState(0);
    let [nombreJuez, setNombreJuez] = useState("");
    let [narrativa,setNarrativa] = useState("");
    let [motivo,setMotivo] = useState("");
    let [resolucion,setResolucion] = useState("");
    let [detenidos,setDetenidos] = useState([]);
    let [horas,setHoras] = useState([]);
    let [minutos,setMinutos] = useState([]);
    let [reportes,setReportes] = useState([]);
    let [fecha, setFecha] = useState(new Date().yyyymmdd('-'));
    let [hora, setHora] = useState(0);
    let [min, setMin] = useState(0);
    let [verModalReporte, setVerModalReporte] = useState(false);
    let [verModalAutoridad, setVerModalAutoridad] = useState(false);
    let [verModalPersona, setVerModalPersona] = useState(false);
    let [campoAutoridad,setCampoAutoridad] = useState(0);
    let [campoPersona,setCampoPersona] = useState(0);

    useEffect(() => {
        let i = 1;
        for(i = 0; i < 59; i++){
            if(i < 24)
               horas.push(i);
            minutos.push(i);
        }
        //cargarCatalogos();
    }, []);

    async function cargarCatalogos(){
        let url = `${edoGlobal.urlws}/iphCivica/procedenciasVehiculo`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        //setProcedencias(datos);
        setCargando(false);
    }

    async function cargarAudiencias(){
        let url = `${edoGlobal.urlws}/audiencia/lista/${corporacion}?fecIni=${fechaIni}&fecFin=${fechaFin}`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        setAudiencias(datos);
        setCargando(false);
    }
    async function cargarDetenidos(){
        let url = `${edoGlobal.urlws}/audiencia/detenidos/${idAu}`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        setDetenidos(datos);
        setCargando(false);
    }
    async function cargarReportes(){
        let url = `${edoGlobal.urlws}/audiencia/reportesPendientes/${idDet}?corp=${corporacion}`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        idRpt = 0;
        setIdRpt(0);
        setReportes(datos);
        setCargando(false);
        console.log(datos);
    }
    const toggle = (id) => {
        if (open === id)
           setOpen();
        else
           setOpen(id);
    };
    function buscarAut(a){
        setCampoAutoridad(a);
        setVerModalAutoridad(true)
    }
    function autSeleccionada(d){
        if(campoAutoridad == 1){
            setIdJuez(d.Id);
            setNombreJuez(d.NombreCompleto);
        }
        setVerModalAutoridad(false)
    }
    function buscarPer(a){
        if(idAu == 0){
            Swal.fire('Atencion','debe guardar este registro para poder agregar detenidos','error');
            return;
        }
        setCampoPersona(a);
        setVerModalPersona(true)
    }
    async function perSeleccionada(d){
        if(campoPersona == 1){
            idDet = d.Id;
            setIdDet(d.Id);
            setNombreDet(d.NombreCompleto);
            await cargarReportes();
        }
        setVerModalPersona(false);
        setVerModalReporte(true);
    }
    function nuevo(){
        editar(0);
    }
    async function editar(i){
        let url = `${edoGlobal.urlws}/audiencia/cargar/${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        url = `${edoGlobal.urlws}/audiencia/detenidos/${i}`;
        resp = await fetch(url);
        let datos = await resp.json();
        setIdAu(i);
        setTipo(d.Tipo);
        setIdRpt(d.Reporte);
        setIdJuez(d.Juez);
        setIdDet(d.Detenido);
        setMotivo(d.Motivo);
        setNarrativa(d.Narrativa);
        setResolucion(d.Resolucion);
        setFecha(d.Fecha);
        setHora(Number(d.Hora.substring(0,2)));
        setMin(Number(d.Hora.substring(3,5)));
        setDetenidos(datos);
        setCargando(false);
        setCapturando(true);
    }
    async function seleccionarRpt(r){
        setIdRpt(r);
        let url = `${edoGlobal.urlws}/audiencia/agregarDetenido/${idAu}?renglon=0&detenido=${idDet}&reporte=${r.Id}&tipo=${r.D}`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        setCargando(false);
        if(res == "ok"){
            setVerModalReporte(false);
            cargarDetenidos();
        }else
           Swal.fire('Atencion',res,'error');
    }
    function cancelar(){
        setCapturando(false);
    }
    function guardar(){
        if(idJuez == 0){
            Swal.fire('Atencion','Juez no especificado','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                guardarB();
        });
    }
    async function guardarB(){
        let tp = tipo;
        if(tp == "" || tp == "?")
           for(let i = 0; i < reportes.length; i++)
              if(reportes[i].Id == idRpt)
                 tp = reportes[i].D;
        let hr = Number(hora) > 9 ? hora : '0' + hora;
        let mi = Number(min) > 9 ? min : '0' + min; 
        let dato = {
            Id: idAu, Reporte: idRpt, Detenido: idDet, Juez : idJuez,
            Fecha : fecha, Hora: hr + ":" + mi, Narrativa:narrativa,
            Resolucion : resolucion, Motivo: motivo, Tipo:tp,
            Usuario : usuario, Corporacion : corporacion
        }
        let url = `${edoGlobal.urlws}/audiencia/guardar`;
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            cargarAudiencias();
            setCapturando(false);
        }else
           Swal.fire('Atencion',res,'error');
    }
    const estiloListado = { display: capturando ? 'none' : 'inline' };
    const estiloCaptura = { display: !capturando ? 'none' : 'inline' };
    return (
        <div>
            <div style={estiloListado}>
                    <table>
                        <tr>
                            <td>Audiencias del &nbsp;</td>
                            <td><Input size="sm" type="date" value={fechaIni} onChange={(e) => setFechaIni(e.target.value)} /></td>
                            <td>&nbsp;al&nbsp;</td>
                            <td><Input size="sm" type="date" value={fechaFin} onChange={(e) => setFechaFin(e.target.value)} /></td>
                            <td>&nbsp;<Button size="sm" color="success" outline onClick={cargarAudiencias}><img src="/imagenes/ok.png" title="cargar reportes" /></Button></td>
                            <td>&nbsp;<Button size="sm" color="success" outline onClick={nuevo}><img src="/imagenes/add.png" title="nuevo reporte" /></Button></td>
                        </tr>
                    </table>
                <Table size="sm" hover striped>
                    <thead>
                        <tr>
                            <th className='celdaFecha'>Fecha</th>
                            <th>Hora</th>
                            <th>Juez</th>
                            <td className='celdaOpcion'></td>
                        </tr>
                    </thead>
                    <tbody>
                        {audiencias.map((o,i)=><tr key={i}>
                            <td>{o.F}</td>
                            <td>{o.H}</td>
                            <td>{o.J}</td>
                            <td><img onClick={()=> editar(o.Id)} src="/imagenes/edit.png" title="editar" /></td>
                        </tr>)}
                    </tbody>
                </Table>
            </div>
            <div style={estiloCaptura}>
                <Row>
                    <div className="col-2">
                        Fecha<Input size="sm" type="date" value={fecha} onChange={(e) => setFecha(e.target.value)} />
                    </div>
                    <div className="col-1">
                        Hora
                        <Input size="sm" type='select' value={hora} onChange={(e) => setHora(e.target.value)}>
                            {horas.map((o, i) => <option key={i} value={o}>{o}</option>)}
                        </Input>
                    </div>
                    <div className="col-1">
                        Minuto
                        <Input size="sm" type='select' value={min} onChange={(e) => setMin(e.target.value)}>
                            {minutos.map((o, i) => <option key={i} value={o}>{o}</option>)}
                        </Input>
                    </div>
                    <div className='col-2'>
                    </div>
                    <div className='col-2'>
                        <br />
                        <Button size="sm" color="success" outline onClick={guardar}><img src="/imagenes/disk.png" />  Guardar</Button>
                    </div>
                    <div className='col-2'>
                        <br />
                        <Button size="sm" color="danger" outline onClick={cancelar}> Regresar </Button>
                    </div>
                </Row>
                <Row>  
                    <div className='col'>
                        Juez<br/>
                        <InputGroup>
                            <Input size="sm" placeholder='nombre del juez' value={nombreJuez} />
                            &nbsp;
                            <Button outline size="sm" color="secondary" onClick={() => buscarAut(1)}>
                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                            </Button>
                        </InputGroup>
                    </div>
                </Row>
                <br/>
                <Accordion size="sm" open={open} toggle={toggle}>
                    <AccordionItem size="sm">
                        <AccordionHeader targetId="0">Detenidos</AccordionHeader>
                        <AccordionBody accordionId="0">
                            <Row>
                                <span>Detenidos <Button size="sm" color="success" outline title="agregar detenido" onClick={() => buscarPer(1)}><img src="imagenes/add.png" /></Button></span>
                                <Table size="sm" hover striped>
                                    <thead>
                                        <tr>
                                            <th>Detenido</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detenidos.map((o, i) => <tr key={i}>
                                            <td>{o.N}</td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                            </Row>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem size="sm">
                        <AccordionHeader targetId="1">Motivos</AccordionHeader>
                        <AccordionBody accordionId="1">
                            <Row>
                                <Input style={{ height: 400 }} type="textarea" value={motivo} onChange={(e) => setMotivo(e.target.value)} />
                            </Row>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem size="sm">
                        <AccordionHeader targetId="2">Narrativa</AccordionHeader>
                        <AccordionBody accordionId="2">
                            <Row>
                                <Input style={{ height: 400 }} type="textarea" value={narrativa} onChange={(e) => setNarrativa(e.target.value)} />
                            </Row>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem size="sm">
                        <AccordionHeader targetId="3">Resolucion</AccordionHeader>
                        <AccordionBody accordionId="3">
                            <Row>
                                <Input style={{ height: 400 }} type="textarea" value={resolucion} onChange={(e) => setResolucion(e.target.value)} />
                            </Row>
                            <br />
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </div>
            <Modal size="lg" isOpen={verModalAutoridad} toggle={() => setVerModalAutoridad(false)}>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <Autoridad sel={true} autSeleccionada={autSeleccionada}/>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={verModalPersona} toggle={() => setVerModalPersona(false)}>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <Persona sel={true} perSeleccionada={perSeleccionada}/>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={verModalReporte} toggle={() => setVerModalReporte(false)}>
                <ModalHeader>Seleccionar reporte</ModalHeader>
                <ModalBody>
                    <div>
                        <Table size="sm" hover striped>
                            <thead>
                                <tr>
                                    <th className='celdaFecha'>Fecha</th>
                                    <th>Hora</th>
                                    <th>Tipo</th>
                                    <td className='celdaOpcion'></td>
                                </tr>
                            </thead>
                            <tbody>
                                {reportes.map((o, i) => <tr key={i}>
                                    <td>{o.F}</td>
                                    <td>{o.H}</td>
                                    <td>{o.T}</td>
                                    <td><img onClick={() => seleccionarRpt(o)} src="/imagenes/ok.png" title="seleccionar" /></td>
                                </tr>)}
                            </tbody>
                        </Table>

                    </div>
                </ModalBody>
            </Modal>
            {cargando ? (
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
            ) : null}
        </div>
   )
}