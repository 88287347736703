import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Row, Input, Card, CardHeader, CardBody, Button,Table, Modal, ModalHeader, ModalBody, InputGroup, Accordion, AccordionBody, AccordionHeader, AccordionItem, Spinner} from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Panel } from './Panel';
import { Autoridad } from './Autoridad';
import { Persona } from './Persona';

export const IphDelito = () =>{
    const edoGlobal = useContext(AppContext);
    const [open, setOpen] = useState('0');
    let [cargando,setCargando] = useState(false);
    let [usuario, setUsuario] = useState(1);
    let [corporacion, setCorporacion] = useState(1);
    let [idRtp, setIdRpt] = useState(0);
    let [idVeh, setIdVeh] = useState(0);
    let [idDet, setIdDet] = useState(0);
    let [idEnt, setIdEnt] = useState(0);
    let [idArma, setIdArma] = useState(0);
    let [referencia,setReferencia] = useState("");
    let [folioSistema,setFolioSistema] = useState("");
    let [fechaIni, setFechaIni] = useState(new Date().yyyymmdd('-'));
    let [fechaFin, setFechaFin] = useState(new Date().yyyymmdd('-'));
    let [reportes, setReportes] = useState([]);
    let [capturando, setCapturando] = useState(false);
    let [horas,setHoras] = useState([]);
    let [minutos,setMinutos] = useState([]);
    let [instituciones,setIntituciones] = useState([]); //{I:1,N:'Guardia Nacional'},{I:2,N:'Policía Federal Ministerial'},{I:3,N:'Policía Ministeria'},{I:4,N:'Policía Mando Único'},{I:5,N:'Policía Estatal'},{I:6,N:'Policía Municipa'},{I:7,N:'Otra autoridad'}]);
    let [tiposAuto,setTiposAuto] = useState([]); //{I:1,N:'Terreste'},{I:2,N:'Acuatico'},{I:3,N:'Aereo'}]);
    let [usosAuto,setUsosAuto] = useState([]); //{I:1,N:'Particular'},{I:2,N:'Transporte publico'},{I:3,N:'Carga'}]);
    let [procedencias,setProcedencias] = useState([]); //{I:1,N:'Nacional'},{I:2,N:'Extranjero'}]);
    let [marcas,setMarcas] = useState([]); //{I:1,N:'Totoya'},{I:2,N:'Honda'}]);
    let [expediente, setExpediente] = useState("");
    let [fecha0101, setFecha0101] = useState(new Date().yyyymmdd('-'));
    let [hr0101, setHr0101] = useState(0);
    let [min0101, setMin0101] = useState(0);
    let [chA, setChA] = useState(true);
    let [chB, setChB] = useState(true);
    let [chC, setChC] = useState(true);
    let [chD, setChD] = useState(true);
    let [chE, setChE] = useState(true);
    let [chF, setChF] = useState(true);
    let [chG, setChG] = useState(true);
    let [cantA, setCantA] = useState(0);
    let [cantB, setCantB] = useState(0);
    let [cantC, setCantC] = useState(0);
    let [cantD, setCantD] = useState(0);
    let [cantE, setCantE] = useState(0);
    let [cantF, setCantF] = useState(0);
    let [cantG, setCantG] = useState(0);
    let [docComple, setDocComple] = useState(false);
    let [chFotos, setChFotos] = useState(false);
    let [chVideos, setChVideos] = useState(false);
    let [chAudios, setChAudios] = useState(false);
    let [chCertMed, setCertMed] = useState(false);
    let [chOtra, setChOtra] = useState(false);
    let [otra, setOtra] = useState("");
    let [chSinA, setChSinA] = useState(false);

    let [id0101, setId0101] = useState(0);
    let [apePat0101, setApePat0101] = useState("");
    let [apeMat0101, setApeMat0101] = useState("");
    let [nombre0101, setNombre0101] = useState("");
    let [ads0101, setAds0101] = useState(0);
    let [cargo0101, setCargo0101] = useState("");

    let [idFis0101, setIdFis0101] = useState(0);
    let [apePatFis0101, setApePatFis0101] = useState("");
    let [apeMatFis0101, setApeMatFis0101] = useState("");
    let [nombreFis0101, setNombreFis0101] = useState("");
    let [fiscalia0101, setFiscalia0101] = useState("");
    let [adsFis0101, setAdsFis0101] = useState(0);
    let [cargoFis0101, setCargoFis0101] = useState("");

    let [idPC0101, setIdPC0101] = useState(0);
    let [apePatPC0101, setApePatPC0101] = useState('');
    let [apeMatPC0101, setApeMatPC0101] = useState('');
    let [nombrePC0101, setNombrePC0101] = useState('');
    let [pcOtraA, setPcOtraA] = useState('');
    let [pcCargo, setPcCargo] = useState('');
    let [pcInst, setPcInst] = useState(0);
    let [pcUnidad, setPCUnidad] = useState('');
    let [chPCUnidad, setChPUnidad] = useState(false);
    let [chPCElementos, setChPElementos] = useState(false);
    let [pcCuantos, setPCCuantos] = useState('0');

    let [medioEnterado, setMedioEnterado] = useState(0);
    let [chEntA, setChEntA] = useState(false);
    let [chEntB, setChEntB] = useState(false);
    let [chEntC, setChEntC] = useState(false);
    let [chEntD, setChEntD] = useState(false);
    let [chEntE, setChEntE] = useState(false);
    let [chEntF, setChEntF] = useState(false);
    let [chEntG, setChEntG] = useState(false);
    let [num911, setNum911] = useState("");

    let [fechaCon, setFechaCon] = useState(new Date().yyyymmdd('-'));
    let [hrCon, setHrCon] = useState(0);
    let [minCon, setMinCon] = useState(0);
    let [fechaArribo, setFechaArribo] = useState(new Date().yyyymmdd('-'));
    let [hrArribo, setHrArribo] = useState(0);
    let [minArribo, setMinArribo] = useState(0);


    let [calle, setCalle] = useState("");
    let [noInt, setNoInt] = useState("");
    let [noExt, setNoExt] = useState("");
    let [cp, setCP] = useState("");
    let [referencias,setReferencias] = useState("");
    let [lat, setLat] = useState("");
    let [lon, setLon] = useState("");

    let [calleInt, setCalleInt] = useState("");
    let [noIntInt, setNoIntInt] = useState("");
    let [noExtInt, setNoExtInt] = useState("");
    let [cpInt, setCPInt] = useState("");
    let [referenciasInt,setReferenciasInt] = useState("");
    let [latInt, setLatInt] = useState("");
    let [lonInt, setLonInt] = useState("");

    let [chInsp, setChInsp] = useState(false);
    let [chInspObj, setChInspObj] = useState(false);
    let [chInspPres, setChInspPres] = useState(false);
    let [chInspPrio, setChInspPrio] = useState(false);
    let [chInspRS, setChInspRS] = useState(false);
    let [chInspRN, setChInspRN] = useState(false);
    let [inspRiesgo, setInspRiesgo] = useState('');
    let [narrativa, setNarrativa] = useState("");

    let [lesionadosA, setLesionadosA] = useState(0);
    let [lesionadosP, setLesionadosP] = useState(0);
    let [fallecidosA, setFallecidosA] = useState(0);
    let [fallecidosP, setFallecidosP] = useState(0);
    let [chFuerzaA,setFuerzaA] = useState(false);
    let [chFuerzaB,setFuerzaB] = useState(false);
    let [chFuerzaC,setFuerzaC] = useState(false);
    let [conductas, setConductas] = useState('');
    let [chFuerzaAM,setFuerzaAM] = useState(false);
    let [asistencia, setAsistencia] = useState('');

    let [idFuerza01, setIdFuerza01] = useState(0);
    let [apePatFuerza01, setApePatFuerza01] = useState('');
    let [apeMatFuerza01, setApeMatFuerza01] = useState('');
    let [nombreFuerza01, setNombreFuerza01] = useState('');
    let [adsFuerza01, setAdsFuerza01] = useState(0);
    let [cargoFuerza01, setCargoFuerza01] = useState('');
    
    let [idFuerza02, setIdFuerza02] = useState(0);
    let [apePatFuerza02, setApePatFuerza02] = useState('');
    let [apeMatFuerza02, setApeMatFuerza02] = useState('');
    let [nombreFuerza02, setNombreFuerza02] = useState('');
    let [adsFuerza02, setAdsFuerza02] = useState(0);
    let [cargoFuerza02, setCargoFuerza02] = useState('');
    let [preservacion, setPreservacion] = useState('');
    let [chApoyo,setChApoyo] = useState(false);
    let [apoyo, setApoyo] = useState('');
    let [chIngreso,setChIngreso] = useState(false);
    let [motivoIng, setMotivoIng] = useState('');

    let [idIng01, setIdIng01] = useState(0);
    let [apePatIng01, setApePatIng01] = useState('');
    let [apeMatIng01, setApeMatIng01] = useState('');
    let [nombreIng01, setNombreIng01] = useState('');
    let [adsIng01, setAdsIng01] = useState(0);
    let [cargoIng01, setCargoIng01] = useState('');

    let [idIng02, setIdIng02] = useState(0);
    let [apePatIng02, setApePatIng02] = useState('');
    let [apeMatIng02, setApeMatIng02] = useState('');
    let [nombreIng02, setNombreIng02] = useState('');
    let [adsIng02, setAdsIng02] = useState(0);
    let [cargoIng02, setCargoIng02] = useState('');

    let [idEntrega, setIdEntrega] = useState(0);
    let [apePatEntrega, setApePatEntrega] = useState('');
    let [apeMatEntrega, setApeMatEntrega] = useState('');
    let [nombreEntrega, setNombreEntrega] = useState('');
    let [adsEntrega, setAdsEntrega] = useState(0);
    let [cargoEntrega, setCargoEntrega] = useState('');

    let [idRecibe, setIdRecibe] = useState(0);
    let [apePatRecibe, setApePatRecibe] = useState('');
    let [apeMatRecibe, setApeMatRecibe] = useState('');
    let [nombreRecibe, setNombreRecibe] = useState('');
    let [adsRecibe, setAdsRecibe] = useState(0);
    let [cargoRecibe, setCargoRecibe] = useState('');
    let [obs, setObs] = useState('');
    let [fechaEntrega, setFechaEntrega] = useState(new Date().yyyymmdd('-'));
    let [hrEntrega, setHrEntrega] = useState(0);
    let [minEntrega, setMinEntrega] = useState(0);
    
    let [chContH, setChContH] = useState(true);
    let [chContE, setChContE] = useState(true);
    let [continuacion, setContinuacion] = useState('');

    let [idCont, setIdCont] = useState(0);
    let [apePatCont, setApePatCont] = useState('');
    let [apeMatCont, setApeMatCont] = useState('');
    let [nombreCont, setNombreCont] = useState('');
    let [adsCont, setAdsCont] = useState(0);
    let [cargoCont, setCargoCont] = useState('');

    let [policias, setPolicias] = useState([]);

    let [detenidos, setDetenidos] = useState([]);
    let [verModalDet, setVerModalDet] = useState(false);
    let [fechaDet, setFechaDet] = useState(new Date().yyyymmdd('-'));
    let [hrDet, setHrDet] = useState(0);
    let [minDet, setMinDet] = useState(0);

    let [idPerDet, setIdPerDet] = useState(0);
    let [apePatDet, setApePatDet] = useState("");
    let [apeMatDet, setApeMatDet] = useState("");
    let [nombreDet, setNombreDet] = useState("");
    let [aliasDet, setAliasDet] = useState("");
    let [fechaNac, setFechaNac] = useState(new Date().yyyymmdd('-'));
    let [nacionalidad, setNacionalidad] = useState("M");
    let [cual, setCual] = useState("");
    let [edad, setEdad] = useState(0);
    let [sexo, setSexo] = useState("?");
    let [calleDet, setCalleDet] = useState("");
    let [noIntDet, setNoIntDet] = useState("");
    let [noExtDet, setNoExtDet] = useState("");
    let [cpDet, setCPDet] = useState("");
    let [referenciasDet,setReferenciasDet] = useState("");
    let [descripDet,setDescripDet] = useState("");
    let [lesiones, setLesiones] = useState(false);
    let [vulnerable, setVulnerable] = useState(false);
    let [padecimientos, setPadecimientos] = useState(false);
    let [padecimiento, setPadecimiento] = useState("");
    let [vulnerableCual, setVulnerableCual] = useState("");
    let [delictivo, setDelictivo] = useState(false);
    let [delictivoCual, setDelictivoCual] = useState("");
    let [autoridad, setAutoridad] = useState("");
    let [derechos, setDerechos] = useState(false);
    let [conObjetos, setConObjetos] = useState(false);
    let [conPertenencias, setConPertenencias] = useState(false);
    let [mismaDir, setMismaDir] = useState(false);
    let [calleDir, setCalleDir] = useState("");
    let [noIntDir, setNoIntDir] = useState("");
    let [noExtDir, setNoExtDir] = useState("");
    let [cpDir, setCPDir] = useState("");
    let [referenciasDir,setReferenciasDir] = useState("");
    let [latDir, setLatDir] = useState("");
    let [lonDir, setLonDir] = useState("");
    let [chFiscalia, setChFiscalia] = useState(false);
    let [chHospital, setChHospital] = useState(false);
    let [chOtraDep, setChOtraDep] = useState(false);
    let [dependencia, setDependencia] = useState("");
    let [idPrimerA, setIdPrimerA] = useState(0);
    let [apePatPrimerA, setApePatPrimerA] = useState("");
    let [apeMatPrimerA, setApeMatPrimerA] = useState("");
    let [nombrePrimerA, setNombrePrimerA] = useState("");
    let [cargoPrimerA, setCargoPrimerA] = useState("");
    let [adsPrimerA, setAdsPrimerA] = useState(0);
    let [obsDet, setObsDet] = useState("");

    let [idPerFam, setIdPerFam] = useState(0);
    let [apePatFam, setApePatFam] = useState("");
    let [apeMatFam, setApeMatFam] = useState("");
    let [nombreFam, setNombreFam] = useState("");
    let [telefonoFam, setTelefonoFam] = useState("");
    
    let [chCalV,setChCalV] = useState(false);
    let [chCalD,setChCalD] = useState(false);
    let [chCalT,setChCalT] = useState(false);
    let [chIdentI,setChIdentI] = useState(false);
    let [chIdentL,setChIdentL] = useState(false);
    let [chIdentP,setChIdentP] = useState(false);
    let [chIdentO,setChIdentO] = useState(false);
    let [chIdentN,setChIdentN] = useState(false);
    let [identificacion,setIdentificacion] = useState("");
    let [tel,setTel] = useState("");
    let [email,setEmail] = useState("");
    let [colonia,setColonia] = useState("");
    let [chTraslado,setChTraslado] = useState(false);

    let [autos, setAutos] = useState([]);
    let [verModalAuto, setVerModalAuto] = useState(false);
    let [fechaAuto, setFechaAuto] = useState(new Date().yyyymmdd('-'));
    let [hrAuto, setHrAuto] = useState(0);
    let [minAuto, setMinAuto] = useState(0);
    let [tipoAuto, setTipoAuto] = useState(0);
    let [procAuto, setProcAuto] = useState(0);
    let [marcaAuto, setMarcaAuto] = useState(0);
    let [subMarcaAuto, setSubMarcaAuto] = useState("");
    let [modeloAuto, setModeloAuto] = useState("");
    let [colorAuto, setColorAuto] = useState("");
    let [usoAuto, setUsoAuto] = useState(0);
    let [placaAuto, setPlacaAuto] = useState("");
    let [serieAuto, setSerieAuto] = useState("");
    let [obsAuto, setObsAuto] = useState("");
    let [destinoAuto, setDestinoAuto] = useState("");
    let [repRoboAuto, setRepRoboAuto] = useState("?");
    let [objetosAuto, setObjetosAuto] = useState(false);
    let [idAutAuto, setIdAutAuto] = useState(0);
    let [apePatAuto, setApePatAuto] = useState("");
    let [apeMatAuto, setApeMatAuto] = useState("");
    let [nombreAuto, setNombreAuto] = useState("");
    let [adsAuto, setAdsAuto] = useState(0);
    let [cargoAuto, setCargoAuto] = useState("");
    let [chRoboS,setChRoboS] = useState(false);
    let [chRoboN,setChRoboN] = useState(false);
    let [chRoboX,setChRoboX] = useState(false);

    let [chAportacion,setChAportacion] = useState(false);
    let [chInsL,setChInsL] = useState(false);
    let [chInsP,setChInsP] = useState(false);
    let [chInsV,setChInsV] = useState(false);
    let [armaDonde,setArmaDonde] = useState("?");
    let [armaTipo,setArmaTipo] = useState("?");
    let [armaCal,setArmaCal] = useState("");
    let [armaCol,setArmaCol] = useState("");
    let [armaMat,setArmaMat] = useState("");
    let [armaSer,setArmaSer] = useState("");
    let [armaObs,setArmaObs] = useState("");
    let [armaDes,setArmaDes] = useState("");
    let [idArmaPer,setIdArmaPer] = useState(0);
    let [apePatArma,setApePatArma] = useState("");
    let [apeMatArma,setApeMatArma] = useState("");
    let [nombreArma,setNombreArma] = useState("");
    let [idArmaTest1,setIdArmaTest1] = useState(0);
    let [apePatTest1,setApePatTest1] = useState("");
    let [apeMatTest1,setApeMatTest1] = useState("");
    let [nombreTest1,setNombreTest1] = useState("");
    let [idArmaTest2,setIdArmaTest2] = useState(0);
    let [apePatTest2,setApePatTest2] = useState("");
    let [apeMatTest2,setApeMatTest2] = useState("");
    let [nombreTest2,setNombreTest2] = useState("");
    let [idArmaRealizo,setIdArmaRealizo] = useState(0);
    let [apePatRealizoA,setApePatRealizoA] = useState("");
    let [apeMatRealizoA,setApeMatRealizoA] = useState("");
    let [nombreRealizoA,setNombreRealizoA] = useState("");
    let [adsRealizoA,setAdsRealizoA] = useState(0);
    let [cargoRealizoA,setCargoRealizoA] = useState("");

    let [chObjD,setChObjD] = useState(false);
    let [chObjH,setChObjH] = useState(false);
    let [chObjN,setChObjN] = useState(false);
    let [chObjO,setChObjO] = useState(false);

    let [armas, setArmas] = useState([]);
    let [verModalArma, setVerModalArma] = useState(false);
    let [objetos, setObjetos] = useState([]);
    let [verModalObjeto, setVerModalObjeto] = useState(false);
    let [entrevistas, setEntrevistas] = useState([]);
    let [verModalEntrevista, setVerModalEntrevista] = useState(false);

    let [verModalAutoridad, setVerModalAutoridad] = useState(false);
    let [campoAutoridad,setCampoAutoridad] = useState(0);
    let [verModalPersona, setVerModalPersona] = useState(false);
    let [campoPersona,setCampoPersona] = useState(0);
    let [selPolicia, setSelPolicia] = useState(false);

    useEffect(() => {
        let fec = new Date();
        fec.addDays(-7);
        setFechaIni(fec.yyyymmdd('-'));
        let i = 1;
        for(i = 0; i < 59; i++){
            if(i < 24)
               horas.push(i);
            minutos.push(i);
        }
        cargarCatalogos();
    }, []);

    async function cargarCatalogos(){
        let url = `${edoGlobal.urlws}/iphCivica/procedenciasVehiculo`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        setProcedencias(datos);
        url = `${edoGlobal.urlws}/iphCivica/tiposVehiculo`;
        resp = await fetch(url);
        datos = await resp.json();
        setTiposAuto(datos);
        url = `${edoGlobal.urlws}/iphCivica/marcasVehiculo`;
        resp = await fetch(url);
        datos = await resp.json();
        setMarcas(datos);
        url = `${edoGlobal.urlws}/iphCivica/usosVehiculo`;
        resp = await fetch(url);
        datos = await resp.json();
        setUsosAuto(datos);
        url = `${edoGlobal.urlws}/iphCivica/instituciones`;
        resp = await fetch(url);
        datos = await resp.json();
        setIntituciones(datos);
        setCargando(false);
    }
    async function cargarReportes(){
        let url = `${edoGlobal.urlws}/iphDelito/lista/${corporacion}?fecIni=${fechaIni}&fecFin=${fechaFin}`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        setReportes(datos);
        setCargando(false);
    }
    function cambiarIdent(c,v){
        if(v){
            setChIdentI(c == "I");
            setChIdentL(c == "L");
            setChIdentP(c == "P");
            setChIdentO(c == "O");
            setChIdentN(c == "N");
        }
    }

    function cambiarCalidad(c,v){
        if(v){
            setChCalV(c == "V");
            setChCalD(c == "D");
            setChCalT(c == "T");
        }
    }
    function cambiarInspeccion(c,v){
        if(v){
            setChInsL(c == "L");
            setChInsP(c == "P");
            setChInsV(c == "V");
        }
    }
    function cambiarObjeto(c,v){
        if(v){
            setChObjD(c == "D");
            setChObjH(c == "H");
            setChObjN(c == "N");
            setChObjO(c == "O");
            if(c != "O")
               setArmaTipo("");
        }
    }
    function cambiarMismaDir(v){
        setMismaDir(v);
        if(v){
            setCalleDir(calleInt);
            setNoExtDir(noExtInt);
            setNoIntDir(noIntInt);
            setCPDir(cpInt);
            setReferenciasDir(referenciasInt);
        }
    }
    function buscarAut(a){
        setCampoAutoridad(a);
        setSelPolicia(false);
        setVerModalAutoridad(true)
    }
    function seleccionarPol(a){
        setCampoAutoridad(a);
        setSelPolicia(true);
        setVerModalAutoridad(true)
    }
    async function cargarPoliciaSel(idPol){
        let url = `${edoGlobal.urlws}/autoridad/cargar/${idPol}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setCargando(false);
        autSeleccionada(d);
    }
    function autSeleccionada(d){
        if(campoAutoridad == 1){
            setId0101(d.Id);
            setApePat0101(d.ApePat);
            setApeMat0101(d.ApeMat);
            setNombre0101(d.Nombre);
            setAds0101(d.Adscripcion);
            setCargo0101(d.Cargo);
        }
        if(campoAutoridad == 2){
            setIdFis0101(d.Id);
            setApePatFis0101(d.ApePat);
            setApeMatFis0101(d.ApeMat);
            setNombreFis0101(d.Nombre);
            setAdsFis0101(d.Adscripcion);
            setCargoFis0101(d.Cargo);
        }
        if(campoAutoridad == 3){
            setIdPC0101(d.Id);
            setApePatPC0101(d.ApePat);
            setApeMatPC0101(d.ApeMat);
            setNombrePC0101(d.Nombre);
            setPcInst(d.Adscripcion);
            setPcCargo(d.Cargo);
        }
        if(campoAutoridad == 4){
            setIdFuerza01(d.Id);
            setApePatFuerza01(d.ApePat);
            setApeMatFuerza01(d.ApeMat);
            setNombreFuerza01(d.Nombre);
            setAdsFuerza01(d.Adscripcion);
            setCargoFuerza01(d.Cargo);
        }
        if(campoAutoridad == 5){
            setIdFuerza02(d.Id);
            setApePatFuerza02(d.ApePat);
            setApeMatFuerza02(d.ApeMat);
            setNombreFuerza02(d.Nombre);
            setAdsFuerza02(d.Adscripcion);
            setCargoFuerza02(d.Cargo);
        }
        if(campoAutoridad == 6){
            setIdIng01(d.Id);
            setApePatIng01(d.ApePat);
            setApeMatIng01(d.ApeMat);
            setNombreIng01(d.Nombre);
            setAdsIng01(d.Adscripcion);
            setCargoIng01(d.Cargo);
        }
        if(campoAutoridad == 7){
            setIdIng02(d.Id);
            setApePatIng02(d.ApePat);
            setApeMatIng02(d.ApeMat);
            setNombreIng02(d.Nombre);
            setAdsIng02(d.Adscripcion);
            setCargoIng02(d.Cargo);
        }
        if(campoAutoridad == 8){
            setIdEntrega(d.Id);
            setApePatEntrega(d.ApePat);
            setApeMatEntrega(d.ApeMat);
            setNombreEntrega(d.Nombre);
            setAdsEntrega(d.Adscripcion);
            setCargoEntrega(d.Cargo);
        }
        if(campoAutoridad == 9){
            setIdRecibe(d.Id);
            setApePatRecibe(d.ApePat);
            setApeMatRecibe(d.ApeMat);
            setNombreRecibe(d.Nombre);
            setAdsRecibe(d.Adscripcion);
            setCargoRecibe(d.Cargo);
        }
        if(campoAutoridad == 10){
            setIdCont(d.Id);
            setApePatCont(d.ApePat);
            setApeMatCont(d.ApeMat);
            setNombreCont(d.Nombre);
            setAdsCont(d.Adscripcion);
            setCargoCont(d.Cargo);
        }
        if(campoAutoridad == 11){
            setIdPrimerA(d.Id);
            setApePatPrimerA(d.ApePat);
            setApeMatPrimerA(d.ApeMat);
            setNombrePrimerA(d.Nombre);
            setAdsPrimerA(d.Adscripcion);
            setCargoPrimerA(d.Cargo);
        }
        if(campoAutoridad == 12){
            setIdAutAuto(d.Id);
            setApePatAuto(d.ApePat);
            setApeMatAuto(d.ApeMat)
            setNombreAuto(d.Nombre);
            setAdsAuto(d.Adscripcion);
            setCargoAuto(d.Cargo);    
        }
        if(campoAutoridad == 13){
            setIdArmaRealizo(d.Id);
            setApePatRealizoA(d.ApePat);
            setApeMatRealizoA(d.ApeMat)
            setNombreRealizoA(d.Nombre);
            setAdsRealizoA(d.Adscripcion);
            setCargoRealizoA(d.Cargo);    
        }
        if(campoAutoridad == 14){
            guardarPolicia(d.Id);
        }
        setVerModalAutoridad(false)
    }
    function buscarPer(a){
        setCampoPersona(a);
        setVerModalPersona(true)
    }
    function perSeleccionada(d){
        if(campoPersona == 1){
            setIdPerDet(d.Id);
            setApePatDet(d.ApePat);
            setApeMatDet(d.ApeMat);
            setNombreDet(d.Nombre);
            setAliasDet(d.Alias);
            setFechaNac(d.FechaNac);
            setEdad(d.Edad);
            setSexo(d.Sexo);
            setNacionalidad(d.Nacionalidad == "MEXICANA"?"M":"E");
            setCual(d.Nacionalidad == "MEXICANA"?"": d.Nacionalidad);
            setCalleDet(d.Domicilio);
            setNoExtDet(d.NoExt);
            setNoIntDet(d.NoInt);
            setCPDet(d.CP);
            setReferenciasDet(d.Referencias);
            setDescripDet(d.Descripcion);
        }
        if(campoPersona == 2){
            setIdPerFam(d.Id);
            setApePatFam(d.ApePat);
            setApeMatFam(d.ApeMat);
            setNombreFam(d.Nombre);
            setDescripDet(d.Descripcion);
        }
        if(campoPersona == 3){
            setIdArmaPer(d.Id);
            setApePatArma(d.ApePat);
            setApeMatArma(d.ApeMat);
            setNombreArma(d.Nombre);
        }
        if(campoPersona == 4){
            setIdArmaTest1(d.Id);
            setApePatTest1(d.ApePat);
            setApeMatTest1(d.ApeMat);
            setNombreTest1(d.Nombre);
        }
        if(campoPersona == 5){
            setIdArmaTest2(d.Id);
            setApePatTest2(d.ApePat);
            setApeMatTest2(d.ApeMat);
            setNombreTest2(d.Nombre);
        }
        setVerModalPersona(false)
    }
    function nuevo(){
        editar(0);
    }
    async function editar(i){
        let url = `${edoGlobal.urlws}/iphDelito/cargar/${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        url = `${edoGlobal.urlws}/iphDelito/cargarDetenidos/${i}`;
        resp = await fetch(url);
        let t = await resp.json();
        url = `${edoGlobal.urlws}/iphDelito/cargarVehiculos/${i}`;
        resp = await fetch(url);
        let a = await resp.json();
        url = `${edoGlobal.urlws}/iphDelito/cargarArmas/${i}`;
        resp = await fetch(url);
        let r = await resp.json();
        url = `${edoGlobal.urlws}/iphDelito/cargarObjetos/${i}`;
        resp = await fetch(url);
        let o = await resp.json();
        url = `${edoGlobal.urlws}/iphDelito/cargarEntrevistas/${i}`;
        resp = await fetch(url);
        let e = await resp.json();
        url = `${edoGlobal.urlws}/iphDelito/cargarPolicias/${i}`;
        resp = await fetch(url);
        let p = await resp.json();
        setIdRpt(i);
        setId0101(d.IdRealiza);
        setIdFis0101(d.IdRecibe);
        setIdPC0101(d.IdPC);
        setIdFuerza01(d.IdFuerza01);
        setIdFuerza02(d.IdFuerza02);
        setIdIng01(d.IdIng01);
        setIdIng02(d.IdIng02);
        setIdEntrega(d.IdEntrega);
        setIdRecibe(d.IdRecibe);
        setIdCont(d.IdCont);
        setReferencia(d.Referencia);
        setFolioSistema(d.FolioSistema);
        setExpediente(d.Expediente);
        setFecha0101(d.Fecha);
        setHr0101(Number(d.Hora.substring(0,2)));
        setMin0101(Number(d.Hora.substring(3,5)));
        setCantA(d.Detenidos);
        setCantB(d.Vehiculos);
        setApePat0101(d.ApePatRealiza);
        setApeMat0101(d.ApeMatRealiza);
        setNombre0101(d.NombreRealiza);
        setAds0101(d.AdscripcionRealiza);
        setApePatFis0101(d.ApePatRecibe);
        setApeMatFis0101(d.ApeMatRecibe);
        setNombreFis0101(d.NombreRecibe);
        setFiscalia0101(d.AutoridadRecibe);
        setAdsFis0101(d.AdscripcionRecibe);
        setCargoFis0101(d.CargoRecibe);
        setCargo0101(d.CargoRealiza);
        setChFotos(d.Fotos);
        setChAudios(d.Audios);
        setChVideos(d.Videos);
        setCertMed(d.CertMedico);
        setChOtra(d.Otra);
        setOtra(d.Cual);
        setDocComple(d.Otra || d.CertMedico || d.Videos || d.Audios || d.Fotos);

        setApePatPC0101(d.ApePatPC);
        setApeMatPC0101(d.ApeMatPC);
        setNombrePC0101(d.NombrePC);
        setPcInst(d.InstPC);
        setPcCargo(d.CargoPC);
        setPCUnidad(d.UnidadPC);
        setPCCuantos(d.ElementosPC);
        setChEntA(d.MedioEnterado == 1);
        setChEntB(d.MedioEnterado == 2);
        setChEntC(d.MedioEnterado == 3);
        setChEntD(d.MedioEnterado == 4);
        setChEntE(d.MedioEnterado == 5);
        setChEntF(d.MedioEnterado == 6);
        setChEntG(d.MedioEnterado == 7);
        setNum911(d.Telefono911);

        setFechaCon(d.FechaConocimiento);
        setHrCon(Number(d.HoraConocimiento.substring(0,2)));
        setMinCon(Number(d.HoraConocimiento.substring(3,5)));
        setFechaArribo(d.FechaArribo);
        setHrArribo(Number(d.HoraArribo.substring(0,2)));
        setMinArribo(Number(d.HoraArribo.substring(3,5)));
        setCalleInt(d.CalleInter);
        setNoExtInt(d.NumExtInter);
        setNoIntInt(d.NumIntInter);
        setCPInt(d.CpInter);
        setLatInt(d.LatInter);
        setLonInt(d.LonInter);
        setReferenciasInt(d.ReferenciasInter);
        setChInsp(d.InspeccionLugar);
        setChInspObj(d.ObjetosLugar);
        setChInspPres(d.PreservoLugar);
        setChInspPrio(d.PriorizoLugar);
        setChInspRN(d.TipoRiesgo == "N");
        setChInspRS(d.TipoRiesgo == "S");
        setInspRiesgo(d.Riesgo);
        setNarrativa(d.Narrativa);

        setLesionadosA(d.LesionadosAut);
        setLesionadosP(d.LesionadosPer);
        setFallecidosA(d.FallecidosAut);
        setFallecidosP(d.FallecidosPer);
        setFuerzaA(d.ReduccionFisica);
        setFuerzaB(d.ArmasIncapacitantes);
        setFuerzaC(d.ArmasFuego);
        setConductas(d.Conducta);
        setFuerzaAM(d.BrindoAsistMedica);
        setAsistencia(d.Asistencia);
        setApePatFuerza01(d.ApePatFuerza01);
        setApeMatFuerza01(d.ApeMatFuerza01);
        setNombreFuerza01(d.NombreFuerza01);
        setAdsFuerza01(d.AdsFuerza01);
        setCargoFuerza01(d.CargoFuerza01);
        setApePatFuerza02(d.ApePatFuerza02);
        setApeMatFuerza02(d.ApeMatFuerza02);
        setNombreFuerza02(d.NombreFuerza02);
        setAdsFuerza02(d.AdsFuerza02);
        setCargoFuerza02(d.CargoFuerza02);

        setPreservacion(d.Preservacion);
        setChApoyo(d.SolicitoApoyo);
        setApoyo(d.CualApoyo);
        setChIngreso(d.Ingreso);
        setMotivoIng(d.MotivoIngreso);
        setApePatIng01(d.ApePatIng01);
        setApeMatIng01(d.ApeMatIng01);
        setNombreIng01(d.NombreIng01);
        setAdsIng01(d.AdsIng01);
        setCargoIng01(d.CargoIng01);
        setApePatIng02(d.ApePatIng02);
        setApeMatIng02(d.ApeMatIng02);
        setNombreIng02(d.NombreIng02);
        setAdsIng02(d.AdsIng02);
        setCargoIng02(d.CargoIng02);
        setApePatEntrega(d.ApePatEnt);
        setApeMatEntrega(d.ApeMatEnt);
        setNombreEntrega(d.NombreEnt);
        setAdsEntrega(d.AdsEnt);
        setCargoEntrega(d.CargoEnt);
        setApePatRecibe(d.ApePatRec);
        setApeMatRecibe(d.ApeMatRec);
        setNombreRecibe(d.NombreRec);
        setAdsRecibe(d.AdsRec);
        setCargoRecibe(d.CargoRec);
        setObs(d.Observaciones);

        setFechaEntrega(d.FechaEntRec);
        setHrEntrega(Number(d.HoraEntRec.substring(0,2)));
        setMinEntrega(Number(d.HoraEntRec.substring(3,5)));
        setChContH(d.TipoCont == "H");
        setChContE(d.TipoCont == "E");
        setContinuacion(d.NarrativaCont);
        setApePatCont(d.ApePatNar);
        setApeMatCont(d.ApeMatNar);
        setNombreCont(d.NombreNar);
        setAdsCont(d.AdsNar);
        setCargoCont(d.CargoNar);    
/*
        setChFlagrancia(d.FormaEnterado === "P");
        setChQueja(d.FormaEnterado === "Q");
        setCh911(d.FormaEnterado === "L");
        setChOtro(d.FormaEnterado === "O");
        setNum911(d.Telefono911);
        setOtro(d.EnteradoOtro);
        setCalle(d.CalleInter);
        setNoExt(d.NumExtInter);
        setNoInt(d.NumIntInter);
        setCP(d.CpInter);
        setLat(d.LatInter);
        setLon(d.LonInter);
        setReferencias(d.ReferenciasInter);
        setNarrativa(d.Narrativa);
        setDetenidos(t);
        setAutos(a);
        */
        setPolicias(p);
        setDetenidos(t);
        setAutos(a);
        setArmas(r);
        setEntrevistas(e);
        setObjetos(o);
        setCargando(false);
        setCapturando(true);
    }
    async function quitarPolicia(idPol,nomPol){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea quitar a " + nomPol + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async(result) => {
            if (result.value){
                let url = `${edoGlobal.urlws}/iphDelito/borrarPolicia/${idRtp}?policia=${idPol}`;
                setCargando(true);
                let resp = await fetch(url);
                const res = await resp.text();
                setCargando(false);
                if(res.substring(0,2) == "ok"){
                    url = `${edoGlobal.urlws}/iphDelito/cargarPolicias/${idRtp}`;
                    resp = await fetch(url);
                    let p = await resp.json();
                    setPolicias(p);
                }else
                   Swal.fire('Atencion',res,'error');        
            }
        });
    }
    async function guardarPolicia(idPol){
        let url = `${edoGlobal.urlws}/iphDelito/guardarPolicia/${idRtp}?policia=${idPol}&orden=0`;
        setCargando(true);
        let resp = await fetch(url);
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            url = `${edoGlobal.urlws}/iphDelito/cargarPolicias/${idRtp}`;
            resp = await fetch(url);
            let p = await resp.json();
            setPolicias(p);
        }else
           Swal.fire('Atencion',res,'error');
    }
    function guardar(){
        if(id0101 == 0){
            Swal.fire('Atencion','Debe indicar los datos de quien realiza la puesta a disposición','error');
            return;
        }
        if(idFis0101 == 0){
            Swal.fire('Atencion','Debe indicar los datos de quien recibe la puesta a disposición','error');
            return;
        }
        if(idPC0101 == 0){
            Swal.fire('Atencion','Debe indicar los datos del primer correspondiente','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                guardarB();
        });
    }
    async function guardarB(){
        let detD = [];
        let detV = [];
        /*
        for(let i = 0; i < detalle.length; i++) {
            det.push({
                cod: detalle[i].Codigo,
                can: detalle[i].Cantidad,
                pre: detalle[i].Precio
            });
        }
        */
        let jsonD = JSON.stringify(detD);
        let jsonV = JSON.stringify(detV);

        if(otra != "")
           chOtra = true;
        let hr = Number(hr0101) > 9 ? hr0101 : '0' + hr0101;
        let min = Number(min0101) > 9 ? min0101 : '0' + min0101; 
        let hrC = Number(hrCon) > 9 ? hrCon : '0' + hrCon;
        let minC = Number(minCon) > 9 ? minCon : '0' + minCon; 
        let hrAr = Number(hrArribo) > 9 ? hrArribo : '0' + hrArribo;
        let minAr = Number(minArribo) > 9 ? minArribo : '0' + minArribo; 
        let hrE = Number(hrEntrega) > 9 ? hrEntrega : '0' + hrEntrega;
        let minE = Number(minEntrega) > 9 ? minEntrega : '0' + minEntrega; 
        let dato = {
            Id: idRtp, Referencia : referencia, FolioSistema : folioSistema,
            Fecha : fecha0101, Hora: hr + ":" + min,
            Expediente : expediente, IdRealiza : id0101, IdRecibe:idFis0101,
            IdPC:idPC0101,IdFuerza01:idFuerza01, IdFuerza02:idFuerza02, IdCont:idCont,
            IdIng01:idIng01, IdIng02:idIng02,IdEntrega:idEntrega, IdRec:idRecibe,
            Detenciones : detenidos.length, UsoFuerza:0, Vehiculos:0, Armas:armas.length, Entrevistas:entrevistas.length, Entregas:0,
            Continuacion:0, Fotos:chFotos, Audios:chAudios, Videos:chVideos, CertMedico:chCertMed, Otra:chOtra, Cual:otra,
            ApePatRealiza:apePat0101 , ApeMatRealiza:apeMat0101, NombreRealiza:nombre0101, AdscripcionRealiza:ads0101, CargoRealiza:cargo0101, 
            ApePatRecibe:apePatFis0101 , ApeMatRecibe:apeMatFis0101, NombreRecibe:nombreFis0101, AutoridadRecibe:fiscalia0101, AdscripcionRecibe:adsFis0101, 
            CargoRecibe:cargoFis0101, ApePatPC:apePatPC0101 , ApeMatPC:apeMatPC0101, NombrePC:nombrePC0101, InstPC:pcInst, CargoPC:pcCargo, UnidadPC:pcUnidad, ElementosPC:pcCuantos, 
            MedioEnterado:medioEnterado, Telefono911:num911,FechaConocimiento:fechaCon, HoraConocimiento:hrC + ":" + minC, MinConocimiento:0,
            FechaArribo:fechaArribo, HoraArribo:hrAr + ":" + minAr, MinArribo:0, CalleInter:calleInt, NumExtInter:noExtInt, NumIntInter:noIntInt,
            CpInter:cpInt, LatInter:latInt, LonInter:lonInt, ReferenciasInter:referenciasInt, InspeccionLugar:chInsp, ObjetosLugar:chInspObj,
            PreservoLugar:chInspPres, PriorizoLugar:chInspPrio, TipoRiesgo:chInspRN ? "NATURAL":"SOCIAL", Riesgo:inspRiesgo, Narrativa:narrativa, LesionadosAut:lesionadosA,
            LesionadosPer:lesionadosP, FallecidosAut:fallecidosA, FallecidosPer:fallecidosP, ReduccionFisica:chFuerzaA, ArmasIncapacitantes:chFuerzaB,
            ArmasFuego:chFuerzaC, Conducta:conductas, BrindoAsistMedica:chFuerzaAM, Asistencia:asistencia, ApePatFuerza01:apePatFuerza01,
            ApeMatFuerza01:apeMatFuerza01, NombreFuerza01:nombreFuerza01, AdsFuerza01:adsFuerza01, CargoFuerza01:cargoFuerza01, ApePatFuerza02:apePatFuerza02,
            ApeMatFuerza02:apeMatFuerza02, NombreFuerza02:nombreFuerza02, AdsFuerza02:adsFuerza02, CargoFuerza02:cargoFuerza02, Preservacion:preservacion,
            SolicitoApoyo:chApoyo, CualApoyo:apoyo, Ingreso:chIngreso, MotivoIngreso:motivoIng, ApePatIng01:apePatIng01, ApeMatIng01:apeMatIng01,
            NombreIng01:nombreIng01, AdsIng01:adsIng01, CargoIng01:cargoIng01, ApePatIng02:apePatIng02, ApeMatIng02:apeMatIng02, NombreIng02:nombreIng02, 
            AdsIng02:adsIng02, CargoIng02:cargoIng02, ApePatEnt:apePatEntrega, ApeMatEnt:apeMatEntrega, NombreEnt:nombreEntrega, AdsEnt:adsEntrega, CargoEnt:cargoEntrega,
            ApePatRec:apePatRecibe, ApeMatRec:apeMatRecibe, NombreRec:nombreRecibe, AdsRec:adsRecibe, CargoRec:cargoRecibe, Observaciones:obs, 
            FechaEntRec:fechaEntrega, HoraEntRec:hrE + ":" + minE, MinEntRec:0, TipoCont:chContH ? 'H':'E', NarrativaCont:continuacion, 
            ApePatNar:apePatCont, ApeMatNar:apeMatCont, NombreNar:nombreCont,AdsNar:adsCont, CargoNar:cargoCont,
            Usuario:usuario, Corporacion:corporacion 
        }
        let url = `${edoGlobal.urlws}/iphDelito/guardar`;
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            if(idRtp == 0){
               setIdRpt(res);
               setFolioSistema(res);
            }
            Swal.fire('Atencion','se guardo el reporte','success');
        }else
           Swal.fire('Atencion',res,'error');
    }
    function cancelar(){
        setCapturando(false);
    }
    function cambiarAnexo(c,v){
        if(c === 'S'){
            if(v){
                setChA(false);
                setChB(false);
                setChC(false);
                setChD(false);
                setChE(false);
                setChF(false);
                setChG(false);
            }
            setChSinA(v);
        }else if(v)
           setChSinA(false);
        if(c === 'A')
            setChA(v);
        if(c === 'B')
            setChB(v);
        if(c === 'C')
            setChC(v);
        if(c === 'D')
            setChD(v);
        if(c === 'E')
            setChE(v);
        if(c === 'F')
            setChF(v);
        if(c === 'G')
            setChG(v);
    }    
    function cambiarTraslado(c,v){
        if(v){
            setChFiscalia(c === "F");
            setChHospital(c === "H");
            setChOtraDep(c === "O");
        }
    }    
    function cambiarEnterado(c,v){
        if(v){
            setMedioEnterado(c);
            setChEntA(false);
            setChEntB(false);
            setChEntC(false);
            setChEntD(false);
            setChEntE(false);
            setChEntF(false);
            setChEntG(false);
        }
        if(c === '1')
            setChEntA(v);
        if(c === '2')
            setChEntB(v);
        if(c === '3')
            setChEntC(v);
        if(c === '4')
            setChEntD(v);
        if(c === '5')
            setChEntE(v);
        if(c === '6')
            setChEntF(v);
        if(c === '7')
            setChEntG(v);
    }    
    function cambiarRobo(c,v){
        if(v){
            setChRoboS(c == "S");
            setChRoboN(c == "N");
            setChRoboX(c == "X");
        }
    }    

    const toggle = (id) => {
        //alert(id);
        if (open === id)
           setOpen();
        else
           setOpen(id);
    };
    function nuevoDetenido(){
        editarDetenido(0);
    }
    async function editarDetenido(i){
        setIdDet(i);
        let url = `${edoGlobal.urlws}/iphDelito/cargarDetenido/${idRtp}?detenido=${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setCargando(false);
        setIdPrimerA(d.IdRealiza);
        setIdPerDet(d.IdPersona);
        setFechaDet(d.Fecha);
        setHrDet(Number(d.Hora.substring(0,2)));
        setMinDet(Number(d.Hora.substring(3,5)));
        setApePatDet(d.ApePat);
        setApeMatDet(d.ApeMat);
        setNombreDet(d.Nombre);
        setAliasDet(d.Alias);
        setFechaNac(d.FechaNac);
        setEdad(d.Edad);
        setSexo(d.Sexo);
        setNacionalidad(d.Nacionalidad);
        setCual(d.Nacionalidad2);
        setCalleDet(d.Calle);
        setNoExtDet(d.NumExt);
        setNoIntDet(d.NumInt);
        setCPDet(d.CP);
        setReferenciasDet(d.Referencias);
        setDescripDet(d.Descripcion);
        setLesiones(d.Lesiones);
        setPadecimientos(d.Padecimientos);
        setVulnerable(d.Vulnerable);
        setPadecimiento(d.Padecimiento);
        setVulnerableCual(d.GrupoVulnerable);
        setCargoPrimerA(d.CargoRealiza);
        setAdsPrimerA(d.AdscripcionRealiza);
        setApePatPrimerA(d.ApePatRealiza);
        setApeMatPrimerA(d.ApeMatRealiza);
        setNombrePrimerA(d.NombreRealiza);
        setDelictivo(d.Delictivo);
        setDelictivoCual(d.GrupoDelictivo);
        setApePatFam(d.ApePatFam);
        setApeMatFam(d.ApeMatFam);
        setNombreFam(d.NombreFam);
        setTelefonoFam(d.TelefonoFam);
        setDerechos(d.Derechos);
        setConObjetos(d.Objetos);
        setMismaDir(d.MismoLugar);
        setCalleDir(d.CalleDet);
        setNoExtDir(d.NumExtDet);
        setNoIntDir(d.NumIntDet);
        setCPDir(d.cpDet);
        setReferenciasDir(d.ReferenciasDet);
        setIdPerFam(d.IdFamiliar);
        setObsDet(d.Observaciones);
        setChHospital(d.Traslado == "H");
        setChFiscalia(d.Traslado == "F");
        setChOtraDep(d.Traslado != "F" && d.Traslado != "H");
        setDependencia(d.Traslado != "F" && d.Traslado != "H" ? d.Traslado:"");
        setVerModalDet(true);        
    }
    async function guardarDetenido(){
        if(idPerDet == 0){
            Swal.fire('Atencion','Debe especificar a la persona detenida','error');
            return;
        }
        if(idPrimerA == 0){
            Swal.fire('Atencion','Debe especificar a quien realiza la detencion','error');
            return;
        }
        let traslado = dependencia;
        if(chHospital)
           traslado = "H";
        if(chFiscalia)
           traslado = "F";
        let hr = Number(hrDet) > 9 ? hrDet : '0' + hrDet;
        let min = Number(minDet) > 9 ? minDet : '0' + minDet; 
        let dato = {
            Id: idRtp, Detenido : idDet, Usuario : usuario,IdPersona: idPerDet,
            Fecha : fechaDet, Hora: hr + ":" + min, Sexo : sexo,
            ApePat : apePatDet, ApeMat: apeMatDet, Nombre : nombreDet, Alias:aliasDet,
            FechaNac : fechaNac, Edad: edad, Nacionalidad : nacionalidad, Nacionalidad2 : cual,
            Calle : calleDet, NumExt : noExtDet, NumInt : noIntDet, CP : cpDet,
            Referencias : referenciasDet, Descripcion : descripDet,
            Padecimientos : padecimientos, Lesiones : lesiones, Vulnerable : vulnerable,
            Padecimiento : padecimiento, GrupoVulnerable : vulnerableCual, Traslado: traslado,
            IdRealiza:idPrimerA, CargoRealiza:cargoPrimerA, AdscripcionRealiza: adsPrimerA,
            IdFamiliar:idPerFam, ApePatFam:apePatFam, ApeMatFam:apeMatFam,NombreFam :nombreFam, TelefonoFam:telefonoFam,
            Delictivo:delictivo, GrupoDelictivo:delictivoCual, Derechos:derechos,
            Observaciones:obsDet, Objetos:conObjetos, MismoLugar:mismaDir, 
            CalleDet:calleDir, NumExtDet:noExtDir, NumIntDet:noIntDir, CPDet:cpDir,ReferenciasDet:setReferenciasDir 
        }
        let url = `${edoGlobal.urlws}/iphDelito/guardarDetenido`;
        setCargando(true);
        let json = JSON.stringify(dato);
        let resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            url = `${edoGlobal.urlws}/iphDelito/cargarDetenidos/${idRtp}`;
            setCargando(true);
            resp = await fetch(url);
            let a = await resp.json();
            setCargando(false);
            setDetenidos(a);    
            setVerModalDet(false);
        }else
           Swal.fire('Atencion',res,'error');
    }
    async function agregarMarca(){
        const { value: nombre } = await Swal.fire({
             title: 'Agregar nueva marca',
            input: 'text',
            inputLabel: 'nombre de la marca'
          })          
          if (!nombre)
             return;
          let nomMarca = nombre.toUpperCase();
          for(let i = 0; i < marcas.length; i++)
             if(marcas[i].N == nomMarca)
                return;
        let url = `${edoGlobal.urlws}/iphCivica/agregarMarca?nombre=${nomMarca}`;
        setCargando(true);
        let resp = await fetch(url);
        let res = await resp.text();
        if(res == "ok"){
            url = `${edoGlobal.urlws}/iphCivica/marcasVehiculo`;
            resp = await fetch(url);
            let datos = await resp.json();
            setMarcas(datos);    
        }
        else
           Swal.fire('Atencion',res,'error');
    }
    function nuevoVehiculo(){
        editarVehiculo(0);
    }
    async function editarVehiculo(i){
        setIdVeh(i);
        let url = `${edoGlobal.urlws}/iphDelito/cargarVehiculo/${idRtp}?vehiculo=${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setFechaAuto(d.Fecha);
        setHrAuto(Number(d.Hora.substring(0,2)));
        setMinAuto(Number(d.Hora.substring(3,5)));
        setTipoAuto(d.Tipo);
        setProcAuto(d.Procedencia);
        setUsoAuto(d.Uso);
        setMarcaAuto(d.Marca);
        setSubMarcaAuto(d.SubMarca);
        setModeloAuto(d.Modelo);
        setColorAuto(d.Color);
        setPlacaAuto(d.Placas);
        setSerieAuto(d.Serie);
        setObsAuto(d.Observaciones);
        setDestinoAuto(d.Destino); 
        setRepRoboAuto(d.ConReporteRobo);
        setObjetosAuto(d.ConObjetos);
        setIdAutAuto(d.IdRealiza);
        setApePatAuto(d.ApePatRealiza);
        setApeMatAuto(d.ApeMatRealiza)
        setNombreAuto(d.NombreRealiza);
        setAdsAuto(d.AdscripcionRealiza);
        setCargoAuto(d.CargoRealiza);
        setCargando(false);
        setVerModalAuto(true);        
    }
    async function guardarVehiculo(){
        let hr = Number(hrAuto) > 9 ? hrAuto : '0' + hrAuto;
        let min = Number(minAuto) > 9 ? minAuto : '0' + minAuto; 
        let repRobo = "?";
        if(chRoboS)
           repRobo = "S";
        if(chRoboN)
           repRobo = "N";
        let dato = {
            Id: idRtp, Vehiculo : idVeh, Usuario : usuario, IdRealiza: idAutAuto,
            Fecha : fecha0101, Hora: hr + ":" + min,
            Tipo : tipoAuto, Procedencia : procAuto,
            Uso : usoAuto, Marca : marcaAuto, SubMarca : subMarcaAuto,
            Modelo : modeloAuto, Color : colorAuto, Placas : placaAuto, 
            Serie : serieAuto, Observaciones : obsAuto, Destino : destinoAuto,
            ConReporteRobo : repRobo, ConObjetos:objetosAuto,
            ApePatRealiza:apePatAuto, ApeMatRealiza:apeMatAuto, NombreRealiza:nombreAuto,
            AdscripcionRealiza:adsAuto, CargoRealiza:cargoAuto
        }
        let url = `${edoGlobal.urlws}/iphDelito/guardarVehiculo`;
        setCargando(true);
        let json = JSON.stringify(dato);
        let resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            url = `${edoGlobal.urlws}/iphDelito/cargarVehiculos/${idRtp}`;
            setCargando(true);
            resp = await fetch(url);
            let a = await resp.json();
            setCargando(false);
            setAutos(a);    
            setVerModalAuto(false);
        }else
           Swal.fire('Atencion',res,'error');
    }

    function nuevaArma(){
        editarArma(0);
    }
    async function editarArma(i){
        setIdArma(i);
        let url = `${edoGlobal.urlws}/iphDelito/cargarArma/${idRtp}?arma=${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setChAportacion(d.Aportacion);
        setChInsL(d.Inspeccion == "L");
        setChInsP(d.Inspeccion == "P");
        setChInsV(d.Inspeccion == "V");
        setArmaDonde(d.Donde);
        setArmaTipo(d.Tipo);
        setArmaCal(d.Calibre);
        setArmaCol(d.Color);
        setArmaMat(d.Matricula);
        setArmaSer(d.Serie);
        setArmaObs(d.Observaciones);
        setArmaDes(d.Destino);
        setApePatArma(d.ApePatPersona);
        setApeMatArma(d.ApeMatPersona);
        setNombreArma(d.NombrePersona);
        setApePatTest1(d.ApePatTestigo1);
        setApeMatTest1(d.ApeMatTestigo1);
        setNombreTest1(d.NombreTestigo1);
        setApePatTest2(d.ApePatTestigo2);
        setApeMatTest2(d.ApeMatTestigo2);
        setNombreTest2(d.NombreTestigo2);
        setApePatRealizoA(d.ApePatRealiza);
        setApeMatRealizoA(d.ApeMatRealiza);
        setNombreRealizoA(d.NombreRealiza);
        setAdsRealizoA(d.AdsRealiza);
        setCargoRealizoA(d.CargoRealiza);
        setIdArmaRealizo(d.IdRealiza);
        setIdArmaPer(d.IdPersona);
        setIdArmaTest1(d.IdTestigo1);
        setIdArmaTest2(d.IdTestigo2);
        setCargando(false);
        setVerModalArma(true);        
    }
    async function guardarArma(){
        let inspec = "L";
        if(chInsP)
           inspec = "P";
        if(chInsV)
           inspec = "V";
        let dato = {
            Id: idRtp, Arma : idArma, Usuario : usuario,
            Aportacion:chAportacion, Inspeccion:inspec,
            Tipo:armaTipo, Calibre:armaCal, Color:armaCol,
            Matricula:armaMat, Serie:armaSer, Donde : armaDonde,
            Observaciones:armaObs, Destino:armaDes,
            AdsRealiza:adsRealizoA, CargoRealiza:cargoRealizoA,
            IdRealiza:idArmaRealizo, IdPersona:idArmaPer,
            IdTestigo1:idArmaTest1, IdTestigo2:idArmaTest2    
        }
        let url = `${edoGlobal.urlws}/iphDelito/guardarArma`;
        setCargando(true);
        let json = JSON.stringify(dato);
        let resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            url = `${edoGlobal.urlws}/iphDelito/cargarArmas/${idRtp}`;
            setCargando(true);
            resp = await fetch(url);
            let a = await resp.json();
            setCargando(false);
            setArmas(a);    
            setVerModalArma(false);
        }else
           Swal.fire('Atencion',res,'error');
    }
    function nuevoObjeto(){
        editarObjeto(0);
    }
    async function editarObjeto(i){
        setIdArma(i);
        let url = `${edoGlobal.urlws}/iphDelito/cargarObjeto/${idRtp}?obj=${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setArmaTipo(d.Apariencia.length == 1 ? "":d.Apariencia);
        setChObjD(d.Apariencia === "D");
        setChObjH(d.Apariencia === "H");
        setChObjN(d.Apariencia === "N");
        setChObjO(d.Apariencia.length > 1);
        setChAportacion(d.Aportacion);
        setChInsL(d.Inspeccion == "L");
        setChInsP(d.Inspeccion == "P");
        setChInsV(d.Inspeccion == "V");
        setArmaDonde(d.Donde);
        setArmaObs(d.Descripcion);
        setArmaDes(d.Destino);
        setApePatArma(d.ApePatPersona);
        setApeMatArma(d.ApeMatPersona);
        setNombreArma(d.NombrePersona);
        setApePatTest1(d.ApePatTestigo1);
        setApeMatTest1(d.ApeMatTestigo1);
        setNombreTest1(d.NombreTestigo1);
        setApePatTest2(d.ApePatTestigo2);
        setApeMatTest2(d.ApeMatTestigo2);
        setNombreTest2(d.NombreTestigo2);
        setApePatRealizoA(d.ApePatRealiza);
        setApeMatRealizoA(d.ApeMatRealiza);
        setNombreRealizoA(d.NombreRealiza);
        setAdsRealizoA(d.AdsRealiza);
        setCargoRealizoA(d.CargoRealiza);
        setIdArmaRealizo(d.IdRealiza);
        setIdArmaPer(d.IdPersona);
        setIdArmaTest1(d.IdTestigo1);
        setIdArmaTest2(d.IdTestigo2);
        setCargando(false);
        setVerModalObjeto(true);        
    }
    async function guardarObjeto(){
        let inspec = "L";
        let apar = armaTipo;
        if(chObjD)
           apar = "D";
        else if(chObjH)
           apar = "H";
        else if(chObjN)
           apar = "N";
        if(chInsP)
           inspec = "P";
        else if(chInsV)
           inspec = "V";
        let dato = {
            Id: idRtp, Objeto : idArma, Usuario : usuario,
            Apariencia : apar, Aportacion:chAportacion, Inspeccion:inspec,
            Donde:armaDonde, Descripcion:armaObs, Destino:armaDes,
            AdsRealiza:adsRealizoA, CargoRealiza:cargoRealizoA,
            IdRealiza:idArmaRealizo, IdPersona:idArmaPer,
            IdTestigo1:idArmaTest1, IdTestigo2:idArmaTest2    
        }
        let url = `${edoGlobal.urlws}/iphDelito/guardarObjeto`;
        setCargando(true);
        let json = JSON.stringify(dato);
        let resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            url = `${edoGlobal.urlws}/iphDelito/cargarObjetos/${idRtp}`;
            setCargando(true);
            resp = await fetch(url);
            let a = await resp.json();
            setCargando(false);
            setObjetos(a);    
            setVerModalObjeto(false);
        }else
           Swal.fire('Atencion',res,'error');
    }

    function nuevaEntrevista(){
        editarEntrevista(0);
    }
    async function editarEntrevista(i){
        setIdEnt(i);
        let url = `${edoGlobal.urlws}/iphDelito/cargarEntrevista/${idRtp}?ent=${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();

        setFechaDet(d.Fecha);
        setHrDet(Number(d.Hora.substring(0,2)));
        setMinDet(Number(d.Hora.substring(3,5)));
        setChIdentI(d.Identificacion == "I");
        setChIdentL(d.Identificacion == "L");
        setChIdentP(d.Identificacion == "P");
        setChIdentO(d.Identificacion == "O");
        setChIdentN(d.Identificacion == "N");
        setIdentificacion(d.Identificacion.length > 1 ? d.Identificacion:"");
        setIdPerDet(d.IdPersona);
        setChCalV(d.Calidad == "V");
        setChCalD(d.Calidad == "D");
        setChCalT(d.Calidad == "T");
        setCalleDet(d.Calle);
        setNoExtDet(d.NumExt);
        setNoIntDet(d.NumInt);
        setCPDet(d.CP);
        setReferenciasDet(d.Referencias);
        setNarrativa(d.Relato);
        setChTraslado(d.Traslado);
        setChFiscalia(d.Lugar == "F");
        setChHospital(d.Lugar =="H");
        setChOtraDep(d.Lugar != "F" && d.Lugar != "H");
        setDependencia(d.Lugar != "F" && d.Lugar != "H" ? d.Lugar : "");
        setIdArmaRealizo(d.IdRealiza);
        setAdsRealizoA(d.AdsRealiza);
        setCargoRealizoA(d.CargoRealiza);    
        setApePatRealizoA(d.ApePatRealiza);
        setApeMatRealizoA(d.ApeMatRealiza)
        setNombreRealizoA(d.NombreRealiza);
        setApePatDet(d.ApePatPersona);
        setApeMatDet(d.ApeMatPersona);
        setNombreDet(d.NombrePersona);
        setFechaNac(d.FechaNac);
        setEdad(d.Edad);
        setSexo(d.Sexo);
        setNacionalidad(d.Nacionalidad == "MEXICANA"?"M":"E");
        setCual(d.Nacionalidad == "MEXICANA"?"": d.Nacionalidad);
        setCargando(false);
        setVerModalEntrevista(true);        
    }
    async function guardarEntrevista(){
        let hr = Number(hrDet) > 9 ? hrDet : '0' + hrDet;
        let min = Number(minDet) > 9 ? minDet : '0' + minDet; 
        let ident = identificacion;
        let cal = "D";
        if(chCalV)
           cal = "V";
        else if(chCalT)
           cal = "T";
        if(chIdentI)
           ident = "I";
        else if(chIdentL)
           ident = "L";
        else if(chIdentP)
           ident = "P";
        else if(chIdentN)
           ident = "N";
        let dato = {
            Id: idRtp, Entrevista : idEnt, Usuario : usuario,
            Fecha : fechaDet, Hora: hr + ":" + min, IdPersona:idPerDet,
            Calle:calleDet,NumExt:noExtDet,NumInt:noIntDet, CP:cpDet,
            Referencias:referenciasDet, Relato:narrativa, Traslado:chTraslado,
            IdRealiza:idArmaRealizo,AdsRealiza:adsRealizoA,CargoRealiza:cargoRealizoA,
            Edad:edad, Identificacion :ident, Calidad : cal
        }
        let url = `${edoGlobal.urlws}/iphDelito/guardarEntrevista`;
        setCargando(true);
        let json = JSON.stringify(dato);
        let resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            url = `${edoGlobal.urlws}/iphDelito/cargarEntrevistas/${idRtp}`;
            setCargando(true);
            resp = await fetch(url);
            let a = await resp.json();
            setEntrevistas(a);
            setCargando(false);
            setVerModalEntrevista(false);
        }else
           Swal.fire('Atencion',res,'error');
    }

    const estiloListado = { display: capturando ? 'none' : 'inline' };
    const estiloCaptura = { display: !capturando ? 'none' : 'inline' };

    return (
        <div>
            <div style={estiloListado}>
                <table>
                    <tr>
                        <td>Reportes del &nbsp;</td>
                        <td><Input size="sm" type="date" value={fechaIni} onChange={(e) => setFechaIni(e.target.value)} /></td>
                        <td>&nbsp;al&nbsp;</td>
                        <td><Input size="sm" type="date" value={fechaFin} onChange={(e) => setFechaFin(e.target.value)} /></td>
                        <td>&nbsp;<Button size="sm" color="success" outline onClick={cargarReportes}><img src="/imagenes/ok.png" title="cargar reportes" /></Button></td>
                        <td>&nbsp;<Button size="sm" color="success" outline onClick={nuevo}><img src="/imagenes/add.png" title="nuevo reporte" /></Button></td>
                    </tr>
                </table>
                <Table size="sm" hover striped>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Referencia</th>
                            <th>Folio Sistema</th>
                            <th>Expediente</th>
                            <td className='celdaOpcion'></td>
                        </tr>
                    </thead>
                    <tbody>
                        {reportes.map((o, i) => <tr key={i}>
                            <td>{o.Fecha}</td>
                            <td>{o.Referencia}</td>
                            <td>{o.FolioSistema}</td>
                            <td>{o.Expediente}</td>
                            <td><img onClick={() => editar(o.Id)} src="/imagenes/edit.png" title="editar" /></td>
                        </tr>)}
                    </tbody>
                </Table>
            </div>
            <div style={estiloCaptura}>
                <Row>
                    <div className='col-3'>
                        Numero de referencia<Input size="sm" type="text" value={referencia} onChange={(e) => setReferencia(e.target.value)} />
                    </div>
                    <div className='col-3'>
                        Folio del sistema<Input size="sm" type="text" value={folioSistema} />
                    </div>
                    <div className='col-2'></div>
                    <div className='col-2'>
                        <br />
                        <Button size="sm" color="success" outline onClick={guardar}><img src="/imagenes/disk.png" />  Guardar</Button>
                    </div>
                    <div className='col-2'>
                        <br />
                        <Button size="sm" color="danger" outline onClick={cancelar}> Regresar </Button>
                    </div>
                </Row>
                <br />
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                        <AccordionHeader targetId="1">Seccion 1 - Puesta a disposicion</AccordionHeader>
                        <AccordionBody accordionId="1">
                            <Card>
                                <CardHeader>Apartado 1.1 Fecha y hora de la puesta a disposición</CardHeader>
                                <CardBody>
                                    <Row>
                                        <div className="col-2">
                                            Fecha<Input size="sm" type="date" value={fecha0101} onChange={(e) => setFecha0101(e.target.value)} />
                                        </div>
                                        <div className="col-1">
                                            Hora<Input size="sm" placeholder='hh' value={hr0101} onChange={(e) => setHr0101(e.target.value)} />
                                        </div>
                                        <div className="col-1">
                                            Minuto<Input size="sm" placeholder='mm' value={min0101} onChange={(e) => setMin0101(e.target.value)} />
                                        </div>
                                        <div className="col-4">
                                            Expediente<Input size="sm" value={expediente} onChange={(e) => setExpediente(e.target.value)} />
                                        </div>
                                    </Row>
                                    <hr />
                                    <span>Señale el o los Anexos entregados e indique la cantidad de cada uno de ellos.</span>
                                    <Row>
                                        <div className='col'>
                                            <table>
                                                <tr>
                                                    <td><b>Anexo A</b> Detencion(es)</td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td><Input type="checkbox" checked={chA} onChange={(e) => cambiarAnexo('A', e.target.checked)} /></td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td className='numeroCeldaCh'><Input size="sm" placeholder='cant' readOnly={!chA} value={cantA} onChange={(e) => setCantA(e.target.value)} /></td>
                                                    <td><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></td>
                                                    <td><b>Anexo E</b> Entrevistas</td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td><Input type="checkbox" checked={chE} onChange={(e) => cambiarAnexo('E', e.target.checked)} /></td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td className='numeroCeldaCh'><Input size="sm" placeholder='cant' readOnly={!chE} value={cantE} onChange={(e) => setCantE(e.target.value)} /></td>
                                                </tr>
                                                <tr>
                                                    <td><b>Anexo B</b> Informe del uso de la fuerza</td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td><Input type="checkbox" checked={chB} onChange={(e) => cambiarAnexo('B', e.target.checked)} /></td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td className='numeroCeldaCh'><Input size="sm" placeholder='cant' readOnly={!chB} value={cantB} onChange={(e) => setCantB(e.target.value)} /></td>
                                                    <td><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></td>
                                                    <td><b>Anexo F</b> Entrega - recepción del lugar de la intervención</td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td><Input type="checkbox" checked={chF} onChange={(e) => cambiarAnexo('F', e.target.checked)} /></td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td className='numeroCeldaCh'><Input size="sm" placeholder='cant' readOnly={!chF} value={cantF} onChange={(e) => setCantF(e.target.value)} /></td>
                                                </tr>
                                                <tr>
                                                    <td><b>Anexo C</b> Inspección de vehículo</td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td><Input type="checkbox" checked={chC} onChange={(e) => cambiarAnexo('C', e.target.checked)} /></td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td className='numeroCeldaCh'><Input size="sm" placeholder='cant' readOnly={!chC} value={cantC} onChange={(e) => setCantC(e.target.value)} /></td>
                                                    <td><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></td>
                                                    <td><b>Anexo G</b> Continuación de la narrativa de los hechos y/o entrevista</td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td><Input type="checkbox" checked={chG} onChange={(e) => cambiarAnexo('G', e.target.checked)} /></td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td className='numeroCeldaCh'><Input size="sm" placeholder='cant' readOnly={!chG} value={cantG} onChange={(e) => setCantG(e.target.value)} /></td>
                                                </tr>
                                                <tr>
                                                    <td><b>Anexo D</b> Inventario de armas y objetos</td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td><Input type="checkbox" checked={chD} onChange={(e) => cambiarAnexo('D', e.target.checked)} /></td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td className='numeroCeldaCh'><Input size="sm" placeholder='cant' readOnly={!chD} value={cantD} onChange={(e) => setCantD(e.target.value)} /></td>
                                                    <td><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></td>
                                                    <td>No se entregaron anexos</td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td><Input type="checkbox" checked={chSinA} onChange={(e) => cambiarAnexo('S', e.target.checked)} /></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <div className='col-2'>
                                            <Input type="checkbox" checked={docComple} onChange={(e) => setDocComple(e.target.checked)} />
                                            &nbsp;Anexa documentacion complementaria
                                        </div>
                                        <div className='col'>
                                            <table>
                                                <tr>
                                                    <td><Input type="checkbox" disabled={!docComple} checked={chFotos} onChange={(e) => setChFotos(e.target.checked)} />&nbsp;Fotografias</td>
                                                    <td><Input type="checkbox" disabled={!docComple} checked={chAudios} onChange={(e) => setChAudios(e.target.checked)} />&nbsp;Audio</td>
                                                </tr>
                                                <tr>
                                                    <td><Input type="checkbox" disabled={!docComple} checked={chVideos} onChange={(e) => setChVideos(e.target.checked)} />&nbsp;Videos</td>
                                                    <td><Input type="checkbox" disabled={!docComple} checked={chCertMed} onChange={(e) => setCertMed(e.target.checked)} />&nbsp;Certificados medicos</td>
                                                </tr>
                                                <tr>
                                                    <td><Input type="checkbox" disabled={!docComple} checked={chOtra} onChange={(e) => setChOtra(e.target.checked)} />Otra&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        &nbsp;&nbsp;&nbsp;&nbsp;(Especifique)</td>
                                                    <td><Input size="sm" disabled={!chOtra} value={otra} onChange={(e) => setOtra(e.target.value)} /></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Row>
                                    <hr />
                                    <Card>
                                        <CardHeader>Policias &nbsp;&nbsp;<Button size="sm" outline color="secondary" onClick={() => buscarAut(14)}><img src="/imagenes/add.png" title="agregar detenido" /></Button></CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Table size="sm" hover striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Nombre</th>
                                                            <th className='celdaOpcion'></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {policias.map((o, i) => <tr key={i}>
                                                            <td>{o.N}</td>
                                                            <td>
                                                                <img src="/imagenes/menos.png" title="quitar" onClick={() => quitarPolicia(o.I, o.N)} />
                                                            </td>
                                                        </tr>)}
                                                    </tbody>
                                                </Table>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <h6>Datos de quien realiza la puesta a disposición</h6>
                                    <Row>
                                        <div className='col'>
                                            <InputGroup>
                                                <Input size="sm" placeholder='primer apellido' value={apePat0101} />
                                                &nbsp;
                                                <Button outline size="sm" color="secondary" onClick={() => seleccionarPol(1)}>
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='segundo apellido' value={apeMat0101} />
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='nombre(s)' value={nombre0101} />
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'>
                                            <Input size="sm" type="select" value={ads0101} onChange={(e) => setAds0101(e.target.value)}>
                                                <option value={0}>Indique la institucion</option>
                                                {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                            </Input>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='cargo/grado' value={cargo0101} onChange={(e) => setCargo0101(e.target.value)} />
                                        </div>
                                    </Row>
                                    <hr />
                                    <h6>Fiscal/Autoridad que recibe la puesta a disposición</h6>
                                    <Row>
                                        <div className='col'>
                                            <InputGroup>
                                                <Input size="sm" placeholder='primer apellido' value={apePatFis0101} />
                                                &nbsp;
                                                <Button outline size="sm" color="secondary" onClick={() => buscarAut(2)}>
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='segundo apellido' value={apeMatFis0101} />
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='nombre(s)' value={nombreFis0101} />
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'>
                                            <Input size="sm" placeholder='fiscal/autoridad' value={fiscalia0101} onChange={(e) => setFiscalia0101(e.target.value)} />
                                        </div>
                                        <div className='col'>
                                        <div className='col'>
                                            <Input size="sm" type="select" value={adsFis0101} onChange={(e) => setAdsFis0101(e.target.value)}>
                                                <option value={0}>Indique la institucion</option>
                                                {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                            </Input>
                                        </div>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='cargo/grado' value={cargoFis0101} onChange={(e) => setCargoFis0101(e.target.value)} />
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="2">Seccion 2 - Primer respondiente</AccordionHeader>
                        <AccordionBody accordionId="2">
                            <Card>
                                <CardHeader>Apartado 2.1 Datos de identificación</CardHeader>
                                <CardBody>
                                    <Row>
                                        <div className='col'>
                                            <InputGroup>
                                                <Input size="sm" placeholder='primer apellido' value={apePatPC0101}  />
                                                &nbsp;
                                                <Button outline size="sm" color="secondary" onClick={() => seleccionarPol(3)}>
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='segundo apellido' value={apeMatPC0101} />
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='nombre(s)' value={nombrePC0101} />
                                        </div>
                                    </Row>
                                    <hr />
                                    <h6>Institucion a la que pertenece</h6>
                                    <Row>
                                        <div className='col-6'>
                                            <Input size="sm" type="select" value={pcInst} onChange={(e) => setPcInst(e.target.value)}>
                                                <option value={0}>Indique la institucion</option>
                                                {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                            </Input>
                                        </div>
                                        <div className='col-3'>
                                        <Input size="sm" placeholder='cargo/grado' value={pcCargo} onChange={(e) => setPcCargo(e.target.value)}/>
                                        </div>
                                   </Row>  
                                    <Row>
                                        <div className='col'>
                                            <table>
                                                <tr>
                                                    <td>En qué unidad arribó al lugar de la intervención</td>
                                                    <td><Input size="sm" value={pcUnidad} onChange={(e) => setPCUnidad(e.target.value)}/></td>
                                                    <td><Input type="checkbox" checked={chPCUnidad} onChange={(e) => setChPUnidad(e.target.checked)}/>no aplica</td>
                                                </tr>
                                            </table>
                                            <table>
                                                <tr>
                                                    <td>Arribó mas de un elemento al lugar de la intervención</td>
                                                    <td><Input type="checkbox" checked={chPCElementos} onChange={(e) => setChPElementos(e.target.checked)}/></td>
                                                    <td><Input size="sm" placeholder='cuantos' value={pcCuantos} onChange={(e) => setPCCuantos(e.target.value)}/></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="3">Seccion 3 - Conocimiento del hecho y seguimiento de la actualizacion de la autoridad</AccordionHeader>
                        <AccordionBody accordionId="3">
                            <Card>
                                <CardHeader>Apartado 3.1 Conocimiento del hecho por el primer respondiente</CardHeader>
                                <CardBody>
                                    <Row>
                                        Como se entero del hecho?
                                        <table>
                                            <tr>
                                                <td className='numeroCeldaGde'>Denuncia &nbsp;</td>
                                                <td><Input type="checkbox" checked={chEntA} onChange={(e) => cambiarEnterado('1', e.target.checked)} /></td>
                                                <td className='numeroCeldaGde'>Flagrancia &nbsp;</td>
                                                <td><Input type="checkbox" checked={chEntB} onChange={(e) => cambiarEnterado('2', e.target.checked)} /></td>
                                                <td className='numeroCeldaGde'>Localizacion &nbsp;</td>
                                                <td><Input type="checkbox" checked={chEntC} onChange={(e) => cambiarEnterado('3', e.target.checked)} /></td>
                                                <td className='numeroCeldaGde'>Mandamiento judicial &nbsp;</td>
                                                <td><Input type="checkbox" checked={chEntD} onChange={(e) => cambiarEnterado('4', e.target.checked)} /></td>
                                            </tr>
                                            <tr>
                                                <td className='numeroCeldaGde'>Llamada de emergencia &nbsp;</td>
                                                <td><Input type="checkbox" checked={chEntE} onChange={(e) => cambiarEnterado('5', e.target.checked)} /></td>
                                                <td className='numeroCeldaGde'>Descubrimiento &nbsp;</td>
                                                <td><Input type="checkbox" checked={chEntF} onChange={(e) => cambiarEnterado('6', e.target.checked)} /></td>
                                                <td className='numeroCeldaGde'>Aportacion &nbsp;</td>
                                                <td><Input type="checkbox" checked={chEntG} onChange={(e) => cambiarEnterado('7', e.target.checked)} /></td>
                                            </tr>
                                            <tr>
                                                <td><Input placeholder="911 numero" size="sm" value={num911} onChange={(e) => setNum911(e.target.value)} /></td>
                                            </tr>
                                        </table>
                                    </Row>
                                </CardBody>
                            </Card>
                            <br />
                            <Card>
                                <CardHeader>Apartado 3.2 Seguimiento de la actuación de la autoridad</CardHeader>
                                <CardBody>
                                    <table>
                                        <tr>
                                            <td colspan="3" style={{ textAlign: 'center' }}>Conocimiento del hecho</td>
                                            <td style={{ width: 200 }}></td>
                                            <td colspan="3" style={{ textAlign: 'center' }}>Arribo al lugar</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Fecha<Input size="sm" type="date" value={fechaCon} onChange={(e) => setFechaCon(e.target.value)}/>
                                            </td>
                                            <td>
                                                Hora
                                                <Input size="sm" type='select' value={hrCon} onChange={(e) => setHrCon(e.target.value)}>
                                                    {horas.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                                </Input>
                                            </td>
                                            <td>
                                                Minuto
                                                <Input size="sm" type='select' value={minCon} onChange={(e) => setMinCon(e.target.value)}>
                                                    {minutos.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                                </Input>
                                            </td>
                                            <td></td>
                                            <td>
                                                Fecha<Input size="sm" type="date" value={fechaArribo} onChange={(e) => setFechaArribo(e.target.value)}/>
                                            </td>
                                            <td>
                                                Hora
                                                <Input size="sm" type='select' value={hrArribo} onChange={(e) => setHrArribo(e.target.value)}>
                                                    {horas.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                                </Input>
                                            </td>
                                            <td>
                                                Minuto
                                                <Input size="sm" type='select' value={minArribo} onChange={(e) => setMinArribo(e.target.value)}>
                                                    {minutos.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                                </Input>
                                            </td>
                                        </tr>
                                    </table>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="4">Seccion 4 - Lugar de la intervencion</AccordionHeader>
                        <AccordionBody accordionId="4">
                            <Card>
                                <CardHeader>Apartado 4.1 Ubicación geográfica</CardHeader>
                                <CardBody>
                                    <Row>
                                        <div className='col'><Input size="sm" placeholder="calle/tramo carretero" value={calleInt} onChange={(e) => setCalleInt(e.target.value)} /></div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'><Input size="sm" placeholder="No.Exterior" value={noExtInt} onChange={(e) => setNoExtInt(e.target.value)} /></div>
                                        <div className='col'><Input size="sm" placeholder="No.Interior" value={noIntInt} onChange={(e) => setNoIntInt(e.target.value)} /></div>
                                        <div className='col-1'><Input size="sm" placeholder="C.P." value={cpInt} onChange={(e) => setCPInt(e.target.value)} /></div>
                                        <div className='col'><Input size="sm" placeholder="Latitud" value={latInt} onChange={(e) => setLatInt(e.target.value)} /></div>
                                        <div className='col'><Input size="sm" placeholder="Longitud" value={lonInt} onChange={(e) => setLonInt(e.target.value)} /></div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'><Input size="sm" placeholder="referencias" value={referenciasInt} onChange={(e) => setReferenciasInt(e.target.value)} /></div>
                                    </Row>
                                </CardBody>
                            </Card>
                            <br />
                            <Card>
                                <CardHeader>Apartado 4.2 Inspección del lugar</CardHeader>
                                <CardBody>
                                    <table>
                                        <tr>
                                            <td>Realizo inspeccion del lugar&nbsp;</td>
                                            <td><Input type="checkbox" checked={chInsp} onChange={(e) => setChInsp(e.target.checked)} /></td>
                                        </tr>
                                        <tr>
                                            <td>Al momento de realizar la inspección del lugar, ¿encontró algún objeto relacionado con los hechos?&nbsp;</td>
                                            <td><Input type="checkbox" checked={chInspObj} onChange={(e) => setChInspObj(e.target.checked)} /></td>
                                        </tr>
                                        <tr>
                                            <td>¿Preservó el lugar de la intervención?&nbsp;</td>
                                            <td><Input type="checkbox" checked={chInspPres} onChange={(e) => setChInspPres(e.target.checked)} /></td>
                                        </tr>
                                        <tr>
                                            <td>¿Llevó a cabo la priorización en el lugar de la intervención?&nbsp;</td>
                                            <td><Input type="checkbox" checked={chInspPrio} onChange={(e) => setChInspPrio(e.target.checked)} /></td>
                                        </tr>
                                    </table>
                                    <br />
                                    <table>
                                        <tr>
                                            <td>Tipo de riesgo presentado&nbsp;</td>
                                            <td>Sociales&nbsp;</td>
                                            <td><Input type="checkbox" checked={chInspRS} onChange={(e) => setChInspRS(e.target.checked)} /></td>
                                            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                            <td>Naturales&nbsp;</td>
                                            <td><Input type="checkbox" checked={chInspRN} onChange={(e) => setChInspRN(e.target.checked)} /></td>
                                        </tr>
                                    </table>
                                    <span>Especifique</span>
                                    <div className='col-12'><Input size="sm" placeholder="tipos de riesgos" value={inspRiesgo} onChange={(e) => setInspRiesgo(e.target.value)} /></div>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="5">Seccion 5 - Narrativa de los hechos</AccordionHeader>
                        <AccordionBody accordionId="5">
                            <Card>
                                <CardHeader>Apartado 5.1 Descripción de los hechos y actuación de la autoridad</CardHeader>
                                <CardBody>
                                    <span>
                                        Relate cronológicamente las acciones realizadas durante su intervención desde el conocimiento del hecho hasta la puesta a disposición. En su caso, explique las
                                        circunstancias de modo, tiempo y lugar que motivaron cada uno de los niveles de contacto y la detención. Tome como base las siguientes preguntas: ¿Quién?
                                        (personas), ¿Qué? (hechos), ¿Cómo? (circunstancias), ¿Cuándo? (tiempo) y ¿Dónde? (lugar).
                                    </span>
                                    <Row>
                                        <Input style={{ height: 400 }} type="textarea" value={narrativa} onChange={(e) => setNarrativa(e.target.value)} />
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem style={{ display: chA ? 'block' : 'none' }}>
                        <AccordionHeader targetId="6">Anexo A - Detenciones</AccordionHeader>
                        <AccordionBody accordionId="6">
                            <Card>
                                <CardHeader>Detenidos &nbsp;&nbsp;<Button size="sm" outline color="secondary" onClick={nuevoDetenido}><img src="/imagenes/add.png" title="agregar detenido" /></Button></CardHeader>
                                <CardBody>
                                    <Row>
                                        <Table size="sm" hover striped>
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Alias</th>
                                                    <th className='numeroCeldaCh'>Edad</th>
                                                    <th className='celdaOpcion'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {detenidos.map((o, i) => <tr key={i}>
                                                    <td>{o.Nombre}</td>
                                                    <td>{o.Alias}</td>
                                                    <td className='numeroCeldaCh'>{o.Edad}</td>
                                                    <td>
                                                        <img src="/imagenes/edit.png" title="editar" onClick={() => editarDetenido(o.Detenido)} />
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem style={{ display: chB ? 'block' : 'none' }}>
                        <AccordionHeader targetId="7">Anexo B - Informe del uso de la fuerza</AccordionHeader>
                        <AccordionBody accordionId="7">
                            <Card>
                                <CardHeader>Apartado B.1 Niveles del uso de la fuerza</CardHeader>
                                <CardBody>
                                    <Row>
                                        <div className='col-6'>
                                            <span>Indique cuantos</span>
                                            <table>
                                                <tr>
                                                    <td className='numeroCeldaMed'></td>
                                                    <td className='numeroCeldaCh'>Autoridad</td>
                                                    <td className='numeroCeldaCh'>Persona</td>
                                                </tr>
                                                <tr>
                                                    <td className='numeroCeldaMed'>Lesionados</td>
                                                    <td className='numeroCeldaCh'><Input size="sm" value={lesionadosA} onChange={(e) => setLesionadosA(e.target.value)} /></td>
                                                    <td className='numeroCeldaCh'><Input size="sm" value={lesionadosP} onChange={(e) => setLesionadosP(e.target.value)}/></td>
                                                </tr>
                                                <tr>
                                                    <td className='numeroCeldaMed'>Fallecidos</td>
                                                    <td className='numeroCeldaCh'><Input size="sm" value={fallecidosA} onChange={(e) => setFallecidosA(e.target.value)}/></td>
                                                    <td className='numeroCeldaCh'><Input size="sm" value={fallecidosP} onChange={(e) => setFallecidosP(e.target.value)}/></td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className='col-6'>
                                            <span>Seleccione según corresponda</span>
                                            <table>
                                                <tr>
                                                    <td>Reducción física de movimientos &nbsp;</td>
                                                    <td><Input type="checkbox" checked={chFuerzaA} onChange={(e) => setFuerzaA(e.target.checked)} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Utilización de armas incapacitantes menos letales &nbsp;</td>
                                                    <td><Input type="checkbox" checked={chFuerzaB} onChange={(e) => setFuerzaB(e.target.checked)} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Utilización de armas de fuego o fuerza letal &nbsp;</td>
                                                    <td><Input type="checkbox" checked={chFuerzaC} onChange={(e) => setFuerzaC(e.target.checked)} /></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Row>
                                    <span>Describa las conductas (resistencia activa y de alta peligrosidad) que motivaron el uso de la fuerza:</span>
                                    <Row>
                                        <Input style={{ height: 200 }} type="textarea" value={conductas} onChange={(e) => setConductas(e.target.value)} />
                                    </Row>
                                    <table>
                                        <tr>
                                            <td>¿Brindó o solicitó asistencia médica?</td>
                                            <td><Input type="checkbox" checked={chFuerzaAM} onChange={(e) => setFuerzaAM(e.target.checked)} /></td>
                                        </tr>
                                    </table>
                                    <span>Explique:</span>
                                    <Row>
                                        <Input style={{ height: 200 }} type="textarea" value={asistencia} onChange={(e) => setAsistencia(e.target.value)} />
                                    </Row>
                                </CardBody>
                            </Card>
                            <br />
                            <Card>
                                <CardHeader>Apartado B.2 Datos del primer respondiente que realizó el informe del uso de la fuerza, sólo si es diferente a quien firmó la puesta a disposición</CardHeader>
                                <CardBody>
                                    <Row>
                                        <div className='col'>
                                            <InputGroup>
                                                <Input size="sm" placeholder='primer apellido' value={apePatFuerza01}  />
                                                &nbsp;
                                                <Button outline size="sm" color="secondary" onClick={() => seleccionarPol(4)}>
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='segundo apellido' value={apeMatFuerza01} />
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='nombre(s)' value={nombreFuerza01} />
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'>
                                            <Input size="sm" type="select" value={adsFuerza01} onChange={(e) => setAdsFuerza01(e.target.value)}>
                                                <option value={0}>Indique la institucion</option>
                                                {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                            </Input>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='cargo/grado' value={cargoFuerza01} onChange={(e) => setCargoFuerza01(e.target.value)} />
                                        </div>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <div className='col'>
                                        <InputGroup>
                                            <Input size="sm" placeholder='primer apellido' value={apePatFuerza02}  />
                                                &nbsp;
                                                <Button outline size="sm" color="secondary" onClick={() => seleccionarPol(5)}>
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='segundo apellido' value={apeMatFuerza02} />
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='nombre(s)' value={nombreFuerza02} />
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'>
                                            <Input size="sm" type="select" value={adsFuerza02} onChange={(e) => setAdsFuerza02(e.target.value)}>
                                                <option value={0}>Indique la institucion</option>
                                                {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                            </Input>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='cargo/grado' value={cargoFuerza02} onChange={(e) => setCargoFuerza02(e.target.value)} />
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem style={{ display: chC ? 'block' : 'none' }}>
                        <AccordionHeader targetId="8">Anexo C - Inspeccion del vehiculo</AccordionHeader>
                        <AccordionBody accordionId="8">
                            <Card>
                                <CardHeader>Vehiculos &nbsp;&nbsp;<Button size="sm" outline color="secondary" onClick={nuevoVehiculo}><img src="/imagenes/add.png" title="agregar vehiculo" /></Button></CardHeader>
                                <CardBody>
                                    <Row>
                                        <Table size="sm" hover striped>
                                            <thead>
                                                <tr>
                                                    <th>Placas</th>
                                                    <th>Marca</th>
                                                    <th>Modelo</th>
                                                    <th>Color</th>
                                                    <th className='celdaOpcion'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {autos.map((o, i) => <tr key={i}>
                                                    <td>{o.Placas}</td>
                                                    <td>{o.Marca}</td>
                                                    <td>{o.Modelo}</td>
                                                    <td>{o.Color}</td>
                                                    <td>
                                                        <img src="/imagenes/edit.png" title="editar" onClick={() => editarVehiculo(o.Vehiculo)} />
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem style={{ display: chD ? 'block' : 'none' }}>
                        <AccordionHeader targetId="9">Anexo D - Inventario de armas y objetos</AccordionHeader>
                        <AccordionBody accordionId="9">
                            <Card>
                                <CardHeader>Armas &nbsp;&nbsp;<Button size="sm" outline color="secondary" onClick={nuevaArma}><img src="/imagenes/add.png" title="agregar arma" /></Button></CardHeader>
                                <CardBody>
                                    <Row>
                                        <Table size="sm" hover striped>
                                            <thead>
                                                <tr>
                                                    <th>Tipo</th>
                                                    <th>Calibre</th>
                                                    <th>Matricula</th>
                                                    <th className='celdaOpcion'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {armas.map((o, i) => <tr key={i}>
                                                    <td>{o.Marca}</td>
                                                    <td>{o.Color}</td>
                                                    <td>{o.Placas}</td>
                                                    <td>
                                                        <img src="/imagenes/edit.png" title="editar" onClick={() => editarArma(o.Vehiculo)} />
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </CardBody>
                            </Card>
                            <br />
                            <Card>
                                <CardHeader>Objetos &nbsp;&nbsp;<Button size="sm" outline color="secondary" onClick={nuevoObjeto}><img src="/imagenes/add.png" title="agregar objeto" /></Button></CardHeader>
                                <CardBody>
                                    <Row>
                                        <Table size="sm" hover striped>
                                            <thead>
                                                <tr>
                                                    <th>Apariencia</th>
                                                    <th>Descripcion</th>
                                                    <th className='celdaOpcion'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {objetos.map((o, i) => <tr key={i}>
                                                    <td>{o.Color}</td>
                                                    <td>{o.Marca}</td>
                                                    <td>
                                                        <img src="/imagenes/edit.png" title="editar" onClick={() => editarObjeto(o.Vehiculo)} />
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem style={{ display: chE ? 'block' : 'none' }}>
                        <AccordionHeader targetId="10">Anexo E - Entrevistas</AccordionHeader>
                        <AccordionBody accordionId="10">
                            <Card>
                                <CardHeader>Entrevistas &nbsp;&nbsp;<Button size="sm" outline color="secondary" onClick={nuevaEntrevista}><img src="/imagenes/add.png" title="agregar entrevista" /></Button></CardHeader>
                                <CardBody>
                                    <Row>
                                        <Table size="sm" hover striped>
                                            <thead>
                                                <tr>
                                                    <th className='celdaFecha'>Fecha</th>
                                                    <th>Nombre</th>
                                                    <th className='celdaOpcion'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {entrevistas.map((o, i) => <tr key={i}>
                                                    <td>{o.Color}</td>
                                                    <td>{o.Marca}</td>
                                                    <td>
                                                        <img src="/imagenes/edit.png" title="editar" onClick={() => editarEntrevista(o.Vehiculo)} />
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem style={{ display: chF ? 'block' : 'none' }}>
                        <AccordionHeader targetId="11">Anexo F - Recepcion del lugar de la intervencion</AccordionHeader>
                        <AccordionBody accordionId="11">
                            <Card>
                                <CardHeader>Apartado F.1 Preservación del lugar de la intervención</CardHeader>
                                <CardBody>
                                    <span>Explique brevemente las acciones realizadas para la preservación del lugar de la intervención. (delimitacíon, acordonamiento, clausura en lugar cerrado, etc.)</span>
                                    <Row>
                                        <Input style={{ height: 100 }} type="textarea" value={preservacion} onChange={(e) => setPreservacion(e.target.value)} />
                                    </Row>
                                    <table>
                                        <tr>
                                            <td>¿Solicitó apoyo de alguna autoridad o servicios especializados en el lugar de la intervención? &nbsp;</td>
                                            <td><Input type="checkbox" checked={chApoyo} onChange={(e) => setChApoyo(e.target.checked)} /></td>
                                        </tr>
                                    </table>
                                    <row>
                                        <Input size="sm" placeholder='cual' value={apoyo} onChange={(e) => setApoyo(e.target.value)}/>
                                    </row>
                                </CardBody>
                            </Card>
                            <br />
                            <Card>
                                <CardHeader>Apartado F.2 Acciones realizadas despúes de la preservación</CardHeader>
                                <CardBody>
                                    <table>
                                        <tr>
                                            <td>Después de la preservación del lugar de la intervención, ¿Ingresó alguna persona al lugar? &nbsp;</td>
                                            <td><Input type="checkbox" checked={chIngreso} onChange={(e) => setChIngreso(e.target.checked)} /></td>
                                        </tr>
                                    </table>
                                    <row>
                                        <Input size="sm" placeholder='motivo' value={motivoIng} onChange={(e) => setMotivoIng(e.target.value)}/>
                                    </row>
                                    <span>Datos del personal que ingresó al lugar de la intervención</span>
                                    <Row>
                                        <div className='col'>
                                            <InputGroup>
                                                <Input size="sm" placeholder='primer apellido' value={apePatIng01} />
                                                &nbsp;
                                                <Button outline size="sm" color="secondary" onClick={() => seleccionarPol(6)}>
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='segundo apellido' value={apeMatIng01} />
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='nombre(s)' value={nombreIng01} />
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'>
                                            <Input size="sm" type="select" value={adsIng01} onChange={(e) => setAdsIng01(e.target.value)} >
                                                <option value={0}>Indique la institucion</option>
                                                {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                            </Input>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='cargo/grado' value={cargoIng01} onChange={(e) => setCargoIng01(e.target.value)} />
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'>
                                            <InputGroup>
                                                <Input size="sm" placeholder='primer apellido' value={apePatIng02}  />
                                                &nbsp;
                                                <Button outline size="sm" color="secondary" onClick={() => seleccionarPol(7)}>
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='segundo apellido' value={apeMatIng02}  />
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='nombre(s)' value={nombreIng02}  />
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'>
                                            <Input size="sm" type="select" value={adsIng02} onChange={(e) => setAdsIng02(e.target.value)} >
                                                <option value={0}>Indique la institucion</option>
                                                {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                            </Input>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='cargo/grado' value={cargoIng02} onChange={(e) => setCargoIng02(e.target.value)} />
                                        </div>
                                    </Row>
                                    <br/>
                                </CardBody>
                            </Card>
                            <br />
                            <Card>
                                <CardHeader>Apartado F.3 Entrega - recepción del lugar de la intervención</CardHeader>
                                <CardBody>
                                    <span>Datos de la persona que entrega el lugar de la intervención</span>
                                    <Row>
                                        <div className='col'>
                                            <InputGroup>
                                                <Input size="sm" placeholder='primer apellido' value={apePatEntrega} />
                                                &nbsp;
                                                <Button outline size="sm" color="secondary" onClick={() => seleccionarPol(8)}>
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='segundo apellido' value={apeMatEntrega} />
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='nombre(s)' value={nombreEntrega} />
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'>
                                            <Input size="sm" type="select" value={adsEntrega} onChange={(e) => setAdsEntrega(e.target.value)} >
                                                <option value={0}>Indique la institucion</option>
                                                {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                            </Input>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='cargo/grado' value={cargoEntrega} onChange={(e) => setCargoEntrega(e.target.value)} />
                                        </div>
                                    </Row>
                                    <br />
                                    <span>Datos de la persona que recibe el lugar de la intervención</span>
                                    <Row>
                                        <div className='col'>
                                            <InputGroup>
                                                <Input size="sm" placeholder='primer apellido' value={apePatRecibe}  />
                                                &nbsp;
                                                <Button outline size="sm" color="secondary" onClick={() => buscarAut(9)}>
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='segundo apellido' value={apeMatRecibe}  />
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='nombre(s)' value={nombreRecibe}  />
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'>
                                            <Input size="sm" type="select" value={adsRecibe} onChange={(e) => setAdsRecibe(e.target.value)} >
                                                <option value={0}>Indique la institucion</option>
                                                {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                            </Input>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='cargo/grado' value={cargoRecibe} onChange={(e) => setCargoRecibe(e.target.value)} />
                                        </div>
                                    </Row>
                                    <br />
                                    <span>Observaciones</span>
                                    <Input style={{ height: 200 }} type="textarea" value={obs} onChange={(e) => setObs(e.target.value)} />
                                </CardBody>
                            </Card>
                            <br />
                            <Card>
                                <CardHeader>Apartado F.4 Fecha y hora de la entrega - recepción del lugar de la intervención</CardHeader>
                                <CardBody>
                                    <Row>
                                        <div className="col-2">
                                            Fecha<Input size="sm" type="date" value={fechaEntrega} onChange={(e) => setFechaEntrega(e.target.value)} />
                                        </div>
                                        <div className="col-1">
                                            Hora
                                            <Input size="sm" type='select' value={hrEntrega} onChange={(e) => setHrEntrega(e.target.value)}>
                                                {horas.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                            </Input>
                                        </div>
                                        <div className="col-1">
                                            Minuto
                                            <Input size="sm" type='select' value={minEntrega} onChange={(e) => setMinEntrega(e.target.value)}>
                                                {minutos.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                            </Input>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem style={{ display: chG ? 'block' : 'none' }}>
                        <AccordionHeader targetId="12">Anexo G - Continuacion de la narrativa de los hechos y/o entrevista</AccordionHeader>
                        <AccordionBody accordionId="12">
                            <table>
                                <tr>
                                    <td>Continuación de la narrativa de:</td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hechos&nbsp;</td>
                                    <td><Input type="checkbox" checked={chContH} onChange={(e) => setChContH(e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entrevista&nbsp;</td>
                                    <td><Input type="checkbox" checked={chContE} onChange={(e) => setChContE(e.target.checked)} /></td>
                                </tr>
                            </table>
                            <Input style={{ height: 400 }} type="textarea" value={continuacion} onChange={(e) => setContinuacion(e.target.value)} />
                            <span>Datos del primer respondiente que realizó la narración de los hechos y/o entrevista, sólo si es diferente a quien firmó la puesta a disposicion</span>
                            <Row>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatCont}  />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => seleccionarPol(10)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatCont}  />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreCont}/>
                                </div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col'>
                                    <Input size="sm" type="select" value={adsCont} onChange={(e) => setAdsCont(e.target.value)}>
                                        <option value={0}>Indique la institucion</option>
                                        {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='cargo/grado' value={cargoCont} onChange={(e) => setCargoCont(e.target.value)} />
                                </div>
                            </Row>
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </div>
            <Modal size="lg" isOpen={verModalDet} toggle={() => setVerModalDet(false)}>
                <ModalBody>
                    <Card>
                        <CardHeader>Apartado A.1 Fecha y hora de la detención</CardHeader>
                        <CardBody>
                            <Row>
                                <div className="col-3">
                                    Fecha<Input size="sm" type="date" value={fechaDet} onChange={(e) => setFechaDet(e.target.value)} />
                                </div>
                                <div className="col-2">
                                    Hora
                                    <Input size="sm" type='select' value={hrDet} onChange={(e) => setHrDet(e.target.value)}>
                                        {horas.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                    </Input>
                                </div>
                                <div className="col-2">
                                    Minuto
                                    <Input size="sm" type='select' value={minDet} onChange={(e) => setMinDet(e.target.value)}>
                                        {minutos.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                    </Input>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado A.2 Datos generales de la persona detenida</CardHeader>
                        <CardBody>
                            <Row>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatDet} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => buscarPer(1)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatDet}/>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreDet} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='alias' value={aliasDet}  />
                                </div>
                            </Row>
                            <br />
                            <Row>
                                <div className="col-3">
                                    Fecha Nacimiento <Input size="sm" type="date" value={fechaNac} />
                                </div>
                                <div className="col-1">
                                    Edad <Input size="sm" type="text" value={edad} />
                                </div>
                                <div className="col-2">
                                    Sexo <Input size="sm" type="select" value={sexo} >
                                        <option value="?">Espeficique</option>
                                        <option value="H">Hombre</option>
                                        <option value="M">Mujer</option>
                                    </Input>
                                </div>
                                <div className="col-2">
                                    Nacionalidad <Input size="sm" type="select" value={nacionalidad} >
                                        <option value="M">Mexicana</option>
                                        <option value="E">Extranjera</option>
                                    </Input>
                                </div>
                                <div className="col">
                                    Cual <Input size="sm" type="text" value={cual} />
                                </div>
                            </Row>
                            <span>Domicilio</span>
                            <Row>
                                <div className='col'><Input size="sm" placeholder="calle/tramo carretero" value={calleDet} onChange={(e) => setCalleDet(e.target.value)} /></div>
                                <div className='col-2'><Input size="sm" placeholder="No.Exterior" value={noExtDet} onChange={(e) => setNoExtDet(e.target.value)} /></div>
                                <div className='col-2'><Input size="sm" placeholder="No.Interior" value={noIntDet} onChange={(e) => setNoIntDet(e.target.value)} /></div>
                                <div className='col-2'><Input size="sm" placeholder="C.P." value={cpDet} onChange={(e) => setCPDet(e.target.value)} /></div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col'><Input size="sm" placeholder="referencias" value={referenciasDet} onChange={(e) => setReferenciasDet(e.target.value)} /></div>
                            </Row>
                            <span>Describa brevemente a la persona detenida, incluyendo tipo de vestimenta y rasgos visibles (barba, tatuajes, cicatrices, lunares, bigote, etc.). En caso de no contar
                                con la fecha de nacimiento, señalar la edad aproximada de la persona</span>
                            <Row>
                                <Input style={{ height: 200 }} type="textarea" value={descripDet} onChange={(e) => setDescripDet(e.target.value)} />
                            </Row>
                            <table>
                                <tr>
                                    <td><Input type="checkbox" checked={lesiones} onChange={(e) => setLesiones(e.target.checked)} /></td>
                                    <td><span>Presenta lesiones</span></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><Input type="checkbox" checked={padecimientos} onChange={(e) => setPadecimientos(e.target.checked)} /></td>
                                    <td><span>Manifiesta algun padecimiento</span></td>
                                    <td><Input size="sm" placeholder='especifique padecimiento' value={padecimiento} onChange={(e) => setPadecimiento(e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td><Input type="checkbox" checked={vulnerable} onChange={(e) => setVulnerable(e.target.checked)} /></td>
                                    <td><span>Se identificó como miembro de algún grupo vulnerable</span></td>
                                    <td><Input style={{ width: 400 }} size="sm" placeholder='especique el grupo' value={vulnerableCual} onChange={(e) => setVulnerableCual(e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td><Input type="checkbox" checked={delictivo} onChange={(e) => setDelictivo(e.target.checked)} /></td>
                                    <td><span>Se identificó como miembro de algún grupo delictivo</span></td>
                                    <td><Input style={{ width: 400 }} size="sm" placeholder='especique el grupo' value={delictivoCual} onChange={(e) => setDelictivoCual(e.target.value)} /></td>
                                </tr>
                            </table>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado A.3 Datos del familiar o persona de confianza señalado por la persona detenida</CardHeader>
                        <CardBody>
                            <Row>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatFam} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => buscarPer(2)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatFam}  />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreFam}  />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='telefono' value={telefonoFam} onChange={(e) => setTelefonoFam(e.target.value)} />
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado A.4 Constancia de lectura de derechos de la persona detenida</CardHeader>
                        <CardBody>
                            <span>Artículo 20 apartado B de la Constitución Política de los Estados Unidos Mexicanos y artículo 152 del Código Nacional de Procedimientos Penales</span>
                            <span>Informe a la persona detenida</span>
                            <ul>
                                <li>Usted tiene derecho a conocer el motivo de su detención.</li>
                                <li>Usted tiene derecho a guardar silencio.</li>
                                <li>Usted tiene derecho a declarar, y en caso de hacerlo, lo hará asistido de su defensor ante la autoridad competente.</li>
                                <li>Usted tiene derecho a ser asistido por un defensor, si no quiere o no puede hacerlo, le será designado un defensor público.</li>
                                <li>Usted tiene derecho a hacer del conocimiento a un familiar o persona que desee, los hechos de su detención y el lugar de custodia en que se halle en cada momento.</li>
                                <li>Usted es considerado inocente desde este momento hasta que se determine lo contrario.</li>
                                <li>En caso de ser extranjero, Usted tiene derecho a que el consulado de su país sea notificado de su detención.</li>
                                <li>Usted tiene derecho a un traductor o intérprete, el cual será proporcionado por el Estado.</li>
                                <li>Usted tiene derecho a ser presentado ante el Ministerio Público o Juez de Control, según sea el caso, inmediatamente después de ser detenido o aprehendido.</li>
                            </ul>
                            <span>Si la persona detenida es adolescente, informe tambien:</span><br />
                            <ul>
                                <li>Usted tiene derecho a permanecer en un lugar distinto al de los adultos.</li>
                                <li>Usted tiene derecho a un trato digno y de conformidad con su condición de adolescente.</li>
                                <li>Usted tiene derecho a que la autoridad informe sobre su detención a la procuraduría federal o local de protección de niñas, niños y adolescentes.</li>
                            </ul>
                            <table>
                                <tr>
                                    <td>¿Le informó sus derechos a la persona detenida?</td>
                                    <td><Input type="checkbox" checked={derechos} onChange={(e) => setDerechos(e.target.checked)} /></td>
                                </tr>
                            </table>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado A.5 Inspección a la persona detenida</CardHeader>
                        <CardBody>
                            <table>
                                <tr>
                                    <td>Al momento de realizar la inspección a la persona detenida, ¿le encontró algún objeto relacionado con los hechos?</td>
                                    <td><Input type="checkbox" checked={conObjetos} onChange={(e) => setConObjetos(e.target.checked)} /></td>
                                </tr>
                                <tr>
                                    <td>¿Recolectó pertenencias de la persona detenida?</td>
                                    <td><Input type="checkbox" checked={conPertenencias} onChange={(e) => setConPertenencias(e.target.checked)} /></td>
                                </tr>
                            </table>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td style={{ width: '5%' }}></td>
                                    <td style={{ width: '5%' }}>&nbsp;&nbsp;Cant.</td>
                                    <td style={{ width: '50%' }}>&nbsp;&nbsp;Breve descripcion</td>
                                    <td>&nbsp;&nbsp;Destino que se le dio</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td><Input size="sm" /></td>
                                    <td><Input size="sm" /></td>
                                    <td><Input size="sm" /></td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td><Input size="sm" /></td>
                                    <td><Input size="sm" /></td>
                                    <td><Input size="sm" /></td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td><Input size="sm" /></td>
                                    <td><Input size="sm" /></td>
                                    <td><Input size="sm" /></td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td><Input size="sm" /></td>
                                    <td><Input size="sm" /></td>
                                    <td><Input size="sm" /></td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td><Input size="sm" /></td>
                                    <td><Input size="sm" /></td>
                                    <td><Input size="sm" /></td>
                                </tr>
                            </table>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado A.6 Lugar de la detencion</CardHeader>
                        <CardBody>
                            <table>
                                <tr>
                                    <td>¿El lugar de la detención es el mismo que el de la intervención?</td>
                                    <td><Input type="checkbox" checked={mismaDir} onChange={(e) => cambiarMismaDir(e.target.checked)} /></td>
                                </tr>
                            </table>
                            <Row>
                                <div className='col'><Input size="sm" placeholder="calle/tramo carretero" value={calleDir} onChange={(e) => setCalleDir(e.target.value)} /></div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col'><Input size="sm" placeholder="No.Exterior" value={noExtDir} onChange={(e) => setNoExtDir(e.target.value)} /></div>
                                <div className='col'><Input size="sm" placeholder="No.Interior" value={noIntDir} onChange={(e) => setNoIntDir(e.target.value)} /></div>
                                <div className='col-1'><Input size="sm" placeholder="C.P." value={cpDir} onChange={(e) => setCPDir(e.target.value)} /></div>
                                <div className='col'><Input size="sm" placeholder="Latitud" value={latDir} onChange={(e) => setLatDir(e.target.value)} /></div>
                                <div className='col'><Input size="sm" placeholder="Longitud" value={lonDir} onChange={(e) => setLonDir(e.target.value)} /></div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col'><Input size="sm" placeholder="referencias" value={referenciasDet} onChange={(e) => setReferenciasDet(e.target.value)} /></div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado A.7 Datos del lugar del traslado de la persona detenida</CardHeader>
                        <CardBody>
                            <table>
                                <tr>
                                    <td>El lugar de traslado</td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fiscalia/Agencia&nbsp;</td>
                                    <td><Input type="checkbox" checked={chFiscalia} onChange={(e) => cambiarTraslado('F', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hospital&nbsp;</td>
                                    <td><Input type="checkbox" checked={chHospital} onChange={(e) => cambiarTraslado('H', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Otra dependencia&nbsp;</td>
                                    <td><Input type="checkbox" checked={chOtraDep} onChange={(e) => cambiarTraslado('O', e.target.checked)} /></td>
                                </tr>
                            </table>
                            <Row>
                                <div className='col'><Input size="sm" placeholder="otra dependencia" value={dependencia} onChange={(e) => setDependencia(e.target.value)} /></div>
                            </Row>
                            <span>Observaciones relacionadas con la detención.</span><br />
                            <span>Describa brevemente la ruta y el medio de traslado desde el lugar de la detención hasta la puesta a disposición, así como la razón de posibles demoras. Incluya cualquier otra observación que considere relevante.</span>
                            <Input style={{ height: 200 }} type="textarea" value={obsDet} onChange={(e) => setObsDet(e.target.value)} />
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado A.8  Datos del primer respondiente que realizó la detención</CardHeader>
                        <CardBody>
                            <Row>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatPrimerA} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => seleccionarPol(11)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatPrimerA}  />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombrePrimerA} />
                                </div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col'>
                                    <Input type="select" size="sm" placeholder='adscripcion' value={adsPrimerA} onChange={(e) => setAdsPrimerA(e.target.value)}>
                                        <option value={0}>Indique la institucion</option>
                                        {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='cargo/grado' value={cargoPrimerA} onChange={(e) => setCargoPrimerA(e.target.value)} />
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <Row>
                        <div className='col-4'></div>
                        <div className='col-2'><Button size="sm" color="success" outline onClick={guardarDetenido}>Aceptar</Button></div>
                        <div className='col-2'><Button size="sm" color="danger" outline onClick={() => setVerModalDet(false)}>Cancelar</Button></div>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={verModalAuto} toggle={() => setVerModalAuto(false)}>
                <ModalBody>
                    <Card>
                        <CardHeader>Apartado C.1 Fecha y hora de la retención del vehículo</CardHeader>
                        <CardBody>
                            <Row>
                                <div className="col-3">
                                    Fecha<Input size="sm" type="date" value={fechaAuto} onChange={(e) => setFechaAuto(e.target.value)} />
                                </div>
                                <div className="col-2">
                                    Hora
                                    <Input size="sm" type='select' value={hrAuto} onChange={(e) => setHrAuto(e.target.value)}>
                                        {horas.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                    </Input>
                                </div>
                                <div className="col-2">
                                    Minuto
                                    <Input size="sm" type='select' value={minAuto} onChange={(e) => setMinAuto(e.target.value)}>
                                        {minutos.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                    </Input>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado C.2 Datos generales del vehículo retenido</CardHeader>
                        <CardBody>
                            <Row>
                                <div className="col-4">
                                    Tipo <Input size="sm" type="select" value={tipoAuto} onChange={(e) => setTipoAuto(e.target.value)}>
                                        <option value={0}>Espeficique</option>
                                        {tiposAuto.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </div>
                                <div className="col-4">
                                    Procedencia <Input size="sm" type="select" value={procAuto} onChange={(e) => setProcAuto(e.target.value)}>
                                        <option value={0}>Espeficique</option>
                                        {procedencias.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </div>
                                <div className="col-4">
                                    Color<Input size="sm" type="text" value={colorAuto} onChange={(e) => setColorAuto(e.target.value)} />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-5">
                                    Marca
                                    <InputGroup>
                                        <Input size="sm" type="select" value={marcaAuto} onChange={(e) => setMarcaAuto(e.target.value)}>
                                            <option value={0}>Espeficique</option>
                                            {marcas.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                        </Input>
                                        <Button size="sm" outline onClick={agregarMarca}><img src="/imagenes/add.png" /></Button>
                                    </InputGroup>
                                </div>
                                <div className="col-5">
                                    SubMarca<Input size="sm" type="text" value={subMarcaAuto} onChange={(e) => setSubMarcaAuto(e.target.value)} />
                                </div>
                                <div className="col-2">
                                    Modelo<Input size="sm" type="text" value={modeloAuto} onChange={(e) => setModeloAuto(e.target.value)} />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-4">
                                    Uso <Input size="sm" type="select" value={usoAuto} onChange={(e) => setUsoAuto(e.target.value)}>
                                        <option value={0}>Espeficique</option>
                                        {usosAuto.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </div>
                                <div className="col-3">
                                    Placa<Input size="sm" type="text" value={placaAuto} onChange={(e) => setPlacaAuto(e.target.value)} />
                                </div>
                                <div className="col-4">
                                    No. de serie<Input size="sm" type="text" value={serieAuto} onChange={(e) => setSerieAuto(e.target.value)} />
                                </div>
                            </Row>
                            <div>
                                <span>Situacion:</span>
                                <table>
                                    <tr>
                                        <td>Con reporte de robo &nbsp;</td>
                                        <td><Input type="checkbox" checked={chRoboS} onChange={(e) => cambiarRobo('S', e.target.checked)} /></td>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sin reporte de robo &nbsp;</td>
                                        <td><Input type="checkbox" checked={chRoboN} onChange={(e) => cambiarRobo('N', e.target.checked)} /></td>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No es posible saberlo &nbsp;</td>
                                        <td><Input type="checkbox" checked={chRoboX} onChange={(e) => cambiarRobo('X', e.target.checked)} /></td>
                                    </tr>
                                </table>
                            </div>
                            <Row>
                                <div className="col">
                                    Observaciones<Input size="sm" type="text" value={obsAuto} onChange={(e) => setObsAuto(e.target.value)} />
                                </div>
                            </Row>
                            <Row>
                                <div className="col">
                                    Destino<Input size="sm" type="text" value={destinoAuto} onChange={(e) => setDestinoAuto(e.target.value)} />
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado C.3 Objetos encontrados en el vehículo inspeccionado</CardHeader>
                        <CardBody>
                            <div>
                                <table>
                                    <tr>
                                        <td>Encontró objetos relacionados con los hechos &nbsp;</td>
                                        <td><Input type="checkbox" checked={objetosAuto} onChange={(e) => setObjetosAuto(e.target.checked)} /></td>
                                    </tr>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado C.4 Datos del primer respondiente que realizó el resguardo, sólo si es diferente a quien firmó la puesta a disposición</CardHeader>
                        <CardBody>
                            <Row>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatAuto}  />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => buscarAut(12)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatAuto}  />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreAuto} />
                                </div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col'>
                                    <Input size="sm" type="select" placeholder='adscripcion' value={adsAuto} onChange={(e) => setAdsAuto(e.target.value)}>
                                    <option value={0}>Indique la institucion</option>
                                    {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='cargo/grado' value={cargoAuto} onChange={(e) => cargoAuto(e.target.value)} />
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Row>
                        <div className='col-4'></div>
                        <div className='col-2'><Button size="sm" color="success" outline onClick={guardarVehiculo}>Aceptar</Button></div>
                        <div className='col-2'><Button size="sm" color="danger" outline onClick={() => setVerModalAuto(false)}>Cancelar</Button></div>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={verModalArma} toggle={() => setVerModalArma(false)}>
                <ModalBody>
                    <Card>
                        <CardHeader>Apartado D.1 Registro de armas de fuego</CardHeader>
                        <CardBody>
                            <span>Indique si se trata de aportación o inspección, según corresponda. </span>
                            <table>
                                <tr>
                                    <td>Aportacion &nbsp;</td>
                                    <td><Input type="checkbox" checked={chAportacion} onChange={(e) => setChAportacion(e.target.checked)} /></td>
                                    <td colspan="7"></td>
                                </tr>
                                <tr>
                                    <td>Inspeccion &nbsp;</td>
                                    <td>Lugar &nbsp;</td>
                                    <td><Input type="checkbox" checked={chInsL} onChange={(e) => cambiarInspeccion('L', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Persona &nbsp;</td>
                                    <td><Input type="checkbox" checked={chInsP} onChange={(e) => cambiarInspeccion('P', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vehiculo &nbsp;</td>
                                    <td><Input type="checkbox" checked={chInsV} onChange={(e) => cambiarInspeccion('V', e.target.checked)} /></td>
                                    <td>Donde se encontro?</td>
                                    <td><Input size="sm" value={armaDonde} onChange={(e)=>setArmaDonde(e.target.value)} /></td>
                                </tr>
                            </table>
                            <Row>
                                <div className="col-2">
                                    Tipo <Input size="sm" type="select" value={armaTipo} onChange={(e) => setArmaTipo(e.target.value)}>
                                        <option value="?">Indique</option>
                                        <option value="C">Corta</option>
                                        <option value="L">Larga</option>
                                    </Input>
                                </div>
                                <div className="col-2">
                                    Calibre <Input size="sm" value={armaCal} onChange={(e) => setArmaCal(e.target.value)} />
                                </div>
                                <div className="col-2">
                                    Color <Input size="sm" value={armaCol} onChange={(e) => setArmaCol(e.target.value)} />
                                </div>
                                <div className="col">
                                    Matricula <Input size="sm" value={armaMat} onChange={(e) => setArmaMat(e.target.value)} />
                                </div>
                                <div className="col">
                                    Serie <Input size="sm" value={armaSer} onChange={(e) => setArmaSer(e.target.value)} />
                                </div>
                            </Row>
                            <Row>
                                <div className='col-7'>
                                    <span>Observaciones (características, marca, cargadores y cartuchos):</span>
                                    <Input style={{ height: 80 }} type="textarea" value={armaObs} onChange={(e) => setArmaObs(e.target.value)} />
                                </div>
                                <div className='col-5'>
                                    <span>Destino que se le dio:</span>
                                    <Input style={{ height: 80 }} type="textarea" value={armaDes} onChange={(e) => setArmaDes(e.target.value)} />
                                </div>
                            </Row>
                            <Row>
                                <span>Anote el nombre y firma de la persona a la que se le aseguró el arma:</span>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatArma} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => buscarPer(3)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatArma} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreArma}  />
                                </div>
                            </Row>
                            <Row>
                                <span>En caso de que la persona a la que se le aseguró el arma no acceda a firmar, anote nombre y firma de dos testigos:</span>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatTest1} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => buscarPer(4)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatTest1} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreTest1} />
                                </div>
                            </Row>
                            <Row>
                                <span>En caso de que la persona a la que se le aseguró el arma no acceda a firmar, anote nombre y firma de dos testigos:</span>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatTest2} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => buscarPer(5)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatTest2} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreTest2} />
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado D.2 Datos del primer respondiente que realizó la recolección y/o aseguramiento de la o las armas, sólo si es diferente a quien firmo la puesta a disposicion</CardHeader>
                        <CardBody>
                            <Row>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatRealizoA} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => seleccionarPol(13)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatRealizoA} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreRealizoA} />
                                </div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col'>
                                    <Input type="select" size="sm" placeholder='adscripcion' value={adsRealizoA} onChange={(e) => setAdsRealizoA(e.target.value)}>
                                        <option value={0}>Indique la institucion</option>
                                        {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='cargo/grado' value={cargoRealizoA} onChange={(e) => setCargoRealizoA(e.target.value)} />
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Row>
                        <div className='col-4'></div>
                        <div className='col-2'><Button size="sm" color="success" outline onClick={guardarArma}>Aceptar</Button></div>
                        <div className='col-2'><Button size="sm" color="danger" outline onClick={() => setVerModalArma(false)}>Cancelar</Button></div>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={verModalObjeto} toggle={() => setVerModalObjeto(false)}>
                <ModalBody>
                    <Card>
                        <CardHeader>Apartado D.3 Registro de objetos recolectados y/o asegurados relacionados con el hecho probablemente delictivo</CardHeader>
                        <CardBody>
                            <span>¿Qué encontró? (apariencia de):</span>
                            <table>
                                <tr>
                                    <td>Narcotico &nbsp;</td>
                                    <td><Input type="checkbox" checked={chObjD} onChange={(e) => cambiarObjeto('D', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hidrocarburo &nbsp;</td>
                                    <td><Input type="checkbox" checked={chObjH} onChange={(e) => cambiarObjeto('H', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Numerario &nbsp;</td>
                                    <td><Input type="checkbox" checked={chObjN} onChange={(e) => cambiarObjeto('N', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Otro</td>
                                    <td><Input type="checkbox" checked={chObjO} onChange={(e) => cambiarObjeto('O', e.target.checked)} /></td>
                                    <td><Input size="sm" value={armaTipo} onChange={(e)=>setArmaTipo(e.target.value)}/></td>
                                </tr>
                            </table>
                            <span>Indique si se trata de aportación o inspección, según corresponda. </span>
                            <table>
                                <tr>
                                    <td>Aportacion &nbsp;</td>
                                    <td><Input type="checkbox" checked={chAportacion} onChange={(e) => setChAportacion(e.target.checked)} /></td>
                                    <td colspan="7"></td>
                                </tr>
                                <tr>
                                    <td>Inspeccion &nbsp;</td>
                                    <td>Lugar &nbsp;</td>
                                    <td><Input type="checkbox" checked={chInsL} onChange={(e) => cambiarInspeccion('L', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Persona &nbsp;</td>
                                    <td><Input type="checkbox" checked={chInsP} onChange={(e) => cambiarInspeccion('P', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vehiculo &nbsp;</td>
                                    <td><Input type="checkbox" checked={chInsV} onChange={(e) => cambiarInspeccion('V', e.target.checked)} /></td>
                                    <td>Donde se encontro?</td>
                                    <td><Input size="sm" value={armaDonde} onChange={(e)=>setArmaDonde(e.target.value)}/></td>
                                </tr>
                            </table>
                            <Row>
                                <div className='col-7'>
                                    <span>Breve descripción del objeto: </span>
                                    <Input style={{ height: 80 }} type="textarea" value={armaObs} onChange={(e) => setArmaObs(e.target.value)} />
                                </div>
                                <div className='col-5'>
                                    <span>Destino que se le dio:</span>
                                    <Input style={{ height: 80 }} type="textarea" value={armaDes} onChange={(e) => setArmaDes(e.target.value)} />
                                </div>
                            </Row>
                            <Row>
                                <span>Anote el nombre y firma de la persona a la que se le aseguró el objeto:</span>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatArma} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => buscarPer(3)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatArma} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreArma}  />
                                </div>
                            </Row>
                            <Row>
                                <span>En caso de que la persona a la que se le aseguró el arma no acceda a firmar, anote nombre y firma de dos testigos:</span>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatTest1} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => buscarPer(4)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatTest1} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreTest1} />
                                </div>
                            </Row>
                            <Row>
                                <span>En caso de que la persona a la que se le aseguró el arma no acceda a firmar, anote nombre y firma de dos testigos:</span>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatTest2} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => buscarPer(5)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatTest2} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreTest2} />
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado D.4  Datos del primer respondiente que realizó la recolección y/o aseguramiento del o los objetos, sólo si es diferente a quien firmo la puesta a disposicion</CardHeader>
                        <CardBody>
                            <Row>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatRealizoA} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => seleccionarPol(13)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatRealizoA} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreRealizoA} />
                                </div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col'>
                                    <Input type="select" size="sm" placeholder='adscripcion' value={adsRealizoA} onChange={(e) => setAdsRealizoA(e.target.value)}>
                                        <option value={0}>Indique la institucion</option>
                                        {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='cargo/grado' value={cargoRealizoA} onChange={(e) => setCargoRealizoA(e.target.value)} />
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Row>
                        <div className='col-4'></div>
                        <div className='col-2'><Button size="sm" color="success" outline onClick={guardarObjeto}>Aceptar</Button></div>
                        <div className='col-2'><Button size="sm" color="danger" outline onClick={() => setVerModalObjeto(false)}>Cancelar</Button></div>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={verModalEntrevista} toggle={() => setVerModalEntrevista(false)}>
                <ModalBody>
                    <Card>
                        <CardHeader>Apartado E.1 Fecha y hora del lugar de la entrevista</CardHeader>
                        <CardBody>
                            <Row>
                                <div className="col-3">
                                    Fecha<Input size="sm" type="date" value={fechaDet} onChange={(e) => setFechaDet(e.target.value)} />
                                </div>
                                <div className="col-2">
                                    Hora
                                    <Input size="sm" type='select' value={hrDet} onChange={(e) => setHrDet(e.target.value)}>
                                        {horas.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                    </Input>
                                </div>
                                <div className="col-2">
                                    Minuto
                                    <Input size="sm" type='select' value={minDet} onChange={(e) => setMinDet(e.target.value)}>
                                        {minutos.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                    </Input>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado E.2 Datos generales</CardHeader>
                        <CardBody>
                            <Row>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatDet} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => buscarPer(1)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatDet} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreDet} />
                                </div>
                            </Row>
                            <table>
                                <tr>
                                    <td>Calidad &nbsp;</td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;Victima u ofendido &nbsp;</td>
                                    <td><Input type="checkbox" checked={chCalV} onChange={(e) => cambiarCalidad('V', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Denunciante &nbsp;</td>
                                    <td><Input type="checkbox" checked={chCalD} onChange={(e) => cambiarCalidad('D', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Testigo &nbsp;</td>
                                    <td><Input type="checkbox" checked={chCalT} onChange={(e) => chCalV('T', e.target.checked)} /></td>
                                </tr>
                            </table>
                            <Row>
                                <div className="col-3">
                                    Fecha Nacimiento <Input size="sm" type="date" value={fechaNac} />
                                </div>
                                <div className="col-1">
                                    Edad <Input size="sm" type="text" value={edad} />
                                </div>
                                <div className="col-2">
                                    Sexo <Input size="sm" type="select" value={sexo} >
                                        <option value="?">Espeficique</option>
                                        <option value="H">Hombre</option>
                                        <option value="M">Mujer</option>
                                    </Input>
                                </div>
                                <div className="col-2">
                                    Nacionalidad <Input size="sm" type="select" value={nacionalidad} >
                                        <option value="M">Mexicana</option>
                                        <option value="E">Extranjera</option>
                                    </Input>
                                </div>
                                <div className="col">
                                    Cual <Input size="sm" type="text" value={cual} />
                                </div>
                            </Row>
                            <table>
                                <tr>
                                    <td>¿Se identificó con algún documento? &nbsp;</td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;INE &nbsp;</td>
                                    <td><Input type="checkbox" checked={chIdentI} onChange={(e) => cambiarIdent('I', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Licencia &nbsp;</td>
                                    <td><Input type="checkbox" checked={chIdentL} onChange={(e) => cambiarIdent('L', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pasaporte &nbsp;</td>
                                    <td><Input type="checkbox" checked={chIdentP} onChange={(e) => cambiarIdent('P', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Otro &nbsp;</td>
                                    <td><Input type="checkbox" checked={chIdentO} onChange={(e) => cambiarIdent('O', e.target.checked)} /></td>
                                    <td><Input size="sm" placeholder="otro" /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No &nbsp;</td>
                                    <td><Input type="checkbox" checked={chIdentN} onChange={(e) => cambiarIdent('N', e.target.checked)} /></td>
                                </tr>
                            </table>
                            <Row>
                                <div className="col">
                                    No.Identificacion <Input size="sm" type="text" value={identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                                </div>
                                <div className="col-3">
                                    No.Telefono <Input size="sm" type="text" value={tel} onChange={(e) => setTel(e.target.value)} />
                                </div>
                                <div className="col">
                                    Email <Input size="sm" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </Row>
                            <span>Domicilio de la persona entrevistada</span><br />
                            <Row>
                                <div className='col'><Input size="sm" placeholder="calle/tramo carretero" value={calleDet} onChange={(e) => setCalleDet(e.target.value)} /></div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col-2'><Input size="sm" placeholder="No.Exterior" value={noExtDet} onChange={(e) => setNoExtDet(e.target.value)} /></div>
                                <div className='col-2'><Input size="sm" placeholder="No.Interior" value={noIntDet} onChange={(e) => setNoIntDet(e.target.value)} /></div>
                                <div className='col-2'><Input size="sm" placeholder="C.P." value={cpDet} onChange={(e) => setCPDet(e.target.value)} /></div>
                                <div className='col'><Input size="sm" placeholder="Colonia" value={colonia} onChange={(e) => setColonia(e.target.value)} /></div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col'><Input size="sm" placeholder="referencias" value={referenciasDet} onChange={(e) => setReferenciasDet(e.target.value)} /></div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado E.3 Relato de la entrevista</CardHeader>
                        <CardBody>
                            <Row>
                                <Input style={{ height: 300 }} type="textarea" value={narrativa} onChange={(e) => setNarrativa(e.target.value)} />
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado E.4 Datos del lugar del traslado o canalización de la persona entrevistada</CardHeader>
                        <CardBody>
                            <table>
                                <tr>
                                    <td>¿Trasladó o canalizó a la persona entrevistada? &nbsp;</td>
                                    <td><Input type="checkbox" checked={chTraslado} onChange={(e) => setChTraslado(e.target.checked)} /></td>
                                </tr>
                            </table>
                            <table>
                                <tr>
                                    <td>Lugar de traslado o canalización: &nbsp;</td>
                                    <td>&nbsp;&nbsp;&nbsp;Fiscalía/Agencia &nbsp;</td>
                                    <td><Input type="checkbox" checked={chFiscalia} onChange={(e) => cambiarTraslado('F', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hospital &nbsp;</td>
                                    <td><Input type="checkbox" checked={chHospital} onChange={(e) => cambiarTraslado('A', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Otra dependencia &nbsp;</td>
                                    <td><Input type="checkbox" checked={chOtraDep} onChange={(e) => cambiarTraslado('A', e.target.checked)} /></td>
                                    <td>&nbsp;&nbsp;<Input size="sm" placeholder="otro" value={dependencia} onChange={(e)=>setDependencia(e.target.value)} /></td>
                                </tr>
                            </table>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado E.5 Constancia de lectura de derechos, SÓLO en caso de víctima u ofendido</CardHeader>
                        <CardBody>
                            <span>Indique a la víctima u ofendido que tiene derecho a:</span>
                            <ul>
                                <li>Recibir asesoría jurídica; ser informado de los derechos que en su favor establece la Constitución y, cuando lo solicite, ser informado del desarrollo del procedimiento penal.</li>
                                <li>Recibir desde la comisión del delito, atención médica y psicológica de urgencia.</li>
                                <li>Comunicarse inmediatamente después de haberse cometido el delito con un familiar, incluso con su asesor jurídico.</li>
                                <li>Ser tratado con respeto y dignidad.</li>
                                <li>Contar con un asesor jurídico gratuito en cualquier etapa del procedimiento, en los términos de la legislación aplicable.</li>
                                <li>Acceder a la justicia de manera pronta, gratuita e imparcial respecto de sus denuncias o querellas.</li>
                                <li>Recibir gratuitamente la asistencia de un intérprete o traductor.</li>
                                <li>Que se le proporcione asistencia migratoria cuando tenga otra nacionalidad.</li>
                                <li>Que se resguarde su identidad y datos personales, en los términos que establece la ley.</li>
                            </ul>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado E.6 Datos del primer respondiente que realizó la entrevista, sólo si es diferente a quien firmó la puesta a disposición</CardHeader>
                        <CardBody>
                            <Row>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatRealizoA} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => buscarAut(13)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatRealizoA} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreRealizoA} />
                                </div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col'>
                                    <Input type="select" size="sm" placeholder='adscripcion' value={adsRealizoA} onChange={(e) => setAdsRealizoA(e.target.value)}>
                                        <option value={0}>Indique la institucion</option>
                                        {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='cargo/grado' value={cargoRealizoA} onChange={(e) => cargoRealizoA(e.target.value)} />
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Row>
                        <div className='col-4'></div>
                        <div className='col-2'><Button size="sm" color="success" outline onClick={guardarEntrevista}>Aceptar</Button></div>
                        <div className='col-2'><Button size="sm" color="danger" outline onClick={() => setVerModalEntrevista(false)}>Cancelar</Button></div>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={verModalAutoridad} toggle={() => setVerModalAutoridad(false)}>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    {!selPolicia  && <Autoridad sel={true} autSeleccionada={autSeleccionada}/> }
                    {selPolicia && <div>
                        <Table size="sm" hover striped>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th className='celdaOpcion'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {policias.map((o, i) => <tr key={i}>
                                    <td>{o.N}</td>
                                    <td>
                                        <img src="/imagenes/ok.png" title="seleccionar" onClick={() => cargarPoliciaSel(o.I)}  />
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </div>
                    }
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={verModalPersona} toggle={() => setVerModalPersona(false)}>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <Persona sel={true} perSeleccionada={perSeleccionada}/>
                </ModalBody>
            </Modal>
            {cargando ? (
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
            ) : null}
        </div>
   )
}