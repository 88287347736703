import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Spinner, Row, Input, Card, CardHeader, CardBody, Button, Table, Accordion, AccordionBody, AccordionHeader, AccordionItem, Modal, ModalBody, InputGroup, ModalHeader} from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Autoridad } from './Autoridad';
import { Persona } from './Persona';

export const IphJusticia = () =>{
    const edoGlobal = useContext(AppContext);
    let [cargando,setCargando] = useState(false);
    let [usuario, setUsuario] = useState(1);
    let [corporacion, setCorporacion] = useState(1);
    let [fechaIni, setFechaIni] = useState(new Date().yyyymmdd('-'));
    let [fechaFin, setFechaFin] = useState(new Date().yyyymmdd('-'));
    let [reportes, setReportes] = useState([]);
    let [capturando, setCapturando] = useState(false);
    const [open, setOpen] = useState('1');
    let [idRtp, setIdRpt] = useState(0);
    let [idVeh, setIdVeh] = useState(0);
    let [idDet, setIdDet] = useState(0);
    let [idPer, setIdPer] = useState(0);
    let [referencia,setReferencia] = useState("");
    let [folioSistema,setFolioSistema] = useState("");
    let [horas,setHoras] = useState([]);
    let [minutos,setMinutos] = useState([]);
    let [instituciones,setIntituciones] = useState([]); //{I:1,N:'Guardia Nacional'},{I:2,N:'Policía Federal Ministerial'},{I:3,N:'Policía Ministeria'},{I:4,N:'Policía Mando Único'},{I:5,N:'Policía Estatal'},{I:6,N:'Policía Municipa'},{I:7,N:'Otra autoridad'}]);
    let [tiposAuto,setTiposAuto] = useState([]); //{I:1,N:'Terreste'},{I:2,N:'Acuatico'},{I:3,N:'Aereo'}]);
    let [usosAuto,setUsosAuto] = useState([]); //{I:1,N:'Particular'},{I:2,N:'Transporte publico'},{I:3,N:'Carga'}]);
    let [procedencias,setProcedencias] = useState([]); //{I:1,N:'Nacional'},{I:2,N:'Extranjero'}]);
    let [marcas,setMarcas] = useState([]); //{I:1,N:'Totoya'},{I:2,N:'Honda'}]);
    let [expediente, setExpediente] = useState("");
    let [fecha0101, setFecha0101] = useState(new Date().yyyymmdd('-'));
    let [hr0101, setHr0101] = useState(0);
    let [min0101, setMin0101] = useState(0);
    let [policias, setPolicias] = useState([]);

    let [chA, setChA] = useState(true);
    let [chB, setChB] = useState(true);
    let [chSinA, setChSinA] = useState(false);
    let [cantA, setCantA] = useState(0);
    let [cantB, setCantB] = useState(0);
    
    let [autoridadEnt, setAutoridadEnt] = useState(0);
    let [apePat0101, setApePat0101] = useState("");
    let [apeMat0101, setApeMat0101] = useState("");
    let [nombre0101, setNombre0101] = useState("");
    let [cargo0101, setCargo0101] = useState("");
    let [unidad0101, setUnidad0101] = useState("");
    let [inst0101, setInst0101] = useState(0);
    let [autoridadRec, setAutoridadRec] = useState(0);
    let [apePatFis0101, setApePatFis0101] = useState("");
    let [apeMatFis0101, setApeMatFis0101] = useState("");
    let [nombreFis0101, setNombreFis0101] = useState("");
    let [fiscalia0101, setFiscalia0101] = useState("");
    let [adsFis0101, setAdsFis0101] = useState(0);
    let [cargoFis0101, setCargoFis0101] = useState("");

    let [chFlagrancia, setChFlagrancia] = useState(false);
    let [chQueja, setChQueja] = useState(false);
    let [ch911, setCh911] = useState(false);
    let [chOtro, setChOtro] = useState(false);
    let [otro, setOtro] = useState("");
    let [num911, setNum911] = useState("");

    let [calle, setCalle] = useState("");
    let [noInt, setNoInt] = useState("");
    let [noExt, setNoExt] = useState("");
    let [cp, setCP] = useState("");
    let [referencias,setReferencias] = useState("");
    let [lat, setLat] = useState("");
    let [lon, setLon] = useState("");

    let [narrativa, setNarrativa] = useState("");

    let [detenidos, setDetenidos] = useState([]);
    let [verModalDet, setVerModalDet] = useState(false);
    let [autoridadDet, setAutoridadDet] = useState(0);
    let [fechaDet, setFechaDet] = useState(new Date().yyyymmdd('-'));
    let [hrDet, setHrDet] = useState(0);
    let [minDet, setMinDet] = useState(0);
    let [apePatDet, setApePatDet] = useState("");
    let [apeMatDet, setApeMatDet] = useState("");
    let [nombreDet, setNombreDet] = useState("");
    let [aliasDet, setAliasDet] = useState("");
    let [fechaNac, setFechaNac] = useState(new Date().yyyymmdd('-'));
    let [nacionalidad, setNacionalidad] = useState("M");
    let [cual, setCual] = useState("");
    let [edad, setEdad] = useState(0);
    let [sexo, setSexo] = useState("?");
    let [calleDet, setCalleDet] = useState("");
    let [noIntDet, setNoIntDet] = useState("");
    let [noExtDet, setNoExtDet] = useState("");
    let [cpDet, setCPDet] = useState("");
    let [referenciasDet,setReferenciasDet] = useState("");
    let [descripDet,setDescripDet] = useState("");
    let [lesiones, setLesiones] = useState(false);
    let [vulnerable, setVulnerable] = useState(false);
    let [padecimientos, setPadecimientos] = useState(false);
    let [padecimiento, setPadecimiento] = useState("");
    let [vulnerableCual, setVulnerableCual] = useState("");
    let [autoridad, setAutoridad] = useState("");
    let [apePatPrimerA, setApePatPrimerA] = useState("");
    let [apeMatPrimerA, setApeMatPrimerA] = useState("");
    let [nombrePrimerA, setNombrePrimerA] = useState("");
    let [cargoPrimerA, setCargoPrimerA] = useState("");
    let [adsPrimerA, setAdsPrimerA] = useState(0);
    let [apePatPrimerR, setApePatPrimerR] = useState("");
    let [apeMatPrimerR, setApeMatPrimerR] = useState("");
    let [nombrePrimerR, setNombrePrimerR] = useState("");
    let [cargoPrimerR, setCargoPrimerR] = useState("");
    let [adsPrimerR, setAdsPrimerR] = useState(0);

    let [autos, setAutos] = useState([]);
    let [verModalAuto, setVerModalAuto] = useState(false);
    let [autoridadAuto, setAutoridadAuto] = useState(0);
    let [fechaAuto, setFechaAuto] = useState(new Date().yyyymmdd('-'));
    let [hrAuto, setHrAuto] = useState(0);
    let [minAuto, setMinAuto] = useState(0);
    let [tipoAuto, setTipoAuto] = useState(0);
    let [procAuto, setProcAuto] = useState(0);
    let [marcaAuto, setMarcaAuto] = useState(0);
    let [subMarcaAuto, setSubMarcaAuto] = useState("");
    let [modeloAuto, setModeloAuto] = useState("");
    let [colorAuto, setColorAuto] = useState("");
    let [usoAuto, setUsoAuto] = useState(0);
    let [placaAuto, setPlacaAuto] = useState("");
    let [serieAuto, setSerieAuto] = useState("");
    let [obsAuto, setObsAuto] = useState("");
    let [destinoAuto, setDestinoAuto] = useState("");

    let [verModalAutoridad, setVerModalAutoridad] = useState(false);
    let [campoAutoridad,setCampoAutoridad] = useState(0);
    let [verModalPersona, setVerModalPersona] = useState(false);
    let [campoPersona,setCampoPersona] = useState(0);
    let [selPolicia, setSelPolicia] = useState(false);

    useEffect(() => {
        let i = 1;
        for(i = 0; i < 59; i++){
            if(i < 24)
               horas.push(i);
            minutos.push(i);
        }
        cargarCatalogos();
    }, []);

    async function cargarCatalogos(){
        let url = `${edoGlobal.urlws}/iphCivica/procedenciasVehiculo`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        setProcedencias(datos);
        url = `${edoGlobal.urlws}/iphCivica/tiposVehiculo`;
        resp = await fetch(url);
        datos = await resp.json();
        setTiposAuto(datos);
        url = `${edoGlobal.urlws}/iphCivica/marcasVehiculo`;
        resp = await fetch(url);
        datos = await resp.json();
        setMarcas(datos);
        url = `${edoGlobal.urlws}/iphCivica/usosVehiculo`;
        resp = await fetch(url);
        datos = await resp.json();
        setUsosAuto(datos);
        url = `${edoGlobal.urlws}/iphCivica/instituciones`;
        resp = await fetch(url);
        datos = await resp.json();
        setIntituciones(datos);
        setCargando(false);
    }

    async function cargarReportes(){
        let url = `${edoGlobal.urlws}/iphCivica/lista/${corporacion}?fecIni=${fechaIni}&fecFin=${fechaFin}`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        setReportes(datos);
        setCargando(false);
    }
    function buscarAut(a){
        setCampoAutoridad(a);
        setSelPolicia(false);
        setVerModalAutoridad(true)
    }
    function seleccionarPol(a){
        setCampoAutoridad(a);
        setSelPolicia(true);
        setVerModalAutoridad(true)
    }
    async function cargarPoliciaSel(idPol){
        let url = `${edoGlobal.urlws}/autoridad/cargar/${idPol}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setCargando(false);
        autSeleccionada(d);
    }
    function autSeleccionada(d){
        if(campoAutoridad == 1){
            setAutoridadEnt(d.Id);
            setApePat0101(d.ApePat);
            setApeMat0101(d.ApeMat);
            setNombre0101(d.Nombre);
            setInst0101(d.Adscripcion);
            setCargo0101(d.Cargo);    
        }
        if(campoAutoridad == 2){
            setAutoridadRec(d.Id);
            setApePatFis0101(d.ApePat);
            setApeMatFis0101(d.ApeMat);
            setNombreFis0101(d.Nombre);
            setAdsFis0101(d.Adscripcion);
            setCargoFis0101(d.Cargo);    
        }
        if(campoAutoridad == 3){
            setAutoridadDet(d.Id);
            setApePatPrimerA(d.ApePat);
            setApeMatPrimerA(d.ApeMat);
            setNombrePrimerA(d.Nombre);
            setAdsPrimerA(d.Adscripcion);
            setCargoPrimerA(d.Cargo);    
        }
        if(campoAutoridad == 4){
            setAutoridadAuto(d.Id);
            setApePatPrimerR(d.ApePat);
            setApeMatPrimerR(d.ApeMat);
            setNombrePrimerR(d.Nombre);
            setAdsPrimerR(d.Adscripcion);
            setCargoPrimerR(d.Cargo);    
        }
        if(campoAutoridad == 5){
            guardarPolicia(d.Id);
        }
        setVerModalAutoridad(false)
    }
    function buscarPer(a){
        setCampoPersona(a);
        setVerModalPersona(true)
    }
    function perSeleccionada(d){
        if(campoPersona == 1){
            setIdPer(d.Id);
            setApePatDet(d.ApePat);
            setApeMatDet(d.ApeMat);
            setNombreDet(d.Nombre);
            setAliasDet(d.Alias);
            setFechaNac(d.FechaNac);
            setEdad(d.Edad);
            setSexo(d.Sexo);
            setNacionalidad(d.Nacionalidad == "MEXICANA"?"M":"E");
            setCual(d.Nacionalidad == "MEXICANA"?"": d.Nacionalidad);
            setCalleDet(d.Domicilio);
            setNoExtDet(d.NoExt);
            setNoIntDet(d.NoInt);
            setCPDet(d.CP);
            setReferenciasDet(d.Referencias);
            setDescripDet(d.Descripcion);
        }
        setVerModalPersona(false)
    }
    async function agregarMarca(){
        const { value: nombre } = await Swal.fire({
             title: 'Agregar nueva marca',
            input: 'text',
            inputLabel: 'nombre de la marca'
          })          
          if (!nombre)
             return;
          let nomMarca = nombre.toUpperCase();
          for(let i = 0; i < marcas.length; i++)
             if(marcas[i].N == nomMarca)
                return;
        let url = `${edoGlobal.urlws}/iphCivica/agregarMarca?nombre=${nomMarca}`;
        setCargando(true);
        let resp = await fetch(url);
        let res = await resp.text();
        if(res == "ok"){
            url = `${edoGlobal.urlws}/iphCivica/marcasVehiculo`;
            resp = await fetch(url);
            let datos = await resp.json();
            setMarcas(datos);    
        }
        else
           Swal.fire('Atencion',res,'error');
    }
    function nuevo(){
        editar(0);
    }
    async function editar(i){
        let url = `${edoGlobal.urlws}/iphCivica/cargar/${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        url = `${edoGlobal.urlws}/iphCivica/cargarDetenidos/${i}`;
        resp = await fetch(url);
        let t = await resp.json();
        url = `${edoGlobal.urlws}/iphCivica/cargarVehiculos/${i}`;
        resp = await fetch(url);
        let a = await resp.json();
        url = `${edoGlobal.urlws}/iphCivica/cargarPolicias/${i}`;
        resp = await fetch(url);
        let p = await resp.json();
        setIdRpt(i);
        setAutoridadEnt(d.IdRealiza);
        setAutoridadRec(d.IdRecibe);
        setReferencia(d.Referencia);
        setFolioSistema(d.FolioSistema);
        setExpediente(d.Expediente);
        setFecha0101(d.Fecha);
        setHr0101(Number(d.Hora.substring(0,2)));
        setMin0101(Number(d.Hora.substring(3,5)));
        setCantA(d.Detenidos);
        setCantB(d.Vehiculos);
        setApePat0101(d.ApePatRealiza);
        setApeMat0101(d.ApeMatRealiza);
        setNombre0101(d.NombreRealiza);
        setInst0101(d.InstitucionRealiza);
        setApePatFis0101(d.ApePatRecibe);
        setApeMatFis0101(d.ApeMatRecibe);
        setNombreFis0101(d.NombreRecibe);
        setCargoFis0101(d.AutoridadRecibe);
        setAdsFis0101(d.AdscripcionRecibe);
        setCargoFis0101(d.CargoRecibe);
        setCargo0101(d.CargoRealiza);
        setUnidad0101(d.UnidadRealiza); 
        setChFlagrancia(d.FormaEnterado === "P");
        setChQueja(d.FormaEnterado === "Q");
        setCh911(d.FormaEnterado === "L");
        setChOtro(d.FormaEnterado === "O");
        setNum911(d.Telefono911);
        setOtro(d.EnteradoOtro);
        setCalle(d.CalleInter);
        setNoExt(d.NumExtInter);
        setNoInt(d.NumIntInter);
        setCP(d.CpInter);
        setLat(d.LatInter);
        setLon(d.LonInter);
        setReferencias(d.ReferenciasInter);
        setNarrativa(d.Narrativa);
        setDetenidos(t);
        setAutos(a);
        setPolicias(p);
        setCargando(false);
        setCapturando(true);
    }
    function cambiarAnexo(c,v){
        if(c === 'S'){
            if(v){
                setChA(false);
                setChB(false);
            }
            setChSinA(v);
        }else if(v)
           setChSinA(false);
        if(c === 'A')
            setChA(v);
        if(c === 'B')
            setChB(v);
    }    
    const toggle = (id) => {
        if (open === id)
           setOpen();
        else
           setOpen(id);
    };
    function cancelar(){
        setCapturando(false);
    }
    async function quitarPolicia(idPol,nomPol){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea quitar a " + nomPol + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async(result) => {
            if (result.value){
                let url = `${edoGlobal.urlws}/iphCivica/borrarPolicia/${idRtp}?policia=${idPol}`;
                setCargando(true);
                let resp = await fetch(url);
                const res = await resp.text();
                setCargando(false);
                if(res.substring(0,2) == "ok"){
                    url = `${edoGlobal.urlws}/iphCivica/cargarPolicias/${idRtp}`;
                    resp = await fetch(url);
                    let p = await resp.json();
                    setPolicias(p);
                }else
                   Swal.fire('Atencion',res,'error');        
            }
        });
    }
    async function guardarPolicia(idPol){
        let url = `${edoGlobal.urlws}/iphCivica/guardarPolicia/${idRtp}?policia=${idPol}&orden=0`;
        setCargando(true);
        let resp = await fetch(url);
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            url = `${edoGlobal.urlws}/iphCivica/cargarPolicias/${idRtp}`;
            resp = await fetch(url);
            let p = await resp.json();
            setPolicias(p);
        }else
           Swal.fire('Atencion',res,'error');
    }
    function guardar(){
        if(autoridadEnt == 0){
            Swal.fire('Atencion','Debe especificar a quien realiza la puesta a disposición','error');
            return;
        }
        if(autoridadRec == 0){
            Swal.fire('Atencion','Debe especificar a quien recibe la puesta a disposición','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                guardarB();
        });
    }
    async function guardarB(){
        let detD = [];
        let detV = [];
        /*
        for(let i = 0; i < detalle.length; i++) {
            det.push({
                cod: detalle[i].Codigo,
                can: detalle[i].Cantidad,
                pre: detalle[i].Precio
            });
        }
        */
        let jsonD = JSON.stringify(detD);
        let jsonV = JSON.stringify(detV);

        let enterado = 'P';
        if(chQueja)
           enterado = 'Q';
        else if(ch911)
           enterado = 'L';
        else if(chOtro)
           enterado = 'O';
        let hr = Number(hr0101) > 9 ? hr0101 : '0' + hr0101;
        let min = Number(min0101) > 9 ? min0101 : '0' + min0101; 
        let dato = {
            Id: idRtp, Referencia : referencia, FolioSistema : folioSistema,
            Fecha : fecha0101, Hora: hr + ":" + min,
            Expediente : expediente,
            Detenciones : detenidos.length, Vehiculos : autos.length,
            ApePatRealiza : apePat0101, ApeMatRealiza : apeMat0101,
            NombreRealiza : nombre0101, InstitucionRealiza : inst0101,
            CargoRealiza : cargo0101, UnidadRealiza : unidad0101,
            ApePatRecibe : apePatFis0101, ApeMatRecibe: apeMatFis0101,
            NombreRecibe : nombreFis0101, AutoridadRecibe : fiscalia0101,
            AdscripcionRecibe : adsFis0101, CargoRecibe : cargoFis0101,
            FormaEnterado : enterado, Telefono911 : num911, EnteradoOtro: otro,
            CalleInter : calle, NumExtInter: noExt, NumIntInter: noInt,
            CpInter:cp, LatInter:lat, LonInter :lon, ReferenciasInter : referencias,
            Narrativa : narrativa, Usuario : usuario, Corporacion : corporacion,
            IdRealiza:autoridadEnt,IdRecibe:autoridadRec,
            sd: jsonD,
            sv: jsonV
        }
        let url = `${edoGlobal.urlws}/iphCivica/guardar`;
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            if(idRtp == 0){
               setIdRpt(res);
               setFolioSistema(res);
            }
        }else
           Swal.fire('Atencion',res,'error');
    }
    function nuevoVehiculo(){
        editarVehiculo(0);
    }
    async function editarVehiculo(i){
        setIdVeh(i);
        let url = `${edoGlobal.urlws}/iphCivica/cargarVehiculo/${idRtp}?vehiculo=${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setCargando(false);
        setAutoridadAuto(d.IdRealiza);
        setFechaAuto(d.Fecha);
        setHrAuto(Number(d.Hora.substring(0,2)));
        setMinAuto(Number(d.Hora.substring(3,5)));
        setTipoAuto(d.Tipo);
        setProcAuto(d.Procedencia);
        setUsoAuto(d.Uso);
        setMarcaAuto(d.Marca);
        setSubMarcaAuto(d.SubMarca);
        setModeloAuto(d.Modelo);
        setColorAuto(d.Color);
        setPlacaAuto(d.Placas);
        setSerieAuto(d.Serie);
        setObsAuto(d.Observaciones);
        setDestinoAuto(d.Destino);
        setCargoPrimerR(d.CargoRealiza);
        setAdsPrimerR(d.AdscripcionRealiza);
        setApePatPrimerR(d.ApePatRealiza);
        setApeMatPrimerR(d.ApeMatRealiza);
        setNombrePrimerR(d.NombreRealiza);
        setVerModalAuto(true);        
    }
    async function guardarVehiculo(){
        if(autoridadAuto == 0){
            Swal.fire('Atencion','Debe especificar a quien realiza el resguardo','error');
            return;
        }
        let hr = Number(hrAuto) > 9 ? hrAuto : '0' + hrAuto;
        let min = Number(minAuto) > 9 ? minAuto : '0' + minAuto; 
        let dato = {
            Id: idRtp, Vehiculo : idVeh, Usuario : usuario, CargoRealiza:cargoPrimerR,
            IdRealiza: autoridadAuto, Fecha : fecha0101, Hora: hr + ":" + min,
            Tipo : tipoAuto, Procedencia : procAuto, AdscripcionRealiza:adsPrimerR,
            Uso : usoAuto, Marca : marcaAuto, SubMarca : subMarcaAuto,
            Modelo : modeloAuto, Color : colorAuto, Placas : placaAuto, 
            Serie : serieAuto, Observaciones : obsAuto, Destino : destinoAuto
        }
        let url = `${edoGlobal.urlws}/iphCivica/guardarVehiculo`;
        setCargando(true);
        let json = JSON.stringify(dato);
        let resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            url = `${edoGlobal.urlws}/iphCivica/cargarVehiculos/${idRtp}`;
            setCargando(true);
            resp = await fetch(url);
            let a = await resp.json();
            setCargando(false);
            setAutos(a);    
            setVerModalAuto(false);
        }else
           Swal.fire('Atencion',res,'error');
    }

    function nuevoDetenido(){
        editarDetenido(0);
    }
    async function editarDetenido(i){
        setIdDet(i);
        let url = `${edoGlobal.urlws}/iphCivica/cargarDetenido/${idRtp}?detenido=${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setCargando(false);
        setAutoridadDet(d.IdRealiza);
        setIdPer(d.IdPersona);
        setFechaDet(d.Fecha);
        setHrDet(Number(d.Hora.substring(0,2)));
        setMinDet(Number(d.Hora.substring(3,5)));
        setApePatDet(d.ApePat);
        setApeMatDet(d.ApeMat);
        setNombreDet(d.Nombre);
        setAliasDet(d.Alias);
        setFechaNac(d.FechaNac);
        setEdad(d.Edad);
        setSexo(d.Sexo);
        setNacionalidad(d.Nacionalidad);
        setCual(d.Nacionalidad2);
        setCalleDet(d.Calle);
        setNoExtDet(d.NumExt);
        setNoIntDet(d.NumInt);
        setCPDet(d.CP);
        setReferenciasDet(d.Referencias);
        setDescripDet(d.Descripcion);
        setLesiones(d.Lesiones);
        setPadecimientos(d.Padecimientos);
        setVulnerable(d.Vulnerable);
        setPadecimiento(d.Padecimiento);
        setVulnerableCual(d.GrupoVulnerable);
        setAutoridad(d.Traslado);
        setCargoPrimerA(d.CargoRealiza);
        setAdsPrimerA(d.AdscripcionRealiza);
        setApePatPrimerA(d.ApePatRealiza);
        setApeMatPrimerA(d.ApeMatRealiza);
        setNombrePrimerA(d.NombreRealiza);
        setVerModalDet(true);        
    }
    async function guardarDetenido(){
        if(idPer == 0){
            Swal.fire('Atencion','Debe especificar a la persona detenida','error');
            return;
        }
        if(autoridadDet == 0){
            Swal.fire('Atencion','Debe especificar a quien realiza la detencion','error');
            return;
        }
        let hr = Number(hrDet) > 9 ? hrDet : '0' + hrDet;
        let min = Number(minDet) > 9 ? minDet : '0' + minDet; 
        let dato = {
            Id: idRtp, Detenido : idDet, Usuario : usuario, IdPersona:idPer,
            Fecha : fechaDet, Hora: hr + ":" + min, Sexo : sexo,
            ApePat : apePatDet, ApeMat: apeMatDet, Nombre : nombreDet, Alias:aliasDet,
            FechaNac : fechaNac, Edad: edad, Nacionalidad : nacionalidad, Nacionalidad2 : cual,
            Calle : calleDet, NumExt : noExtDet, NumInt : noIntDet, CP : cpDet,
            Referencias : referenciasDet, Descripcion : descripDet,
            Padecimientos : padecimientos, Lesiones : lesiones, Vulnerable : vulnerable,
            Padecimiento : padecimiento, GrupoVulnerable : vulnerableCual, Traslado: autoridad,
            IdRealiza:autoridadDet, CargoRealiza:cargoPrimerA, AdscripcionRealiza: adsPrimerA
        }
        let url = `${edoGlobal.urlws}/iphCivica/guardarDetenido`;
        setCargando(true);
        let json = JSON.stringify(dato);
        let resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            url = `${edoGlobal.urlws}/iphCivica/cargarDetenidos/${idRtp}`;
            setCargando(true);
            resp = await fetch(url);
            let a = await resp.json();
            setCargando(false);
            setDetenidos(a);    
            setVerModalDet(false);
        }else
           Swal.fire('Atencion',res,'error');
    }
    const estiloListado = { display: capturando ? 'none' : 'inline' };
    const estiloCaptura = { display: !capturando ? 'none' : 'inline' };
    return (
        <div>
            <div style={estiloListado}>
                    <table>
                        <tr>
                            <td>Reportes del &nbsp;</td>
                            <td><Input size="sm" type="date" value={fechaIni} onChange={(e) => setFechaIni(e.target.value)} /></td>
                            <td>&nbsp;al&nbsp;</td>
                            <td><Input size="sm" type="date" value={fechaFin} onChange={(e) => setFechaFin(e.target.value)} /></td>
                            <td>&nbsp;<Button size="sm" color="success" outline onClick={cargarReportes}><img src="/imagenes/ok.png" title="cargar reportes" /></Button></td>
                            <td>&nbsp;<Button size="sm" color="success" outline onClick={nuevo}><img src="/imagenes/add.png" title="nuevo reporte" /></Button></td>
                        </tr>
                    </table>
                <Table size="sm" hover striped>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Referencia</th>
                            <th>Folio Sistema</th>
                            <th>Expediente</th>
                            <td className='celdaOpcion'></td>
                        </tr>
                    </thead>
                    <tbody>
                        {reportes.map((o,i)=><tr key={i}>
                            <td>{o.Fecha}</td>
                            <td>{o.Referencia}</td>
                            <td>{o.FolioSistema}</td>
                            <td>{o.Expediente}</td>
                            <td><img onClick={()=> editar(o.Id)} src="/imagenes/edit.png" title="editar" /></td>
                        </tr>)}
                    </tbody>
                </Table>
            </div>
            <div style={estiloCaptura}>
                <Row>
                    <div className='col-3'>
                        Numero de referencia<Input size="sm" type="text" value={referencia} onChange={(e) => setReferencia(e.target.value)} />
                    </div>
                    <div className='col-3'>
                        Folio del sistema<Input size="sm" type="text" value={folioSistema} />
                    </div>
                    <div className='col-2'></div>
                    <div className='col-2'>
                        <br />
                        <Button size="sm" color="success" outline onClick={guardar}><img src="/imagenes/disk.png" />  Guardar</Button>
                    </div>
                    <div className='col-2'>
                        <br />
                        <Button size="sm" color="danger" outline onClick={cancelar}> Regresar </Button>
                    </div>
                </Row>
                <br />
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                        <AccordionHeader targetId="1">Seccion 1 - Puesta a disposicion</AccordionHeader>
                        <AccordionBody accordionId="1">
                            <Card>
                                <CardHeader>Apartado 1.1 Fecha y hora de la puesta a disposición</CardHeader>
                                <CardBody>
                                    <Row>
                                        <div className="col-2">
                                            Fecha<Input size="sm" type="date" value={fecha0101} onChange={(e) => setFecha0101(e.target.value)} />
                                        </div>
                                        <div className="col-1">
                                            Hora
                                            <Input size="sm" type='select' value={hr0101} onChange={(e) => setHr0101(e.target.value)}>
                                                {horas.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                            </Input>
                                        </div>
                                        <div className="col-1">
                                            Minuto
                                            <Input size="sm" type='select' value={min0101} onChange={(e) => setMin0101(e.target.value)}>
                                                {minutos.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                            </Input>
                                        </div>
                                        <div className="col-4">
                                            Expediente<Input size="sm" value={expediente} onChange={(e) => setExpediente(e.target.value)} />
                                        </div>
                                    </Row>
                                    <hr />
                                    <span>Señale el o los Anexos entregados e indique la cantidad de cada uno de ellos.</span>
                                    <Row>
                                        <div className='col'>
                                            <table>
                                                <tr>
                                                    <td><b>Anexo A</b> Detencion(es)</td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td><Input type="checkbox" checked={chA} onChange={(e) => cambiarAnexo('A', e.target.checked)} /></td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td className='numeroCeldaCh'><Input size="sm" placeholder='cant' readOnly={!chA} value={cantA} onChange={(e) => setCantA(e.target.value)} /></td>
                                                    <td><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></td>
                                                    <td><b>Anexo B</b> Descripción de vehiculos</td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td><Input type="checkbox" checked={chB} onChange={(e) => cambiarAnexo('B', e.target.checked)} /></td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td className='numeroCeldaCh'><Input size="sm" placeholder='cant' readOnly={!chB} value={cantB} onChange={(e) => setCantB(e.target.value)} /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td></td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td className='numeroCeldaCh'></td>
                                                    <td><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></td>
                                                    <td>No se entregaron anexos</td>
                                                    <td><span>&nbsp;</span></td>
                                                    <td><Input type="checkbox" checked={chSinA} onChange={(e) => cambiarAnexo('S', e.target.checked)} /></td>
                                                </tr>
                                            </table>
                                        </div>
                                        <hr />
                                    <Card>
                                        <CardHeader>Policias &nbsp;&nbsp;<Button size="sm" outline color="secondary" onClick={() => buscarAut(5)}><img src="/imagenes/add.png" title="agregar detenido" /></Button></CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Table size="sm" hover striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Nombre</th>
                                                            <th className='celdaOpcion'></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {policias.map((o, i) => <tr key={i}>
                                                            <td>{o.N}</td>
                                                            <td>
                                                                <img src="/imagenes/menos.png" title="quitar" onClick={() => quitarPolicia(o.I, o.N)} />
                                                            </td>
                                                        </tr>)}
                                                    </tbody>
                                                </Table>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    </Row>
                                </CardBody>
                            </Card>
                            <br />
                            <Card>
                                <CardHeader>Apartado 1.2 Datos de quien realiza la puesta a disposición/Primer respondiente</CardHeader>
                                <CardBody>
                                    <Row>
                                        <div className='col'>
                                            <InputGroup>
                                                <Input size="sm" placeholder='primer apellido' value={apePat0101} />
                                                    &nbsp;
                                                    <Button outline size="sm" color="secondary" onClick={()=>seleccionarPol(1)}>
                                                        <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                    </Button>
                                            </InputGroup>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='segundo apellido' value={apeMat0101} />
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='nombre(s)' value={nombre0101} />
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'>
                                            <Input size="sm" type="select" value={inst0101} onChange={(e) => setInst0101(e.target.value)}>
                                                <option value={0}>Indique la institucion</option>
                                                {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                            </Input>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='cargo/grado' value={cargo0101} onChange={(e) => setCargo0101(e.target.value)} />
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='unidad en que arribo' value={unidad0101} onChange={(e) => setUnidad0101(e.target.value)} />
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                            <br />
                            <Card>
                                <CardHeader>Apartado 1.3 Datos de quien recibe la puesta a disposición</CardHeader>
                                <CardBody>
                                    <Row>
                                        <div className='col'>
                                            <InputGroup>
                                                <Input size="sm" placeholder='primer apellido' value={apePatFis0101} />
                                                &nbsp;
                                                <Button outline size="sm" color="secondary" onClick={() => buscarAut(2)}>
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='segundo apellido' value={apeMatFis0101}/>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='nombre(s)' value={nombreFis0101}  />
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'>
                                            <Input size="sm" placeholder='fiscal/autoridad' value={fiscalia0101} onChange={(e) => setFiscalia0101(e.target.value)} />
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" type="select" value={adsFis0101} onChange={(e) => setAdsFis0101(e.target.value)}>
                                                <option value={0}>Indique la institucion</option>
                                                {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                            </Input>
                                        </div>
                                        <div className='col'>
                                            <Input size="sm" placeholder='cargo/grado' value={cargoFis0101} onChange={(e) => setCargoFis0101(e.target.value)} />
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="2">Seccion 2. Datos de la probable infraccion administrativa</AccordionHeader>
                        <AccordionBody accordionId="2">
                            <Card>
                                <CardHeader>Apartado 2.1 Conocimiento de la probable infracción administrativa por el primer respondiente</CardHeader>
                                <CardBody>
                                    <Row>
                                        <span>¿ Como se entero del hecho ?</span>
                                        <table>
                                            <tr>
                                                <td><Input type="checkbox" checked={chFlagrancia} onChange={(e) => setChFlagrancia(e.target.checked)} /></td>
                                                <td><span>Presencia directa en la comisión de la probable infracción</span></td>
                                                <td><Input type="checkbox" checked={chQueja} onChange={(e) => setChQueja(e.target.checked)} /></td>
                                                <td><span>Queja (petición de parte):</span></td>
                                            </tr>
                                            <tr>
                                                <td><Input type="checkbox" checked={ch911} onChange={(e) => setCh911(e.target.checked)} /></td>
                                                <td><span>Llamada de emergencia (ingrese el numero si cuenta con el)</span></td>
                                                <td><Input type="checkbox" checked={chOtro} onChange={(e) => setChOtro(e.target.checked)} /></td>
                                                <td><span>Otro (espeficique)</span></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <Input placeholder="911 numero" size="sm" value={num911} onChange={(e) => setNum911(e.target.value)} />
                                                </td>
                                                <td></td>
                                                <td>
                                                    <Input placeholder="espeficique" size="sm" value={otro} onChange={(e) => setOtro(e.target.value)} />
                                                </td>
                                            </tr>
                                        </table>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="3">Seccion 3. Lugar de la intervencion</AccordionHeader>
                        <AccordionBody accordionId="3">
                            <Card>
                                <CardHeader>Apartado 3.1 Ubicación geográfica</CardHeader>
                                <CardBody>
                                    <Row>
                                        <div className='col'><Input size="sm" placeholder="calle/tramo carretero" value={calle} onChange={(e) => setCalle(e.target.value)} /></div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'><Input size="sm" placeholder="No.Exterior" value={noExt} onChange={(e) => setNoExt(e.target.value)} /></div>
                                        <div className='col'><Input size="sm" placeholder="No.Interior" value={noInt} onChange={(e) => setNoInt(e.target.value)} /></div>
                                        <div className='col-1'><Input size="sm" placeholder="C.P." value={cp} onChange={(e) => setCP(e.target.value)} /></div>
                                        <div className='col'><Input size="sm" placeholder="Latitud" value={lat} onChange={(e) => setLat(e.target.value)} /></div>
                                        <div className='col'><Input size="sm" placeholder="Longitud" value={lon} onChange={(e) => setLon(e.target.value)} /></div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className='col'><Input size="sm" placeholder="referencias" value={referencias} onChange={(e) => setReferencias(e.target.value)} /></div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="4">Seccion 4. Narrativa de los hechos</AccordionHeader>
                        <AccordionBody accordionId="4">
                            <Card>
                                <CardHeader>Apartado 4.1 Descripción de los hechos y actuación de la autoridad</CardHeader>
                                <CardBody>
                                    <span>
                                        Relate cronológicamente las acciones realizadas durante su intervención desde el conocimiento del hecho, hasta su conclusión o la puesta a disposición. En su
                                        caso, explique las circunstancias de modo, tiempo y lugar que motivaron cada uno de los niveles de contacto y la detención. Tome como base las siguientes
                                        preguntas: ¿Quién? (personas), ¿Qué? (hechos), ¿Cómo? (circunstancias), ¿Cuándo? (tiempo) y ¿Dónde? (lugar).
                                    </span>
                                    <Row>
                                        <Input style={{ height: 400 }} type="textarea" value={narrativa} onChange={(e) => setNarrativa(e.target.value)} />
                                    </Row>
                                </CardBody>
                            </Card>
                            <br />
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem style={{ display: chA ? 'block' : 'none' }}>
                        <AccordionHeader targetId="6">Anexo A - Detenciones</AccordionHeader>
                        <AccordionBody accordionId="6">
                        <Card>
                            <CardHeader>Detenidos &nbsp;&nbsp;<Button size="sm" outline color="secondary" onClick={nuevoDetenido}><img src="/imagenes/add.png" title="agregar detenido" /></Button></CardHeader>
                                <CardBody>
                                    <Row>
                                        <Table size="sm" hover striped>
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Alias</th>
                                                    <th className='numeroCeldaCh'>Edad</th>
                                                    <th className='celdaOpcion'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {detenidos.map((o, i) => <tr key={i}>
                                                    <td>{o.Nombre}</td>
                                                    <td>{o.Alias}</td>
                                                    <td className='numeroCeldaCh'>{o.Edad}</td>
                                                    <td>
                                                        <img src="/imagenes/edit.png" title="editar" onClick={()=>editarDetenido(o.Detenido)} />
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem style={{ display: chB ? 'block' : 'none' }}>
                        <AccordionHeader targetId="7">Anexo B - Descripción del vehiculo</AccordionHeader>
                        <AccordionBody accordionId="7">
                            <Card>
                                <CardHeader>Vehiculos &nbsp;&nbsp;<Button size="sm" outline color="secondary" onClick={nuevoVehiculo}><img src="/imagenes/add.png" title="agregar vehiculo" /></Button></CardHeader>
                                <CardBody>
                                    <Row>
                                        <Table size="sm" hover striped>
                                            <thead>
                                                <tr>
                                                    <th>Placas</th>
                                                    <th>Marca</th>
                                                    <th>Modelo</th>
                                                    <th>Color</th>
                                                    <th className='celdaOpcion'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {autos.map((o, i) => <tr key={i}>
                                                    <td>{o.Placas}</td>
                                                    <td>{o.Marca}</td>
                                                    <td>{o.Modelo}</td>
                                                    <td>{o.Color}</td>
                                                    <td>
                                                        <img src="/imagenes/edit.png" title="editar" onClick={()=>editarVehiculo(o.Vehiculo)} />
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </div>
            <Modal size="lg" isOpen={verModalDet} toggle={() => setVerModalDet(false)}>
                <ModalBody>
                    <Card>
                        <CardHeader>Apartado A.1 Fecha y hora de la detención</CardHeader>
                        <CardBody>
                            <Row>
                                <div className="col-3">
                                    Fecha<Input size="sm" type="date" value={fechaDet} onChange={(e) => setFechaDet(e.target.value)} />
                                </div>
                                <div className="col-2">
                                    Hora
                                    <Input size="sm" type='select' value={hrDet} onChange={(e) => setHrDet(e.target.value)}>
                                        {horas.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                    </Input>
                                </div>
                                <div className="col-2">
                                    Minuto
                                    <Input size="sm" type='select' value={minDet} onChange={(e) => setMinDet(e.target.value)}>
                                        {minutos.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                    </Input>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado A.2 Datos generales de la persona detenida</CardHeader>
                        <CardBody>
                            <Row>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatDet} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => buscarPer(1)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatDet}  />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombreDet} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='alias' value={aliasDet} />
                                </div>
                            </Row>
                            <br />
                            <Row>
                                <div className="col-3">
                                    Fecha Nacimiento <Input size="sm" type="date" value={fechaNac} />
                                </div>
                                <div className="col-1">
                                    Edad <Input size="sm" type="text" value={edad} />
                                </div>
                                <div className="col-2">
                                    Sexo <Input size="sm" type="select" value={sexo} >
                                        <option value="?">Espeficique</option>
                                        <option value="H">Hombre</option>
                                        <option value="M">Mujer</option>
                                    </Input>
                                </div>
                                <div className="col-2">
                                    Nacionalidad <Input size="sm" type="select" value={nacionalidad} >
                                        <option value="M">Mexicana</option>
                                        <option value="E">Extranjera</option>
                                    </Input>
                                </div>
                                <div className="col">
                                    Cual <Input size="sm" type="text" value={cual} />
                                </div>
                            </Row>
                            <span>Domicilio</span>
                            <Row>
                                <div className='col'><Input size="sm" placeholder="calle/tramo carretero" value={calleDet} onChange={(e) => setCalleDet(e.target.value)} /></div>
                                <div className='col-2'><Input size="sm" placeholder="No.Exterior" value={noExtDet} onChange={(e) => setNoExtDet(e.target.value)} /></div>
                                <div className='col-2'><Input size="sm" placeholder="No.Interior" value={noIntDet} onChange={(e) => setNoIntDet(e.target.value)} /></div>
                                <div className='col-2'><Input size="sm" placeholder="C.P." value={cpDet} onChange={(e) => setCPDet(e.target.value)} /></div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col'><Input size="sm" placeholder="referencias" value={referenciasDet} onChange={(e) => setReferenciasDet(e.target.value)} /></div>
                            </Row>
                            <span>Describa brevemente a la persona detenida, incluyendo tipo de vestimenta y rasgos visibles (barba, tatuajes, cicatrices, lunares, bigote, etc.). En caso de no contar
                                con la fecha de nacimiento, señalar la edad aproximada de la persona</span>
                            <Row>
                                <Input style={{ height: 200 }} type="textarea" value={descripDet} onChange={(e) => setDescripDet(e.target.value)} />
                            </Row>
                            <table>
                                <tr>
                                    <td><Input type="checkbox" checked={lesiones} onChange={(e) => setLesiones(e.target.checked)} /></td>
                                    <td><span>Presenta lesiones</span></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><Input type="checkbox" checked={padecimientos} onChange={(e) => setPadecimientos(e.target.checked)} /></td>
                                    <td><span>Manifiesta algun padecimiento</span></td>
                                    <td><Input size="sm" placeholder='especifique padecimiento' value={padecimiento} onChange={(e) => setPadecimiento(e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td><Input type="checkbox" checked={vulnerable} onChange={(e) => setVulnerable(e.target.checked)} /></td>
                                    <td><span>Se identificó como miembro de algún grupo vulnerable</span></td>
                                    <td><Input style={{ width: 400 }} size="sm" placeholder='especique el grupo' value={vulnerableCual} onChange={(e) => setVulnerableCual(e.target.value)} /></td>
                                </tr>
                            </table>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado A.3 Datos del lugar del traslado de la persona detenida</CardHeader>
                        <CardBody>
                            <Row>
                                <span>Señale a qué autoridad administrativa se trasladó a la persona detenida.</span>
                                <Input type="text" value={autoridad} onChange={(e) => setAutoridad(e.target.value)} />
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado A.4 Datos del primer respondiente que realizó la detención, sólo si es diferente a quien firmó la puesta a disposición</CardHeader>
                        <CardBody>
                            <Row>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatPrimerA} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => seleccionarPol(3)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatPrimerA} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombrePrimerA} />
                                </div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col'>
                                    <Input size="sm" placeholder='cargo/grado' value={cargoPrimerA} onChange={(e) => setCargoPrimerA(e.target.value)} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" type="select" value={adsPrimerA} onChange={(e) => setAdsPrimerA(e.target.value)}>
                                        <option value={0}>Indique la institucion</option>
                                        {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <Row>
                        <div className='col-4'></div>
                        <div className='col-2'><Button size="sm" color="success" outline onClick={guardarDetenido}>Aceptar</Button></div>
                        <div className='col-2'><Button size="sm" color="danger" outline onClick={()=>setVerModalDet(false)}>Cancelar</Button></div>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={verModalAuto} toggle={() => setVerModalAuto(false)}>
                <ModalBody>
                    <Card>
                        <CardHeader>Apartado B.1 Fecha y hora de la retención del vehículo</CardHeader>
                        <CardBody>
                            <Row>
                                <div className="col-3">
                                    Fecha<Input size="sm" type="date" value={fechaAuto} onChange={(e) => setFechaAuto(e.target.value)} />
                                </div>
                                <div className="col-2">
                                    Hora
                                    <Input size="sm" type='select' value={hrAuto} onChange={(e) => setHrAuto(e.target.value)}>
                                        {horas.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                    </Input>
                                </div>
                                <div className="col-2">
                                    Minuto
                                    <Input size="sm" type='select' value={minAuto} onChange={(e) => setMinAuto(e.target.value)}>
                                        {minutos.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                    </Input>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado B.2 Datos generales del vehículo retenido</CardHeader>
                        <CardBody>
                            <Row>
                                <div className="col-4">
                                    Tipo <Input size="sm" type="select" value={tipoAuto} onChange={(e) => setTipoAuto(e.target.value)}>
                                        <option value={0}>Espeficique</option>
                                        {tiposAuto.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </div>
                                <div className="col-4">
                                    Procedencia <Input size="sm" type="select" value={procAuto} onChange={(e) => setProcAuto(e.target.value)}>
                                        <option value={0}>Espeficique</option>
                                        {procedencias.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}                                    
                                    </Input>
                                </div>
                                <div className="col-4">
                                    Color<Input size="sm" type="text" value={colorAuto} onChange={(e) => setColorAuto(e.target.value)} />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-5">
                                     Marca
                                <InputGroup>
                                    <Input size="sm" type="select" value={marcaAuto} onChange={(e) => setMarcaAuto(e.target.value)}>
                                        <option value={0}>Espeficique</option>
                                        {marcas.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                    <Button size="sm" outline onClick={agregarMarca}><img src="/imagenes/add.png"/></Button>
                                    </InputGroup>
                                </div>
                                <div className="col-5">
                                    SubMarca<Input size="sm" type="text" value={subMarcaAuto} onChange={(e) => setSubMarcaAuto(e.target.value)} />
                                </div>
                                <div className="col-2">
                                    Modelo<Input size="sm" type="text" value={modeloAuto} onChange={(e) => setModeloAuto(e.target.value)} />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-4">
                                    Uso <Input size="sm" type="select" value={usoAuto} onChange={(e) => setUsoAuto(e.target.value)}>
                                        <option value={0}>Espeficique</option>
                                        {usosAuto.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </div>
                                <div className="col-3">
                                    Placa<Input size="sm" type="text" value={placaAuto} onChange={(e) => setPlacaAuto(e.target.value)} />
                                </div>
                                <div className="col-4">
                                    No. de serie<Input size="sm" type="text" value={serieAuto} onChange={(e) => setSerieAuto(e.target.value)} />
                                </div>
                            </Row>
                            <Row>
                                <div className="col">
                                    Observaciones<Input size="sm" type="text" value={obsAuto} onChange={(e) => setObsAuto(e.target.value)} />
                                </div>
                            </Row>
                            <Row>
                                <div className="col">
                                    Destino<Input size="sm" type="text" value={destinoAuto} onChange={(e) => setDestinoAuto(e.target.value)} />
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader>Apartado B.3 Datos del primer respondiente que realizó el resguardo, sólo si es diferente a quien firmó la puesta a disposición</CardHeader>
                        <CardBody>
                            <Row>
                                <div className='col'>
                                    <InputGroup>
                                        <Input size="sm" placeholder='primer apellido' value={apePatPrimerR} />
                                        &nbsp;
                                        <Button outline size="sm" color="secondary" onClick={() => seleccionarPol(4)}>
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='segundo apellido' value={apeMatPrimerR} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" placeholder='nombre(s)' value={nombrePrimerR} />
                                </div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col'>
                                    <Input size="sm" placeholder='cargo/grado' value={cargoPrimerR} onChange={(e) => setCargoPrimerR(e.target.value)} />
                                </div>
                                <div className='col'>
                                    <Input size="sm" type="select" value={adsPrimerR} onChange={(e) => setAdsPrimerR(e.target.value)}>
                                        <option value={0}>Indique la institucion</option>
                                        {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                    <br/>
                    <Row>
                        <div className='col-4'></div>
                        <div className='col-2'><Button size="sm" color="success" outline onClick={guardarVehiculo}>Aceptar</Button></div>
                        <div className='col-2'><Button size="sm" color="danger" outline onClick={()=>setVerModalAuto(false)}>Cancelar</Button></div>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={verModalAutoridad} toggle={() => setVerModalAutoridad(false)}>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    {!selPolicia  && <Autoridad sel={true} autSeleccionada={autSeleccionada}/> }
                    {selPolicia && <div>
                        <Table size="sm" hover striped>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th className='celdaOpcion'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {policias.map((o, i) => <tr key={i}>
                                    <td>{o.N}</td>
                                    <td>
                                        <img src="/imagenes/ok.png" title="seleccionar" onClick={() => cargarPoliciaSel(o.I)}  />
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </div>
                    }
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={verModalPersona} toggle={() => setVerModalPersona(false)}>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <Persona sel={true} perSeleccionada={perSeleccionada}/>
                </ModalBody>
            </Modal>
            {cargando ? (
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
            ) : null}
        </div>
   )
}