import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Row, Input, Spinner, CardHeader, CardBody, Button, Table, Container, Accordion, AccordionBody, AccordionHeader, AccordionItem, Modal, ModalBody, InputGroup} from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Panel } from './Panel';

export const Persona = (props) => {
    const edoGlobal = useContext(AppContext);
    let [cargando,setCargando] = useState(false);
    let [usuario, setUsuario] = useState(1);
    let [corporacion, setCorporacion] = useState(1);
    let [datos, setDatos] = useState([]);
    let [apePatB, setApePatB] = useState("");
    let [apeMatB, setApeMatB] = useState("");
    let [nombreB, setNombreB] = useState("");
    let [aliasB, setAliasB] = useState("");
    let [capturando, setCapturando] = useState(false);
    let [id, setId] = useState(0);
    let [apePat, setApePat] = useState("");
    let [apeMat, setApeMat] = useState("");
    let [nombre, setNombre] = useState("");
    let [alias, setAlias] = useState("");
    let [curp, setCurp] = useState("");
    let [fechaNac, setFechaNac] = useState(new Date().yyyymmdd('-'));
    let [sexo, setSexo] = useState("");
    let [nacionalidad, setNacionalidad] = useState("");
    let [telefono, setTelefono] = useState("");
    let [domicilio, setDomicilio] = useState("");
    let [noExt, setNoExt] = useState("");
    let [noInt, setNoInt] = useState("");
    let [cp, setCP] = useState("");
    let [colonia, setColonia] = useState("");
    let [poblacion, setPoblacion] = useState("");
    let [municipio, setMunicipio] = useState("");
    let [estado, setEstado] = useState("");
    let [referencias, setReferencias] = useState("");
    let [descripcion, setDescripcion] = useState("");

/*
    useEffect(() => {
        cargarCatalogos();
    }, []);

    async function cargarCatalogos(){
        let url = `${edoGlobal.urlws}/iphCivica/instituciones`;
        let resp = await fetch(url);
        let datos = await resp.json();
        setIntituciones(datos);
        setCargando(false);
    }
*/
    async function cargarDatos(){
        let url = `${edoGlobal.urlws}/persona/lista/${corporacion}?apePat=${apePatB}&apeMat=${apeMatB}&nombre=${nombreB}&alias=${aliasB}`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        setDatos(datos);
        setCargando(false);
    }
    function nuevo(){
        editar(0);
    }
    async function editar(i){
        let url = `${edoGlobal.urlws}/persona/cargar/${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setId(i);
        setApePat(d.ApePat);
        setApeMat(d.ApeMat);
        setNombre(d.Nombre);
        setAlias(d.Alias);
        setFechaNac(d.FechaNac);
        setSexo(d.Sexo);
        setNacionalidad(d.Nacionalidad);
        setTelefono(d.Telefono);
        setDomicilio(d.Domicilio);
        setNoExt(d.NoExt);
        setNoInt(d.NoInt);
        setCP(d.CP);
        setColonia(d.Colonia);
        setPoblacion(d.Poblacion);
        setMunicipio(d.Municipio);
        setEstado(d.Estado);
        setReferencias(d.Referencias);
        setDescripcion(d.Descripcion);
        setCargando(false);
        setCapturando(true);
    }
    async function seleccionar(i){
        let url = `${edoGlobal.urlws}/persona/cargar/${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setCargando(false);
        props.perSeleccionada(d);
    }
    function guardar(){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                guardarB();
        });
    }
    async function guardarB(){
        let dato = {
            Id: id, ApePat:apePat, ApeMat:apeMat,Nombre:nombre, Alias:alias,
            FechaNac:fechaNac, Sexo:sexo, Nacionalidad:nacionalidad,
            Telefono:telefono,Domicilio:domicilio, NoExt:noExt,NoInt:noInt,
            CP:cp, Poblacion:poblacion, Municipio:municipio, Estado:estado,
            Colonia: colonia, Referencias:referencias, Descripcion:descripcion,
            Usuario:usuario, Corporacion:corporacion
        }
        let url = `${edoGlobal.urlws}/persona/guardar`;
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            setCapturando(false);
            cargarDatos();
        }else
           Swal.fire('Atencion',res,'error');
    }

    const estiloListado = { display: capturando ? 'none' : 'inline' };
    const estiloCaptura = { display: !capturando ? 'none' : 'inline' };

    return(
        <div>
            <div style={estiloListado}>
                <table>
                    <tr>
                        <td><Input size="sm" placeholder='Ape.Pat.' value={apePatB} onChange={(e) => setApePatB(e.target.value)} /></td>
                        <td>&nbsp;&nbsp;</td>
                        <td><Input size="sm" placeholder='Ape.Mat.' value={apeMatB} onChange={(e) => setApeMatB(e.target.value)} /></td>
                        <td>&nbsp;&nbsp;</td>
                        <td><Input size="sm" placeholder='nombre' value={nombreB} onChange={(e) => setNombreB(e.target.value)} /></td>
                        <td>&nbsp;&nbsp;</td>
                        <td><Input size="sm" placeholder='alias' value={aliasB} onChange={(e) => setAliasB(e.target.value)} /></td>
                        <td>&nbsp;&nbsp;</td>
                        <td>&nbsp;<Button size="sm" color="success" outline onClick={cargarDatos}><img src="/imagenes/ok.png" title="cargar datos" /></Button></td>
                        <td>&nbsp;{!props.sel  && <Button size="sm" color="success" outline onClick={nuevo}><img src="/imagenes/add.png" title="nuevo dato" /></Button>}</td>
                    </tr>
                </table>
                <div style={{ height: 450, overflow: 'auto' }}>
                    <Table size="sm" hover striped>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Alias</th>
                                <th>Colonia</th>
                                <th>Poblacion</th>
                                <td className='celdaOpcion'></td>
                                <td className='celdaOpcion'></td>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.map((o, i) => <tr key={i}>
                                <td>{o.N}</td>
                                <td>{o.A}</td>
                                <td>{o.C}</td>
                                <td>{o.P}</td>
                                <td>
                                    {!props.sel && <img onClick={() => editar(o.I)} src="/imagenes/edit.png" title="editar" />}
                                </td>
                                <td>
                                    {props.sel ? <img onClick={() => seleccionar(o.I)} src="/imagenes/ok.png" title="seleccionar" /> : null}
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div style={estiloCaptura}>
                <Row>
                    <div className="col">
                        Apellido Pat.
                        <Input size="sm" value={apePat} onChange={(e) => setApePat(e.target.value)} />
                    </div> 
                    <div className="col">
                        Apellido Mat.
                        <Input size="sm" value={apeMat} onChange={(e) => setApeMat(e.target.value)} />
                    </div> 
                    <div className="col">
                        Nombre
                        <Input size="sm" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                    </div> 
                </Row>
                <Row>
                    <div className="col-2">
                        Alias
                        <Input size="sm" value={alias} onChange={(e) => setAlias(e.target.value)} />
                    </div> 
                    <div className="col-2">
                        Fecha Nac.
                        <Input size="sm" type="date" value={fechaNac} onChange={(e) => setFechaNac(e.target.value)} />
                    </div> 
                    <div className="col-3">
                        CURP
                        <Input size="sm" value={curp} onChange={(e) => setCurp(e.target.value)} />
                    </div> 
                    <div className="col-2">
                        Sexo
                        <Input size="sm" type="select" value={sexo} onChange={(e) => setSexo(e.target.value)} >
                            <option value={'M'}>Mujer</option>
                            <option value={'H'}>Hombre</option>
                        </Input>
                    </div> 
                    <div className="col">
                        Nacionalidad
                        <Input size="sm" value={nacionalidad} onChange={(e) => setNacionalidad(e.target.value)} />
                    </div> 
                </Row>
                <Row>
                    <div className="col-2">
                        Telfono
                        <Input size="sm" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                    </div> 
                    <div className="col">
                        Calle
                        <Input size="sm" value={domicilio} onChange={(e) => setDomicilio(e.target.value)} />
                    </div> 
                    <div className="col-1">
                        No.Ext.
                        <Input size="sm" value={noExt} onChange={(e) => setNoExt(e.target.value)} />
                    </div> 
                    <div className="col-1">
                        No.Int.
                        <Input size="sm" value={noInt} onChange={(e) => setNoInt(e.target.value)} />
                    </div> 
                    <div className="col-1">
                        C.P.
                        <Input size="sm" value={cp} onChange={(e) => setCP(e.target.value)} />
                    </div> 
                </Row>
                <Row>
                    <div className="col">
                        Colonia
                        <Input size="sm" value={colonia} onChange={(e) => setColonia(e.target.value)} />
                    </div> 
                    <div className="col">
                        Poblacion
                        <Input size="sm" value={poblacion} onChange={(e) => setPoblacion(e.target.value)} />
                    </div> 
                    <div className="col">
                        Municipio
                        <Input size="sm" value={municipio} onChange={(e) => setMunicipio(e.target.value)} />
                    </div> 
                    <div className="col">
                        Estado
                        <Input size="sm" value={estado} onChange={(e) => setEstado(e.target.value)} />
                    </div> 
                </Row>
                <Row>
                    <div className="col">
                        Referencias
                        <Input size="sm" value={referencias} onChange={(e) => setReferencias(e.target.value)} />
                    </div> 
                </Row>
                <Row>
                    <div className="col">
                        Descripcion
                        <Input size="sm" style={{ height: 200 }} type="textarea" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />
                    </div> 
                </Row>
                <Row>
                    <div className='col-4'>
                        <br/>
                        <Button size="sm" color="success" outline onClick={guardar}><img src="/imagenes/disk.png" />  Guardar</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button size="sm" color="danger" outline onClick={()=>setCapturando(false)}> Cancelar </Button>
                    </div>
                </Row>
            </div>
            {cargando ? (
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
            ) : null}
        </div>
    )
}