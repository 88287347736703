import React from 'react';
import Login from './Login';
import Menu from './Menu';
import { AppContext } from './AppContext';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { IphJusticia } from './IphJusticia';
import { IphDelito } from './IphDelito';
import { Audiencias } from './Audiencias';
import { Autoridad } from './Autoridad';
import { Persona } from './Persona';

class App extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      u: { id: 0, nombre: 'Demo', admin: false },
      lg: false,
      opc: '',
      txt:'',
      nombreEmpresa:'',
      urlws : '',
      activeTab:0,
      tabs:['IPH Delitos','IPH Justicia Civica','Audiencias','Autoridades','Personas'],
      componenteActivo:0,
      componentes: [
          <div><IphDelito /></div>,
          <div><IphJusticia /></div>,
          <div><Audiencias /></div>,
          <div><Autoridad sel={false}/></div>,
          <div><Persona sel={false}/></div>
      ]
    }
  }
  componentDidMount() {
    const edoGlobal = this.context;
    this.setState({...edoGlobal});
    if(this.state.opc != "")
      this.opcionSeleccionada(this.state.opc,this.state.opc)
  }
  opcionSeleccionada = (opc,txt) => {
    const rpt = opc;
    //alert(opc);
    let urlws = this.state.urlws;
    for (let i = 0; i < this.state.tabs.length; i++) {
      if(this.state.tabs[i] === txt ){
        this.setState({activeTab:i});
        return;
      }
    }
    /*
    if (opc === "alimentos")
      this.agregar(<Alimentos />);
    else if (opc === "perfil")
      this.agregar(<Perfil />);
    else if (opc === "dietas")
      this.agregar(<Dietas />);
    else if (opc === "citas")
      this.agregar(<Citas />);
    else
       return;
       */
    this.setState({
        tabs: this.state.tabs.concat(txt)
    });
    opc = "";
    this.setState({ opc,txt });
  }
  agregar = (componente) => {
    this.setState({
      activeTab:this.state.tabs.length,
      componentes: this.state.componentes.concat(componente)
    });
  }
  toggle = (i) =>{
    const txt = this.state.tabs[i];
    this.setState({activeTab:i, componenteActivo:i, txt});
  }
  cerrar = (i) =>{
    if(i == 0)
       return;
    this.state.componentes[i] = null;
    this.state.tabs[i] = null;
    this.setState({activeTab:this.state.tabs.length-1});
  }
  iniciar = (u) => {
    const edoGlobal = this.context;
    const usu = { id: u.Id, nombre: u.Nombre, admin: u.Admin };
    this.setState({ lg: true, u: usu });
    edoGlobal.setUsuario(u);
  }
  render() {
    let {opc} = this.state;
    const estilo = { visibility: this.state.lg ? 'visible' : 'hidden' };
    if(this.state.lg){
      document.body.classList.add('body');
      document.body.classList.remove('fondoInicial');
    }
    return (
      <div>
        {this.state.lg ? null : 
          <Container>
            <div className="row" >
              <div className="col-4">
              </div>
              <div className="col-4">
                <br/><br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/><br/>
                <Login iniciar={this.iniciar} />
              </div>
            </div>
          </Container>
        }
        <div style={estilo} className="colorBlanco">
          <div className="row" >
            <div className="col-1">
              <img src="/imagenes/logo.png" height={50} alt="logo" />
            </div>
            <div className="col">
              <div className="row">
                <div className="col-11">
                  {
                  /*
                  <Menu opcionSeleccionada={this.opcionSeleccionada} />
                  */
                  }
                </div>
              </div>
                <div className="row">
                  <div className="col-7">
                  </div>
                  <div className="col-2">
                  </div>
                  <div className="col-2">
                  </div>
                </div>
            </div>
            <div className="col-2">
              <span style={{ textAlign:'left', color: '#000', fontWeight:'bolder' }}>usuario:{this.state.u.nombre}</span>
            </div>
          </div>
          {this.state.lg ?
            <div id="content" style={{ width: '100%', padding:10 }}>
              <div>
                <Nav tabs>
                  {this.state.tabs.map((o, i) =>
                    o == null ? null :
                      <NavItem key={i}>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === i })}
                          onClick={() => { this.toggle(i); }}>
                          <span>{o}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          {i > 10 &&
                            <a href="#"><b><span onClick={() => { this.cerrar(i) }}><img src="/imagenes/circle-x.png" alt="cerrar" /></span></b></a>
                          }
                        </NavLink>
                      </NavItem>
                  )}
                </Nav>
              </div>
              <div style={{padding:4}}>
              {
                this.state.componentes.map((o, i) => {
                  const k = "k" + i;
                  const visible = i == this.state.activeTab ? 'block' : 'none';
                  return <div key={k} style={{ display: visible }}>{o}</div>
                })
              }
              </div>
            </div>      
          :null
          }
        </div>
      </div>
    )
  }
}
export default App;