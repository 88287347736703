import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Row, Input, Spinner, CardHeader, CardBody, Button, Table, Container, Accordion, AccordionBody, AccordionHeader, AccordionItem, Modal, ModalBody, InputGroup} from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Panel } from './Panel';

export const Autoridad = (props) => {
    const edoGlobal = useContext(AppContext);
    let [cargando,setCargando] = useState(false);
    let [instituciones,setIntituciones] = useState([]);
    let [usuario, setUsuario] = useState(1);
    let [corporacion, setCorporacion] = useState(1);
    let [datos, setDatos] = useState([]);
    let [apePatB, setApePatB] = useState("");
    let [apeMatB, setApeMatB] = useState("");
    let [nombreB, setNombreB] = useState("");
    let [capturando, setCapturando] = useState(false);
    let [id, setId] = useState(0);
    let [apePat, setApePat] = useState("");
    let [apeMat, setApeMat] = useState("");
    let [nombre, setNombre] = useState("");
    let [adscripcion, setAdscripcion] = useState(0);
    let [cargo, setCargo] = useState("");

    useEffect(() => {
        cargarCatalogos();
    }, []);

    async function cargarCatalogos(){
        let url = `${edoGlobal.urlws}/iphCivica/instituciones`;
        let resp = await fetch(url);
        let datos = await resp.json();
        setIntituciones(datos);
        setCargando(false);
    }

    async function cargarDatos(){
        let url = `${edoGlobal.urlws}/autoridad/lista/${corporacion}?apePat=${apePatB}&apeMat=${apeMatB}&nombre=${nombreB}`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        setDatos(datos);
        setCargando(false);
    }
    function nuevo(){
        editar(0);
    }
    async function editar(i){
        let url = `${edoGlobal.urlws}/autoridad/cargar/${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setId(i);
        setApePat(d.ApePat);
        setApeMat(d.ApeMat);
        setNombre(d.Nombre);
        setAdscripcion(d.Adscripcion);
        setCargo(d.Cargo);
        setCargando(false);
        setCapturando(true);
    }
    async function seleccionar(i){
        let url = `${edoGlobal.urlws}/autoridad/cargar/${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setCargando(false);
        props.autSeleccionada(d);
    }
    function guardar(){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                guardarB();
        });
    }
    async function guardarB(){
        let dato = {
            Id: id, ApePat:apePat, ApeMat:apeMat,Nombre:nombre,
            Adscripcion:adscripcion, Cargo:cargo, 
            Usuario:usuario, Corporacion:corporacion
        }
        let url = `${edoGlobal.urlws}/autoridad/guardar`;
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            setCapturando(false);
            cargarDatos();
        }else
           Swal.fire('Atencion',res,'error');
    }

    const estiloListado = { display: capturando ? 'none' : 'inline' };
    const estiloCaptura = { display: !capturando ? 'none' : 'inline' };

    return(
        <div>
            <div style={estiloListado}>
                <table>
                    <tr>
                        <td><Input size="sm" placeholder='Ape.Pat.' value={apePatB} onChange={(e) => setApePatB(e.target.value)} /></td>
                        <td>&nbsp;&nbsp;</td>
                        <td><Input size="sm" placeholder='Ape.Mat.' value={apeMatB} onChange={(e) => setApeMatB(e.target.value)} /></td>
                        <td>&nbsp;&nbsp;</td>
                        <td><Input size="sm" placeholder='nombre' value={nombreB} onChange={(e) => setNombreB(e.target.value)} /></td>
                        <td>&nbsp;&nbsp;</td>
                        <td>&nbsp;<Button size="sm" color="success" outline onClick={cargarDatos}><img src="/imagenes/ok.png" title="cargar datos" /></Button></td>
                        <td>&nbsp;<Button size="sm" color="success" outline onClick={nuevo}><img src="/imagenes/add.png" title="nuevo dato" /></Button></td>
                    </tr>
                </table>
                <div style={{ height: 450, overflow: 'auto' }}>
                    <Table size="sm" hover striped>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Adscripcion</th>
                                <th>Cargo</th>
                                <td className='celdaOpcion'></td>
                                <td className='celdaOpcion'></td>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.map((o, i) => <tr key={i}>
                                <td>{o.N}</td>
                                <td>{o.A}</td>
                                <td>{o.C}</td>
                                <td><img onClick={() => editar(o.I)} src="/imagenes/edit.png" title="editar" /></td>
                                <td>
                                    {props.sel ? <img onClick={() => seleccionar(o.I)} src="/imagenes/ok.png" title="seleccionar" /> : null}
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div style={estiloCaptura}>
                <Row>
                    <div className="col">
                        Apellido Pat.
                        <Input size="sm" value={apePat} onChange={(e) => setApePat(e.target.value)} />
                    </div> 
                    <div className="col">
                        Apellido Mat.
                        <Input size="sm" value={apeMat} onChange={(e) => setApeMat(e.target.value)} />
                    </div> 
                    <div className="col">
                        Nombre
                        <Input size="sm" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                    </div> 
                </Row>
                <Row>
                    <div className="col">
                        Adscripcion
                        <Input size="sm" type="select" value={adscripcion} onChange={(e) => setAdscripcion(e.target.value)}>
                            <option value={0}>Indique la institucion</option>
                            {instituciones.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                        </Input>
                    </div> 
                    <div className="col">
                        Cargo
                        <Input size="sm" value={cargo} onChange={(e) => setCargo(e.target.value)} />
                    </div> 
                </Row>
                <Row>
                    <div className='col-4'>
                        <br/>
                        <Button size="sm" color="success" outline onClick={guardar}><img src="/imagenes/disk.png" />  Guardar</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button size="sm" color="danger" outline onClick={()=>setCapturando(false)}> Cancelar </Button>
                    </div>
                </Row>
            </div>
            {cargando ? (
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
            ) : null}
        </div>
    )
}